import React, { useEffect, useMemo, useRef, useState } from "react";
import RightArrowIcon from "../../assets/Icons/RightArrowIcon";
import VerticleThreeDotsIcon from "../../assets/Icons/VerticleThreeDotsIcon";
import LocationIcon from "../../assets/Icons/LocationIcon";
import * as constants from "../../constant";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { updateApplicantStatusApi } from "../../slices/jobSlice";
import useLocations from "../../utils/dropdown-options/useLocations";
import axios from "axios";
import useIndustries from "../../utils/dropdown-options/useIndustries";
import {
  updateApplicantStatusByMemberApi,
  updateReferalStatusApi,
} from "../../slices/referSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import Candidate from "../../modules/home/logins/candidate/Candidate";
import useSalaries from "../../utils/dropdown-options/useSalaries";
import ExCheckBox from "../basic-components/ExCheckBox";
import CheckedIcon from "../../assets/Icons/CheckedIcon";
import Tooltip from "../tool-tip/Tooltip";

const CandidateCard = ({
  candidate,
  candidateDetails,
  setAppliedCadidateViaRefer,
  appliedCadidateViaRefer,
  setSelectedCandidates = () => {},
  selectedCandidates = [],
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const menuParentRef = useRef(null);
  const inpuRef = useRef(null);

  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector((state) => state.member.memberDetails);

  const [isChecked, setIsChecked] = useState(
    selectedCandidates.some((c) => c.id === candidate.id)
    // false
  );

  const { defaultIndustry } = useIndustries();

  const preferdIndustry = useMemo(() => {
    if (candidate?.preferredIndustry) {
      return candidate?.preferredIndustry[0]
        ? defaultIndustry(candidate.preferredIndustry[0])
        : null;
    } else if (
      candidate?.applications &&
      candidate?.applications[0]?.preferredIndustry[0]
    ) {
      return defaultIndustry(candidate?.applications[0]?.preferredIndustry[0]);
    }
    return null;
  }, [candidate?.applications, candidate.preferredIndustry]);

  const token = useAppSelector((state) => state?.login?.session?.token);

  const [toggle, setToggle] = useState(false);

  const { defaultLocation } = useLocations();
  const { defualtSalary } = useSalaries();

  const data = {
    comments: candidate.comments,
    reasonForjobChange: candidate.reasonForJobChange,
    expectation: candidate.expectation,
    previousCompany: candidate.previousCompany,
    workMode: candidate.workMode,
    teamSize: candidate.teamSize,
    reportingTo: candidate.reportingTo,
    qualification: candidate.qualification,
    skills: candidate.skills,
  };

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setToggle(false);
      }
    };
    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const toggleStateModal = () => {
    setToggle(!toggle);
  };

  const handleDownload = async () => {
    if (candidate?.attachment?.url) {
      axios
        .get(`${constants.baseUrl}/${candidate?.attachment?.url}?t=${token}`, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = candidate?.attachment?.name;
          a.click();
        })
        .catch((err) => {});
    } else if (
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.CANDIDATE_MANEGMENT) ||
      loginSelector?.who === constants.RECRUITER.toLowerCase()
    ) {
      axios
        .get(
          `${constants.baseUrl}/${candidate?.applications[0]?.attachment?.url}?t=${token}`,
          {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = candidate?.applications[0]?.attachment?.name;
          a.click();
        })
        .catch((err) => {});
    }
  };

  const updateStateHandler = async (applicantStatus) => {
    if (
      loginSelector?.who === constants.COMPANY.toLowerCase() ||
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.ADMIN)
    ) {
      dispatch(
        updateApplicantStatusApi({
          jobId: candidate.jobId,
          applicationId: candidate.id,
          status: applicantStatus,
          subStatus: "APPLICATION_VIEWED", //APPLICATION_CONTACT_VIEWED
        })
      );
    } else if (
      (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
        memberSelector.designation === constants.CANDIDATE_MANEGMENT) ||
      loginSelector?.who === constants.RECRUITER.toLowerCase()
    ) {
      const resp = await dispatch(
        updateApplicantStatusByMemberApi({
          jobId: candidate.jobId,
          applicationId: candidate.applications[0].id,
          status: applicantStatus,
          subStatus: "APPLICATION_VIEWED", //APPLICATION_CONTACT_VIEWED
        })
      ).then(unwrapResult);
      const newArray = appliedCadidateViaRefer.referrals.map((ref) => {
        return {
          ...ref,
          applications: ref.applications.map((apps) => {
            if (apps.id === resp.data.data.id) {
              return { ...apps, status: resp.data.data.status };
            }
          }),
        };
      });

      setAppliedCadidateViaRefer({
        ...appliedCadidateViaRefer,
        referrals: newArray,
      });
      dispatch(
        updateReferalStatusApi({
          referalId: candidate.id,
          status: applicantStatus,
          subStatus: "APPLICATION_VIEWED",
        })
      ).then(unwrapResult);
    }
    toggleStateModal();
  };

  const candidateLocation = useMemo(() => {
    return candidateDetails?.location
      ? defaultLocation(candidateDetails?.location)
      : candidate?.location && candidate?.location.length > 0
      ? defaultLocation(candidate?.location[0])
      : "";
  }, [candidateDetails?.location, candidate?.location]);

  const candidateLastsal = useMemo(() => {
    return defualtSalary(candidate?.lastSalary);
  }, [candidate?.lastSalary, defualtSalary]);

  const expectedSal = useMemo(() => {
    return defualtSalary(candidate?.expectation);
  }, [candidate?.expectation, defualtSalary]);

  // const navigateHandler = () => {
  //   if (candidateDetails.userId)
  //     navigate(`/company-admin/applicant/${candidateDetails.userId}`, {
  //       state: candidate,
  //     });
  // };

  const onChange = (e) => {
    if (!selectedCandidates.some((c) => c.id === candidate.id)) {
      setSelectedCandidates((prev) => [...prev, candidate]);
    } else {
      const filteredArray = selectedCandidates.filter(
        (c) => c.id !== candidate.id
      );
      setSelectedCandidates(filteredArray);
    }
  };

  const toggleInputHandler = () => {
    if (inpuRef.current) {
      inpuRef.current.click();
      setIsChecked(!isChecked);
    }
  };

  return (
    <tr className="bg-exwhite-100 cursor-pointer first:border-none border-t-8 border-primary-100/50">
      <td className="flex items-center justify-start px-3 py-4">
        <div className="flex items-center gap-2 mr-2">
          <input
            type="checkbox"
            id={candidate.id}
            className="hidden"
            ref={inpuRef}
            onChange={onChange}
          />
          <div
            className={`h-5 w-5 border-2 rounded-sm border-exgray-200 ${
              candidate.isTagged
                ? "bg-exgray-100 border-none"
                : isChecked && "bg-secondary-400 border-none"
            } cursor-pointer flex items-center justify-center`}
            onClick={() => {
              if (!candidate.isTagged) toggleInputHandler();
            }}
          >
            {(isChecked || candidate.isTagged) && <CheckedIcon />}
          </div>
        </div>
        <Link
          className="flex-1 text-base text-primary-400 font-semibold cursor-pointer text-nowrap"
          // target="_blank"
          to={`/company-admin/job/${candidate.jobId}/applicant/${candidateDetails.userId}`}
          state={{ candidate }}
        >
          {`${candidate?.author?.firstName} ${candidate?.author?.lastName}`}
        </Link>
      </td>

      <td className="text-base text-start text-exgray-500 px-3 py-4">
        <Tooltip text={candidate.emailId}>
          <div className="max-w-36 truncate">{candidate.emailId}</div>
        </Tooltip>
      </td>
      <td className="text-base text-start text-exgray-500 px-3 py-4 text-nowrap">
        {candidateLocation?.Name && candidateLocation?.Name}
      </td>
      <td className="text-base text-center text-exgray-500 px-3 py-4">
        {`${candidateDetails.totalExperience} Yrs`}
      </td>
      <td className="text-base text-center text-exgray-500 px-3 py-4">
        {candidate.noOfServingDays}
      </td>
      <td className="text-base text-center text-exgray-500 px-3 py-4">
        {candidateLastsal?.Title}
      </td>
      <td className="text-base text-center text-exgray-500 px-3 py-4">
        {expectedSal?.Title}
      </td>
    </tr>
    // <div className="bg-exwhite-100 flex items-center justify-start gap-4 p-4 rounded-md ">
    //   <div className="flex-1 flex items-center justify-start">
    //     <div className="flex items-center gap-2 mr-2">
    //       <input
    //         type="checkbox"
    //         id={candidate.id}
    //         className="hidden"
    //         ref={inpuRef}
    //         onChange={onChange}
    //       />
    //       <div
    //         className={`h-5 w-5 border-2 rounded-sm border-exgray-200 ${
    //           candidate.isTagged
    //             ? "bg-exgray-100 border-none"
    //             : isChecked && "bg-secondary-400 border-none"
    //         } cursor-pointer flex items-center justify-center`}
    //         onClick={() => {
    //           if (!candidate.isTagged) toggleInputHandler();
    //         }}
    //       >
    //         {(isChecked || candidate.isTagged) && <CheckedIcon />}
    //       </div>
    //     </div>
    //     <Link
    //       className="flex-1 text-base text-primary-400 font-semibold cursor-pointer"
    //       // target="_blank"
    //       to={`/company-admin/job/${candidate.jobId}/applicant/${candidateDetails.userId}`}
    //       state={{ candidate }}
    //     >
    //       {`${candidate?.author?.firstName} ${candidate?.author?.lastName}`}
    //     </Link>
    //   </div>
    //   <div className="w-44 overflow-x-auto py-2">
    //     <h1 className="text-base text-start text-exgray-500">
    //       {candidate.emailId}
    //     </h1>
    //   </div>
    //   <div className="flex-1">
    //     <h1 className="text-base text-start text-exgray-500">
    //       {candidateLocation?.Name && candidateLocation?.Name}
    //     </h1>
    //   </div>
    //   <div className="flex-1">
    //     <h1 className="text-base text-start text-exgray-500">
    //       {`${candidateDetails.totalExperience} Yrs Exp`}
    //     </h1>
    //   </div>
    //   <div className="flex-1">
    //     <h1 className="text-base text-start text-exgray-500">
    //       {candidate.noOfServingDays}
    //     </h1>
    //   </div>
    //   <div className="flex-1">
    //     <h1 className="text-base text-start text-exgray-500">
    //       {candidateLastsal?.Title}
    //     </h1>
    //   </div>
    //   <div className="flex-1">
    //     <h1 className="text-base text-start text-exgray-500">
    //       {expectedSal?.Title}
    //     </h1>
    //   </div>
    // </div>
  );
};

export default CandidateCard;
