const StarIcon = ({ width = "13", height = "13", color = "#0A65CC" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.02447 0.963526C6.17415 0.502871 6.82585 0.50287 6.97553 0.963525L8.0716 4.33688C8.13854 4.54289 8.33051 4.68237 8.54713 4.68237H12.0941C12.5784 4.68237 12.7798 5.30218 12.388 5.58688L9.51843 7.67173C9.34318 7.79905 9.26985 8.02474 9.33679 8.23075L10.4329 11.6041C10.5825 12.0648 10.0553 12.4478 9.66344 12.1631L6.79389 10.0783C6.61865 9.95095 6.38135 9.95095 6.20611 10.0783L3.33656 12.1631C2.9447 12.4478 2.41746 12.0648 2.56714 11.6041L3.66321 8.23075C3.73015 8.02474 3.65682 7.79905 3.48157 7.67173L0.612025 5.58688C0.220169 5.30218 0.421556 4.68237 0.905918 4.68237H4.45287C4.66949 4.68237 4.86146 4.54289 4.9284 4.33688L6.02447 0.963526Z"
        fill={color}
      />
    </svg>
  );
};

export default StarIcon;
