import { AxiosPromise } from "axios";
import apiClient from "../config/axiosConfig";
import {
  CreateInterviewStagesReq,
  CreateInterviewStagesRes,
  GetInterviewStagesReq,
  GetInterviewStagesRes,
  TagApplicantReq,
  unSaveJobReq,
  unSaveJobRes,
  UpdateApplicantStageReq,
  UpdateInterviewStagesReq,
  withdrawToJobReq,
  withdrawToJobRes,
} from "../models/job.model";
import { ApiResponse } from "../models/base.model";

class JobService {
  createJob(createJob) {
    return apiClient.post(`/v1/exo/jobs`, createJob);
  }

  getRecommendedJobs(filterData) {
    return apiClient.get(
      // `/v1/exo/jobs?filter=${JSON.stringify(filterData)}`
      `/v1/exo/jobs?filter=${JSON.stringify(filterData)}&size=20`
    ); //You can add filter also like "/v1/exo/jobs?filter={"expMin":5}"
  }

  getJobByUrl(nextUrl) {
    return apiClient.get(nextUrl);
  }

  getJobApplicantsForCMByUrl(req) {
    return apiClient.get(req.nextUrl, {
      params: req,
    });
  }

  getNextAssignedJobToMemberByUrl(filterData) {
    return apiClient.get(filterData.nextUrl, {
      params: filterData,
    });
  }

  getAppliedJob() {
    return apiClient.get(`/v1/exo/jobs/applied`);
  }

  getSavedJob() {
    return apiClient.get(`/v1/exo/jobs/saved`);
    // return apiClient.get(`/v1/exo/jobs/saved?size=2`);
  }

  updateJob(job) {
    return apiClient.put(`/v1/exo/jobs/${job.jobId}`, job);
  }

  saveJob(jobSave) {
    return apiClient.post(`/v1/exo/jobs/saved?size=20`, jobSave);
  }

  unSaveJob(jobUnSave: unSaveJobReq): AxiosPromise<ApiResponse<unSaveJobRes>> {
    return apiClient.delete(`/v1/exo/jobs/saved/${jobUnSave.jobId}`);
  }

  applyToJob(jobApplication) {
    return apiClient.post(
      `/v1/exo/jobs/${jobApplication.jobId}/applications`,
      jobApplication
    );
  }

  withdrawToJob(
    jobApplication: withdrawToJobReq
  ): AxiosPromise<ApiResponse<withdrawToJobRes>> {
    return apiClient.patch(
      `/v1/exo/jobs/${jobApplication.jobId}/applications/${jobApplication.applicationId}/withdraw`,
      jobApplication
    );
  }

  getJobDetail(getJob) {
    return apiClient.get(`/v1/exo/jobs/${getJob.jobId}`);
  }

  getApplicants(job) {
    return apiClient.get(`/v1/exo/jobs/${job.jobId}/applications?size=20`, {
      params: job,
    });
  }

  getNextStagedApplicants(nextUrl: string) {
    return apiClient.get(nextUrl);
  }

  getApplicantDetailsById(req) {
    return apiClient.get(
      `/v1/exo/jobs/${req.jobId}/applications/${req.applicantId}`
    );
  }

  updateApplicantStage(req: UpdateApplicantStageReq) {
    return apiClient.put(
      `/v1/exo/jobs/applications/${req.applicantId}/stage`,
      req
    );
  }

  tagApplicant(req: TagApplicantReq) {
    return apiClient.put(
      `/v1/exo/jobs/applications/${req.applicantId}/tagged `,
      req
    );
  }

  updateApplication(jobApplication) {
    return apiClient.patch(
      `/v1/exo/jobs/${jobApplication.jobId}/applications/${jobApplication.applicationId}/action`,
      jobApplication
    );
  }

  updateJobStatus(job) {
    return apiClient.patch(`/v1/exo/jobs/${job.jobId}/status`, {
      status: job.status,
    });
  }

  sendApplicationMessage(sendComment) {
    return apiClient.post(
      `/v1/exo/jobs/${sendComment.jobId}/applications/${sendComment.applicationId}/comments`,
      sendComment.comment
    );
  }

  getAllAplicationMessage(getComment) {
    return apiClient.get(
      `/v1/exo/jobs/${getComment.jobId}/applications/${getComment.applicationId}/comments`
    );
  }

  asignJob(req) {
    return apiClient.post(`/v1/exo/jobs/assign`, req);
  }

  getAssignJobsToMember(req) {
    return apiClient.get(`/v1/exo/jobs/assign/member/${req.memberId}?size=20`, {
      params: req,
    });
  }

  getAssignMembersToJob(req) {
    return apiClient.get(`/v1/exo/jobs/assign/job/${req.jobId}`);
  }

  //Interview stages
  getInterviewStages(
    req: GetInterviewStagesReq
  ): AxiosPromise<ApiResponse<Array<CreateInterviewStagesRes>>> {
    return apiClient.get(`/v1/exo/job/${req.jobId}/interview_stages`);
  }

  createInterviewStages(req: CreateInterviewStagesReq) {
    return apiClient.post(`/v1/exo/job/${req.jobId}/interview_stages`, req);
  }

  updateInterviewStages(req: UpdateInterviewStagesReq) {
    return apiClient.put(
      `/v1/exo/job/${req.jobId}/interview_stages/${req.stageId}`,
      req
    );
  }
}

const jobService = new JobService();
export default jobService;
