import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import CrossIcon from "../../../assets/Icons/CrossIcon";
import CustomDatePicker from "../../../components/basic-components/CustomDatePicker";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import ExInput from "../../../components/basic-components/ExInput";
import ExMultiDropdown from "../../../components/basic-components/ExMultiDropdown";
import { createMemberApi, updateMembersApi } from "../../../slices/companyMemberSlice";
import * as constants from "../../../constant";
import { member } from "../../../models/companyMember.model";
import useIndustries from "../../../utils/dropdown-options/useIndustries";
import useGender from "../../../utils/dropdown-options/useGender";
import useMaritalStatus from "../../../utils/dropdown-options/useMaritalStatus";
import useNationality from "../../../utils/dropdown-options/useNationality";
//import ExMultiDropdown from "../../../components/basic-components/ExMultiDropdown";

const marriageStatus = [
  {
    id: 1,
    Title: "Married"
  },
  {
    id: 2,
    Title: "UnMarried"
  }
];

const nationalityArray = [
  {
    id: 1,
    Title: "Indian"
  },
  {
    id: 2,
    Title: "NRI"
  }
];

export interface UpdateEmployeeDetalProps {
  employeeDetail: member;
  onClose: Function;
}


const UpdateEmployeeDetail = ({ onClose, employeeDetail }: UpdateEmployeeDetalProps) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const companySelector = useAppSelector(
    (state) => state.company.companyDetails
  );
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const gender = useAppSelector(
    (state) => state?.appConfig?.genderList
  );

  const { genderArray, defaultGender } = useGender();
  const { maritalStatusArray, defaultMaritalStatus } = useMaritalStatus();
  const { nationalityArray, defaultNationality } = useNationality();

  console.log(defaultGender(employeeDetail?.gender), "this is for gender get ");

  const domain = useMemo(() => {
    if (
      loginSelector.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector.emailId
    ) {
      const email = memberSelector.emailId.match(
        /@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/
      );
      if (email.length > 0) {
        return email[0] ? email[0] : "";
      }
      return "";
    } else if (
      loginSelector.who === constants.COMPANY.toLowerCase() &&
      companySelector?.domain
    ) {
      return `@${companySelector.domain}`;
    }
    return "";
  }, [companySelector?.domain, loginSelector?.who, memberSelector?.emailId]);

  const form = useForm({
    defaultValues: {
      emailId: employeeDetail.emailId,
      firstName: employeeDetail.firstName,
      lastName: employeeDetail.lastName,
      mobileNo: employeeDetail.mobileNo,
      gender: defaultGender(employeeDetail?.gender),
      birthDate: employeeDetail.birthDate,
      nationality: defaultNationality(employeeDetail.nationality),
      maritalStatus: defaultMaritalStatus(employeeDetail.maritalStatus),
      currentAddress: employeeDetail.currentAddress.street,
      permanentAddress: employeeDetail.permanentAddress.street,
      employeeCode: employeeDetail.employeeCode
    },
  });
  const { handleSubmit, control, formState, setValue } = form;
  const { errors } = formState;

  const onSubmit = async (formData) => {
    try {
      await dispatch(
        updateMembersApi({
          firstName: formData.firstName,
          lastName: formData.lastName,
          //emailId: formData.emailId,
          countryCode: 91,
          mobileNo: parseInt(formData.mobileNo),
          memberId: employeeDetail.id,
          gender: formData.gender.Title,
          joiningDate: formData.joiningDate,
          updatedBy: userId,
          birthDate: formData.birthDate,
          nationality: formData.nationality.Title,
          maritalStatus: formData.maritalStatus.Title,
          currentAddress: {
            street: formData.currentAddress,
            city: '',
            state: '',
            country: '',
            postalCode: ''
          },
          permanentAddress: {
            street: formData.permanentAddress,
            city: '',
            state: '',
            country: '',
            postalCode: ''
          }
        })
      ).then(unwrapResult);
      toast.success("Personal details updated successfully");
    } catch (error) {
      toast.error("Something went wrong,please try again");
    }
    onClose();
  };


  return (
    <div className="px-10 py-6 w-[70%] mx-auto bg-white shadow-md rounded-lg overflow-y-auto no-scrollbar">
      <div className="mb-5">
        <div className="flex flex-row items-center justify-between mb-2">
          <h1 className="text-xl text-primary-400 font-medium mb-1">
            Update Personal Details
          </h1>
          <div onClick={() => onClose()}>
            <CrossIcon />
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "First name is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="First Name"
                placeholder="Type here..."
                name="firstName"
                onChange={onChange}
                value={value}
                error={errors?.firstName?.message}
                isMandatory={true}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Last name is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Last Name"
                placeholder="Type here..."
                name="lastName"
                onChange={onChange}
                value={value}
                error={errors?.lastName?.message}
                isMandatory={true}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="emailId"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Email ID is required",
              },
              pattern: {
                value:
                  /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                message: "Please enter a valid email ID",
              },
              validate: (value) => {
                // Ensure email ends with @aws.com
                return (
                  value.endsWith(domain) || `Email must end with ${domain}`
                );
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Personal Email ID"
                placeholder="Type here..."
                name="emailId"
                onChange={onChange}
                value={value}
                error={errors?.emailId?.message}
                isMandatory={true}
              />
            )}
          />
          <Controller
            name="mobileNo"
            control={control}
            rules={{
              //valueAsNumber: true,
              required: {
                value: true,
                message: "phoneNo is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Phone Number"
                placeholder="Type here..."
                name="mobileNo"
                onChange={onChange}
                value={value}
                error={errors?.mobileNo?.message}
                isMandatory={true}
              />
            )}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="gender"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Gender is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Gender"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={gender}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.gender?.message}
                isMandatory={true}
              />
            )}
          />

          <Controller
            name="birthDate"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Reporting manager is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                title="Date Of Birth"
                name="birthDate"
                onChange={onChange}
                selected={value ? new Date(value) : null}
                //maxDate={new Date()}
                //minDate={new Date()}
                showYearDropdown={true}
                dropdownMode="select"
                placeholderText="Ex. mm/dd/yyyy"
                popperPlacement="bottom-end"
                dateFormat="dd/MM/yyyy"
                showMonthYearPicker={false}
                error={errors?.birthDate?.message}
                isMandotary={true}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="maritalStatus"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Joining date is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Marital Status"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={marriageStatus}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.maritalStatus?.message}
                isMandatory={true}
              />
            )}
          />
          <Controller
            name="nationality"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Joining date is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExDropdown
                title="Nationality"
                placeholder="Select"
                onChange={onChange}
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={nationalityArray}
                dropDownHeight="40px"
                dropDownBorderWidth="1px"
                dropDownBorderColor="#8C8C8C"
                error={errors?.nationality?.message}
                isMandatory={true}
              />
            )}
          />

        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="currentAddress"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Current Address is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Current Address"
                placeholder="Type here..."
                name="firstName"
                onChange={onChange}
                value={value}
                error={errors?.currentAddress?.message}
                isMandatory={true}
              />
            )}
          />
          <Controller
            name="permanentAddress"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Joining date is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Permanent Address"
                placeholder="Type here..."
                name="firstName"
                onChange={onChange}
                value={value}
                error={errors?.permanentAddress?.message}
                isMandatory={true}
              />
            )}
          />
        </div>


        <div className="w-fit ml-auto mr-0 pt-3">
          <button
            type="submit"
            className="px-8 py-2 text-exwhite-100 text-base font-semibold text-center bg-expurple-800 rounded-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateEmployeeDetail;
