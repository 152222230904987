import React, { useState } from "react";
import CrossIcon from "../../assets/Icons/CrossIcon";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { createClientApi } from "../../slices/salesSlice";
import Select from "react-select";
import { formatDate } from "../../utils/date.util";
interface ClientFormData {
  clientName: string;
  clientEmail: string;
  domain: string;
  location: string;
  contactPersonName: string;
  contactPersonPhone: number;
  contactPersonEmail: string;
  contactPersonCountryCode: number;
  designation: string;
  industry: string;
  subIndustry: string;
  engagementType: string[];
  status: string;
  stages: string;
  revenueExpectations: string;
  leadOwner:string;
  leadConversion:string;
  createdBy:string;
}

const countryCodes = ["+1", "+91", "+44", "+61", "+81"];
const industries = ["IT", "Finance", "Healthcare", "Retail"]; // Example industries
const subIndustries = {
  IT: ["Software", "Hardware", "Networking"],
  Finance: ["Banking", "Insurance", "Investment"],
  Healthcare: ["Pharmaceuticals", "Hospitals", "Medical Devices"],
  Retail: ["E-commerce", "Supermarkets", "Luxury Goods"],
};

const ClientForm = ({ togglePopupHandler }) => {


   
  const dispatch = useAppDispatch();
  const {
    salesEngangementType,
    salesIndustry,
    salesSubIndustry,
    salesStages,
    salesStatus,
    countries,
  } = useAppSelector((state) => state.appConfig);

  const {userId}=useAppSelector((state)=>state.login.session)
  const [formData, setFormData] = useState<ClientFormData>({
    clientName: "",
    clientEmail: "",
    domain: "",
    location: "",
    contactPersonName: "",
    contactPersonPhone: 0,
    contactPersonEmail: "",
    contactPersonCountryCode: 91,
    designation: "",
    industry: "",
    subIndustry: "",
    engagementType: [],
    status: "",
    stages: "",
    revenueExpectations: "",
    leadOwner:userId,
    leadConversion:"LEAD",
    createdBy:userId
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleIndustryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      industry: value,
      subIndustry: "", // Reset sub-industry when industry changes
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    //  formData.engagementType= []
    dispatch(
      createClientApi({
        clientName: formData.clientName,
        clientEmail: formData.clientEmail,
        domain: formData.domain,
        engagementType: formData.engagementType,
        industry: formData.industry,
        subIndustry: formData.subIndustry,
        location: formData.location,
        status: formData.status,
        stages: formData.stages,
        revenueExpectations: formData.revenueExpectations,
        contacts: [
          {
            name: formData.contactPersonName,
            email: formData.contactPersonEmail,
            countryCode: formData.contactPersonCountryCode,
            phoneNumber: parseInt(formData.contactPersonPhone),
            designation: formData.designation,
            isPrimary: true,
          },
        ],
        leadOwner:userId,
        leadConversion:"LEAD",
        createdBy:userId
      })
    );
    togglePopupHandler(false);
    // alert("Client created successfully!");
  };

  const handleMultiSelectChange = (selectedOptions) => {
    const engagementType = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData({ ...formData, engagementType });
  };

  return (
    <div className="p-6 w-[70%]  mx-auto bg-white shadow-md rounded-lg h-screenheight  overflow-y-auto no-scrollbar">
      <div className="flex justify-between ">
        <h1 className="text-2xl font-bold mb-6">Create Client</h1>
        <div
          onClick={() => togglePopupHandler(false)}
          className="hover:scale-90 hover:font-bold"
        >
          <CrossIcon color="black" />
        </div>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Client Details */}
        <div>
          <h2 className="text-lg font-semibold mb-2">Client Details</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <input
              type="text"
              name="clientName"
              placeholder="Client Name *"
              value={formData.clientName}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            />
            <input
              type="text"
              name="domain"
              placeholder="Client Domain (Ex:- google.com) *"
              value={formData.domain}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            />
            <input
              type="email"
              name="clientEmail"
              placeholder="Client Email "
              value={formData.clientEmail}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
            <input
              type="text"
              name="location"
              placeholder="Location"
              value={formData.location}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
          </div>
        </div>

        {/* Contact Person Details */}
        <div>
          <h2 className="text-lg font-semibold mb-2">Contact Person Details</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <input
              type="text"
              name="contactPersonName"
              placeholder="Name *"
              value={formData.contactPersonName}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            />
            <input
              type="email"
              name="contactPersonEmail"
              placeholder="Email *"
              value={formData.contactPersonEmail}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            />
            {/* Country Code and Phone */}
            <div className="flex gap-2">
              <select
                name="contactPersonCountryCode"
                value={formData.contactPersonCountryCode}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 w-1/3"
              >
                <option value="">Country code</option>
                {countries.map((code, index) => (
                  <option key={index} value={Math.floor(code.PhoneCode)}>
                    {code.Iso2} +{code.PhoneCode}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="contactPersonPhone"
                placeholder="Phone"
                value={Math.floor(formData.contactPersonPhone)}
                minLength={10}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only numeric values and ensure length is <= 10
                  if (/^\d*$/.test(value) && value.length <= 10) {
                    handleChange(e); // Update the state only if validation passes
                  }
                }}
                className="border border-gray-300 rounded-md p-2 w-2/3"
              />
            </div>
            <input
              type="text"
              name="designation"
              placeholder="Designation *"
              value={formData.designation}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              required
            />
          </div>
        </div>

        {/* Additional Details */}
        <div>
          <h2 className="text-lg font-semibold mb-2">Additional Details</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <select
              name="industry"
              value={formData.industry}
              onChange={handleIndustryChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Industry</option>
              {salesIndustry.map((industry, index) => (
                <option key={index} value={industry.Id}>
                  {industry.Title}
                </option>
              ))}
            </select>
            <select
              name="subIndustry"
              value={formData.subIndustry}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              disabled={!formData.industry} // Disable if no industry selected
            >
              <option value="">Select Sub-Industry</option>
              {formData.industry &&
                salesSubIndustry.map((sub, index) => (
                  <option key={index} value={sub.Id}>
                    {sub.Title}
                  </option>
                ))}
            </select>
            {/* <select
              name="engagementType"
              value={formData.engagementType}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Engagement Type</option>
              {salesEngangementType.map((eng, index) => (
                <option key={index} value={eng.Title}>
                  {eng.Title}
                </option>
              ))}
            </select> */}
            <Select
              isMulti
              name="engagementType"
              options={salesEngangementType?.map((eng) => ({
                label: eng.Title,
                value: eng.Id.toString(),
              }))}
              className="basic-multi-select"
              placeholder="Select Engagement Type"
              classNamePrefix="select"
              value={formData.engagementType.map((id) => ({
                label: salesEngangementType.find((eng) => eng.Id.toString() === id).Title,
                value: id,
              }))}
              onChange={handleMultiSelectChange}
            />

            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Status</option>
              {salesStatus.map((status, index) => (
                <option key={index} value={status.Id}>
                  {status.Title}
                </option>
              ))}
            </select>
            <select
              name="stages"
              value={formData.stages}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Stage</option>
              {salesStages.map((stage, index) => (
                <option key={index} value={stage.Id}>
                  {stage.Title}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="revenueExpectations"
              placeholder="Revenue Expectation"
              value={formData.revenueExpectations}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="text-right">
          <button
            type="submit"
            className="bg-primary-500 text-white px-6 py-2 rounded-md shadow hover:bg-primary-400"
          >
            Create Client
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientForm;
