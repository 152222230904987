import { useRef, useState } from "react";
import DownloadHrmsIcon from "../../../assets/Icons/DownloadHrmsIcon";
import FileIcon from "../../../assets/Icons/FileIcon";
import MenuIcon from "../../../assets/Icons/MenuIcon";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import UploadIcon from "../../../assets/Icons/UploadIcon";
import { DropdownButton } from "./DropdownButton";
import { useParams } from "react-router-dom";
import uploadContent from "../../../utils/upload-content/uploadContent";
import { updateDocumentStatusApi, uploadDocumentsApi } from "../../../slices/companyMemberSlice";

const roles = [
  { Id: 1, Title: "Form 16" },
  { Id: 2, Title: "Gratuity Nomination Form" },
  { Id: 3, Title: "Form 11" },

];

const docOptions = [
  {
    Id: 1,
    Title: "Approved",
    key: "approved"
  },
  {
    Id: 2,
    Title: "Reject",
    key: "reject"
  }
]

const StatutoryDocuments = () => {
  const dispatch = useAppDispatch();

  const documentList = useAppSelector((state) => state.member.documentList);
  const memberSelector = useAppSelector(
    (state) => state?.member.memberDetails
  );

  const userId = useAppSelector(
    (state) => state.login.session.userId
  );

  const [selectedRole, setRole] = useState(null);
  const hiddenFileInput = useRef(null);
  const { memberId } = useParams();

  const handleClick = (role) => {
    setRole(role)
    hiddenFileInput.current.click();
  };

  const handleFileUpload = (response) => {
    dispatch(uploadDocumentsApi({
      userID: userId,
      documentType: "statutory_document",
      documentName: selectedRole?.Title,
      documentURL: response[0].url,
      uploadedBy: memberSelector.id,
      status: 'In-Review'
    }));
  }

  const getStatus = (title) => {
    let doc = documentList?.filter((doc) => doc.documentName == title);
    if (doc) {
      return doc[0]?.status;
    }
  }

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      uploadContent.uploadEmployeeDocument(memberSelector.id, fileUploaded, handleFileUpload)
    }
  };

  const updateDocumentStatus = (item, role) => {
    const doc = getDocument(role.Title);
    dispatch(updateDocumentStatusApi({
      id: doc.id,
      userID: doc.userId,
      status: item.Title,
      updatedBy: userId
    }));
  }

  const getDocument = (title) => {
    let doc = documentList?.filter((doc) => doc.documentName == title);
    if (doc) {
      return doc[0];
    }
  }

  return (
    <div className="px-3 mx-auto">
      <div className="relative overflow-auto">
        <table className="w-full text-left text-gray-500">
          <thead className="text-xs text-gray-400">
            <tr>
              <th className="px-6 py-3 text-base w-3/12">Documents</th>
              <th className="px-6 py-3 text-base w-3/12">Uploader</th>
              {/* <th className="px-6 py-3 text-base w-3/12">Approval Date</th> */}
              <th className="px-6 py-3 text-base w-2/12">Status</th>
              <th className="px-6 py-3 text-base w-1/12">Action</th>
            </tr>
          </thead>
          <tbody>
            {
              roles?.map((role) => {
                return (
                  <tr
                    className="bg-white border-b border-exgray-200/60 text-primary-400"
                  >
                    <td className="py-4">
                      <div className="flex items-center">
                        <FileIcon />
                        <div className="ml-3">
                          <h1 className="">{role?.Title}</h1>
                          {/* <h1 className="text-xs text-exgray-200">Uploaded  on 5th Feb 2023</h1> */}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      {memberSelector?.firstName} {memberSelector?.lastName}
                    </td>

                    <td className="px-6 py-3">
                      {getStatus(role.Title) ?
                        <h1 className="text-secondary-400 font-medium">{getStatus(role.Title)}</h1>
                        : "Not Uploaded"
                      }
                    </td>
                    <td>
                      {
                        memberId == userId ?
                          <div className="items-center justify-between ml-10" onClick={() => handleClick(role)}>
                            <UploadIcon color="#0A65CC" height="20" width="20" />
                          </div>
                          :
                          <div className="flex justify-between items-center">
                            <div className="text-center">
                              <DownloadHrmsIcon />
                            </div>
                            <DropdownButton
                              defaultAction={(item, role) => updateDocumentStatus(item, role)}
                              buttonLabel={"Dropdown"}
                              items={docOptions}
                            />
                          </div>
                      }
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <input
          type="file"
          onChange={(event) => handleChange(event)}
          ref={hiddenFileInput}
          style={{ display: "none" }} // Make the file input element invisible
        />
      </div>
    </div>
  );
};

export default StatutoryDocuments;
