import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../../../assets/Icons/SearchIcon";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import {
  getCandidatesApi,
  getCandidatesByUrlApi,
} from "../../../../slices/referSlice";
import { unwrapResult } from "@reduxjs/toolkit";

import LoadingIcon from "../../../../assets/Icons/LoadingIcon";
import { v4 as uuidV4 } from "uuid";
import * as constants from "../../../../constant";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFoundIcon from "../../../../assets/Icons/NoDataFoundIcon";
import BundleUploadUserCard from "./components/BundleUploadUserCard";
import { toast } from "sonner";

const JobSeekers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchRef = useRef(null);

  // const [searchTerm, setSearchTerm] = useState("");

  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const candidateListSelector = useAppSelector(
    (state) => state?.refer?.candidateList?.candidates
  );

  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await dispatch(
          getCandidatesApi({
            authorId:
              loginSelector.who === constants.COMPANY.toLowerCase()
                ? loginSelector.userId
                : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
                ? memberSelector.companyId
                : loginSelector.userId,
            recruiterId:
              loginSelector.who === constants.COMPANY.toLowerCase()
                ? ""
                : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
                ? loginSelector.userId
                : loginSelector.userId,
          })
        ).then(unwrapResult);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error("Couldn't Load Data, Pleas try again!");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500); // Adjust delay as needed

    return () => clearTimeout(timer);
  }, [query]);

  // Fetch data when debouncedQuery changes
  useEffect(() => {
    searchCandidateHandler();
  }, [debouncedQuery]);

  const navigationHandler = (path) => {
    navigate(path);
  };

  const onChangeSearchHandler = (e) => {
    setQuery(e.target.value);
  };

  const searchCandidateHandler = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getCandidatesApi({
          authorId:
            loginSelector.who === constants.COMPANY.toLowerCase()
              ? loginSelector.userId
              : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
              ? memberSelector.companyId
              : loginSelector.userId,
          s: debouncedQuery,
        })
      ).then(unwrapResult);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getMoreDataHandler = () => {
    try {
      dispatch(
        getCandidatesByUrlApi({
          authorId:
            loginSelector.who === constants.COMPANY.toLowerCase()
              ? loginSelector.userId
              : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
              ? memberSelector.companyId
              : loginSelector.userId,
        })
      );
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };

  return (
    <div className="mx-auto">
      <div className="flex items-center justify-between">
        <p className="text-expurple-800 text-lg font-semibold">{`${
          candidateListSelector?.metadata?.totalCount
            ? candidateListSelector?.metadata?.totalCount
            : 0
        } Job Seekers`}</p>
        <div>
          {/* <button
            className="bg-transparent px-10 py-2 text-expurple-800 text-base font-semibold"
            onClick={() =>
              navigationHandler(
                loginSelector.who === constants.COMPANY.toLowerCase()
                  ? "/company-admin/jobs"
                  : loginSelector.who === constants.COMPANY_MEMBER.toLowerCase()
                  ? "/company-admin/jobs"
                  : loginSelector.who === constants.RECRUITER.toLowerCase()
                  ? "/recruiter-dashboard"
                  : ""
              )
            }
          >
            Back
          </button> */}
        </div>
      </div>

      <div className="flex items-center justify-between pb-2">
        <div className="w-[40%] relative pl-1">
          <input
            type="text"
            ref={searchRef}
            autoComplete="off"
            className="outline outline-2 outline-exgray-100 bg-exwhite-100 py-2 w-full text-base pr-10 placeholder:text-exgray-100 rounded-full text-exgray-400 pl-5 focus:outline-secondary-300"
            placeholder="Search"
            name="search"
            onChange={onChangeSearchHandler}
          />
          <div className="absolute top-0 right-2 h-full flex items-center justify-center">
            <SearchIcon color="#042347" />
          </div>
        </div>

        <div>
          <button
            className="px-8 py-2 text-base text-exwhite-100  rounded-md font-semibold w-fit  bg-expurple-800"
            onClick={() => navigationHandler("/company-admin/bulk-upload")}
          >
            Create
          </button>
        </div>
      </div>

      <div className="py-2">
        {!isLoading &&
        candidateListSelector?.user &&
        candidateListSelector?.user?.length > 0 ? (
          <InfiniteScroll
            className="rounded-lg"
            dataLength={
              candidateListSelector?.user?.length
                ? candidateListSelector?.user?.length
                : 0
            }
            next={() => getMoreDataHandler()}
            hasMore={candidateListSelector?.metadata?.nextResultURL}
            // height={"calc(100vh - 155px)"}
            loader={""}
          >
            <table className="min-w-full" cellSpacing="10">
              <thead className="bg-secondary-100">
                <tr className="text-left">
                  <th className="text-nowrap px-4 py-2 text-base text-primary-800 font-semibold">
                    Candidate
                  </th>
                  <th className="text-nowrap px-4 py-2 text-base text-primary-800 font-semibold">
                    Email Id
                  </th>
                  <th className="text-nowrap px-4 py-2 text-base text-primary-800 font-semibold">
                    Phone Number
                  </th>
                  <th className="text-nowrap px-4 py-2 text-base text-primary-800 font-semibold">
                    Location
                  </th>
                  <th className="text-nowrap px-4 py-2 text-base text-primary-800 font-semibold">
                    Designation
                  </th>
                  <th className="text-nowrap px-4 py-2 text-base text-primary-800 font-semibold">
                    Yrs of Exp.
                  </th>
                  <th className="text-nowrap px-4 py-2 text-base text-primary-800 font-semibold">
                    Company Name
                  </th>
                  <th className="text-nowrap px-4 py-2 text-base text-primary-800 font-semibold">
                    Recruiter Name
                  </th>
                  <th className="text-nowrap px-4 py-2 text-base text-primary-800 font-semibold">
                    Uploaded On
                  </th>
                  <th className="text-nowrap px-4 py-2 text-base text-primary-800 font-semibold">
                    Resume
                  </th>
                </tr>
              </thead>
              <tbody>
                {candidateListSelector?.user.map((candidateDetails) => (
                  <BundleUploadUserCard
                    candidateDetails={candidateDetails}
                    key={uuidV4()}
                  />
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
        ) : candidateListSelector?.user &&
          candidateListSelector?.user?.length === 0 ? (
          <div>
            <div className="flex items-center justify-center pt-20">
              <NoDataFoundIcon />
            </div>
            <div>
              <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                No Data Found
              </h1>
              <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                Find new opportunities and manage your candidate search progress
                here.
              </p>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center py-20">
            <LoadingIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default JobSeekers;
