import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../../../../components/Header/Navbar";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { useEffect, useState } from "react";
import {
  getBandListApi,
  getCategoriesApi,
  getCitiesApi,
  getCompanySizeRangeApi,
  getCompanyTypesApi,
  getCountriesApi,
  getCurrencyTypeApi,
  getDepartmentApi,
  getDesignationListApi,
  getDesignationsApi,
  getEmloymentTypeApi,
  getExperienceMaxApi,
  getExperienceMinApi,
  getFunctionsApi,
  getGenderListApi,
  getIndustriesApi,
  getJobTypeApi,
  getLanguagesApi,
  getQualificationsApi,
  getRoleListApi,
  getSalaryMaxRangeApi,
  getSalaryRangeMinApi,
  getSalesEngangementTypeApi,
  getSalesIndustryApi,
  getSalesInteractionTypeApi,
  getSalesStagesApi,
  getSalesStatusApi,
  getSalesSubIndustryApi,
  getWorkplaceTypeApi,
} from "../../../../slices/appSlice";
import ToastNotification from "../../../../components/toast-notification/ToastNotification";
import { chatRoomActive, removeChat } from "../../../../slices/chatSlice";
import ChatRoom from "../../../chat/ChatRoom";
import Chat from "../../../chat/Chat";

const Main = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { currentChat, openChatRoom } = useAppSelector((state) => state.chat);

  const { session } = useAppSelector((state) => state?.login);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  // useEffect(() => {
  //   if (userId && userType === "user") navigate("/candidate-profile");
  // }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const offsets = [0, 500, 1000]; // Adjust as needed for your data size
        const citiesPromises = offsets.map((offset) =>
          dispatch(getCitiesApi({ offset }))
        );

        const otherPromises = [
          dispatch(getDesignationsApi(null)),
          dispatch(getCountriesApi(null)),
          dispatch(getIndustriesApi(null)),
          dispatch(getCategoriesApi(null)),
          dispatch(getFunctionsApi(null)),
          dispatch(getJobTypeApi(null)),
          dispatch(getWorkplaceTypeApi(null)),
          // dispatch(getCitiesApi(null)),
          dispatch(getLanguagesApi(null)),
          dispatch(getSalaryMaxRangeApi(null)),
          dispatch(getSalaryRangeMinApi(null)),
          dispatch(getCompanyTypesApi(null)),
          dispatch(getCompanySizeRangeApi(null)),
          dispatch(getExperienceMaxApi(null)),
          dispatch(getExperienceMinApi(null)),
          dispatch(getCurrencyTypeApi(null)),
          dispatch(getSalesEngangementTypeApi(null)),
          dispatch(getSalesIndustryApi(null)),
          dispatch(getSalesSubIndustryApi(null)),
          dispatch(getSalesStagesApi(null)),
          dispatch(getSalesStatusApi(null)),
          dispatch(getDepartmentApi(null)),
          dispatch(getBandListApi(null)),
          dispatch(getGenderListApi(null)),
          dispatch(getRoleListApi(null)),
          dispatch(getDesignationListApi(null)),
          dispatch(getQualificationsApi(null)),
          dispatch(getSalesInteractionTypeApi(null)),
          //dispatch(getEmloymentTypeApi(null)),
        ];
        await Promise.all([...citiesPromises, ...otherPromises]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [dispatch]);

  useEffect(() => {
    setActiveIndex(currentChat?.length - 1);
  }, [currentChat]);

  const handleRemoveChat = (conversationId) => {
    dispatch(removeChat(conversationId));
  };

  const openChat = () => {
    dispatch(chatRoomActive());
  };

  return (
    <>
      <div className={`${"fixed w-full z-50"}`}>
        <Navbar />
      </div>
      {!loading && (
        <div className="min-h-screenheight w-full pt-16 bg-primary-100/50">
          <Outlet />
        </div>
      )}

      <div className="fixed z-[1000]  bottom-0  right-0  gap-2 flex flex-row-reverse">
        {openChatRoom && (
          <div>
            <ChatRoom />
          </div>
        )}
        <div className="flex flex-row-reverse gap-4 items-end">
          {currentChat?.map((ele, i) => {
            return (
              <div key={"chat" + i}>
                {activeIndex == i ? (
                  <Chat
                    memberDetails={ele.member}
                    conversationId={ele?.conversationId}
                  />
                ) : (
                  <div
                    onClick={() => setActiveIndex(i)}
                    className=" bg-orange-600 px-7 py-2 flex items-center gap-2 rounded-t-lg "
                  >
                    <h1 className="text-white font-semibold text-md line-clamp-1">
                      {ele.member[0]?.firstName} {ele.member[0]?.lastName}
                    </h1>
                    <div onClick={() => handleRemoveChat(ele.conversationId)}>
                      {/* <CrossIcon width="10"/> */}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {/* {session?.userId && session?.who === "company_member" && (
        <div
          onClick={openChat}
          className="fixed z-[500]  bottom-4  right-4 bg-primary-400 cursor-pointer rounded-t-xl rounded-l-xl"
        >
          <h1 className="text-white text-lg px-8 font-semibold py-2">Chat</h1>
        </div>
      )} */}
      <ToastNotification />
    </>
  );
};

export default Main;
