import React, { useMemo } from "react";

import LocationIcon from "../../../../../assets/Icons/LocationIcon";
import moment from "moment";
import ClockIcon from "../../../../../assets/Icons/ClockIcon";
import useWorkPlaceTypes from "../../../../../utils/dropdown-options/useWorkPlaceTypes";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import { useAppSelector } from "../../../../../config/hooks";
import * as constant from "../../../../../constant";

const JobCard = ({ job, jobApplication = null }) => {
  const { defaultLocations } = useLocations();
  const { defaultWorkPlaceType } = useWorkPlaceTypes();

  const loginSelector = useAppSelector((state) => state?.login?.session);

  const loc = useMemo(() => {
    return job.location && job.location.length > 0
      ? defaultLocations(job.location)
      : null;
  }, [job.location]);

  const workplace = useMemo(() => {
    return job?.workplaceType ? defaultWorkPlaceType(job?.workplaceType) : "";
  }, [job?.workplaceType]);

  const jobRelativeTime = job?.createdAt
    ? moment(job?.createdAt).fromNow()
    : "";

  const jobCreationDate = job?.createdAt
    ? moment(job?.createdAt).format("DD MMMM YYYY")
    : "";

  return (
    <div
      className={`bg-exwhite-100 h-full rounded-lg p-5 space-y-2 cursor-pointer border border-exgray-100/70 ${
        job.jobStatus === "CLOSED" && "opacity-40"
      }`}
      style={{ boxShadow: "5px 5px 5px 0px #E7E9EC" }}
    >
      <div className="flex items-center justify-between gap-2">
        <h1 className="text-lg text-black font-semibold line-clamp-2 self-start flex-1">
          {job?.title}
        </h1>

        <div className="h-16 w-16 bg-deafultCompanyProfile rounded-md bg-cover bg-center bg-no-repeat"></div>
      </div>

      <div className="flex items-center justify-between gap-2">
        <div className="bg-secondary-100 px-4 py-1 rounded-full">
          <h1 className="text-secondary-400 text-center text-base line-clamp-1">
            {(job?.hideCompany && !job?.authorId === loginSelector?.userId) ||
            (job?.hideCompany && !loginSelector)
              ? "Confidential"
              : job?.company}
          </h1>
        </div>

        {/* <h1 className="text-primary-500 text-xl font-semibold">Int6063</h1> */}
      </div>

      {/* <div className="flex items-center justify-between gap-2">
        <div className="self-stretch ml-3 flex-1 space-y-1">
          <div className="flex items-center justify-start gap-2">
            <p className=" flex-1 text-base text-black font-semibold line-clamp-1">
              {job?.hideCompany ? "Confidential" : job?.company}
            </p>
            {jobApplication?.status && jobApplication?.status === "HOLD" && (
              <div className="px-4 border border-red-500 text-base text-red-500 font-semibold text-center w-fit rounded-full line-clamp-1">
                Hold
              </div>
            )}

            {jobApplication?.status &&
              jobApplication?.status === "REJECTED" && (
                <div className="px-4 border border-red-500 text-base text-red-500 font-semibold text-center w-fit rounded-full line-clamp-1">
                  Rejected
                </div>
              )}

            {jobApplication?.status &&
              jobApplication?.status === "SHORTLISTED" && (
                <div className="px-4 border border-green-800 text-base text-green-800 font-semibold text-center w-fit rounded-full line-clamp-1">
                  Shortlisted
                </div>
              )}
          </div>
        </div>
        {job?.workplaceType && workplace?.Title && (
          <div className="self-start ml-auto mr-0">
            <p className="py-1 px-4 rounded-full bg-[#CEECD5] text-green-900 whitespace-nowrap line-clamp-1">
              {workplace?.Title}
            </p>
          </div>
        )}
      </div> */}

      <div className="flex items-center justify-start gap-2 text-base text-exgray-300 border-b pb-2 border-exgray-100">
        <div className="w-fit">
          <LocationIcon width="20" height="20" color="#6C7275" />
        </div>
        <h1 className="flex-1 line-clamp-1">
          {loc && loc.length > 0
            ? loc.map(
                (l, i) => `${l?.Name}${loc?.length - 1 !== i ? ", " : ""}`
              )
            : "N/A"}
        </h1>
      </div>
      <div className="flex items-center justify-between w-fit ml-auto mr-0">
        <div className="flex-1 flex items-center justify-start gap-2">
          {loginSelector?.who === constant.USER.toLowerCase() ||
          !loginSelector?.userId ? (
            <>
              <div className="w-fit">
                <ClockIcon width="20" height="20" color="#6C7275" />
              </div>
              <h1 className="flex-1 text-base text-exgray-300 line-clamp-1">
                {`Posted ${jobRelativeTime}`}
              </h1>
            </>
          ) : (
            <h1 className="flex-1 text-base text-exgray-300 line-clamp-1">
              {`${jobCreationDate}`}
            </h1>
          )}
        </div>

        {/* {job.jobStatus === "CLOSED" && (
          <div className="px-4 border border-red-500 text-base text-red-500 font-semibold text-center w-fit rounded-full">
            Closed
          </div>
        )} */}
      </div>
    </div>
  );
};

export default JobCard;
