import React, { useEffect, useRef, useState } from "react";
import UploadIcon from "../../../../assets/Icons/UploadIcon";
import uploadContent from "../../../../utils/upload-content/uploadContent";
import { v4 as uuidV4 } from "uuid";
import FileIcon from "../../../../assets/Icons/FileIcon";
import CrossIcon from "../../../../assets/Icons/CrossIcon";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getCvDetailsApi } from "../../../../slices/aiSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import * as constants from "../../../../constant";
import ErrorIcon from "../../../../assets/Icons/ErrorIcon";
import LoadingIcon from "../../../../assets/Icons/LoadingIcon";
import { bulkUploadRegisterApi } from "../../../../slices/loginSlice";
import Popup from "../../../../components/popup/Popup";
import { useNavigate } from "react-router-dom";
import {
  addBulkUserEducationApi,
  addBulkUserExperienceApi,
  createBulkUploadAdditionalDetailsApi,
  updateBulkUserProfileApi,
} from "../../../../slices/candidateProfileSlice";
import moment from "moment";
import { toast } from "sonner";
import SuccessIcon from "../../../../assets/Icons/SuccessIcon";

const BulkUpload = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [resumes, setResumes] = useState([]);
  const [resumesCopy, setResumesCopy] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [getCreatedResponse, setGetCreatedResponse] = useState(false);
  const [successCount, setSuccessCount] = useState(0);
  const [exceedCvCounts, setExceedCvCounts] = useState(false);

  const loginSelector = useAppSelector((state) => state?.login?.session);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const userType = useAppSelector((state) => state?.login?.session?.who);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const imageInputRef = useRef(null);

  // useEffect(() => {
  //   let newArray = [];
  //   if (resumes.length > 0 && resumesCopy.length > 0) {
  //     for (let index = 0; index < resumes.length; index++) {
  //       let foundMatch = false;

  //       for (let j = 0; j < resumesCopy.length; j++) {
  //         if (resumes.length - 1 === j) setIsLoading(false);

  //         if (
  //           resumes[index].orginalFileName === resumesCopy[j].orginalFileName
  //         ) {
  //           newArray.push({
  //             orginalFileName: resumesCopy[j].orginalFileName,
  //             isUploaded: resumesCopy[j].isUploaded,
  //             isLoading: false,
  //             data: resumesCopy[j].data,
  //             url: resumesCopy[j].url,
  //             contentType: resumesCopy[j].contentType,
  //             size: resumesCopy[j].size,
  //             errorType: resumesCopy[j].errorType,
  //           });
  //           foundMatch = true; // Mark as found and break
  //           break;
  //         }
  //       }

  //       // If no match was found, push the original resume
  //       if (!foundMatch) {
  //         newArray.push(resumes[index]);
  //       }
  //     }

  //     setResumes(newArray);
  //   }
  // }, [resumesCopy]);

  useEffect(() => {
    if (resumes.length === 0) {
      setGetCreatedResponse(false);
    }
  }, [resumes]);

  const handleClick = () => {
    if (imageInputRef.current) imageInputRef.current.click();
  };

  const removeResumeHanlder = (index) => {
    const filteredArray = resumes.filter((_data, i) => i !== index);
    setResumes(filteredArray);
    setResumesCopy(filteredArray);
  };

  const removeAllHandler = () => {
    setResumes([]);
    setResumesCopy([]);
    setGetCreatedResponse(false);
  };

  const navigateHandler = (path) => {
    navigate(path);
  };

  const resumeDetailsHandler = async (uploadContent) => {
    if (uploadContent[0].isUploaded) {
      const formData = new FormData();
      formData.append(
        "resumes",
        `${constants.baseUrl}/${uploadContent[0].url}?t=${loginSelector.token}`
      );

      try {
        const response = await dispatch(getCvDetailsApi(formData)).then(
          unwrapResult
        );

        if (
          uploadContent[0].orginalFileName.includes(".xlsx") ||
          uploadContent[0].orginalFileName.includes(".csv")
        ) {
          CreateMultipleusers(response.data, uploadContent[0]);
        } else {
          if (response.data.email && response.data.phoneNo) {
            CreateSingleUser(response.data, uploadContent[0]);
          } else {
            setResumes((prevResumes) => [
              ...prevResumes,
              {
                orginalFileName: uploadContent[0].orginalFileName,
                isUploaded:
                  response.data.email && response.data.phoneNo
                    ? uploadContent[0].isUploaded
                    : false,
                isLoading: false,
                data: response.data,
                url: uploadContent[0].url,
                contentType: uploadContent[0].contentType,
                size: uploadContent[0].size,
                errorType: "Email and phone number are missing",
              },
            ]);
          }
        }
      } catch (error) {
        setResumes((prevResumes) => [
          ...prevResumes,
          {
            orginalFileName: uploadContent[0].orginalFileName,
            isUploaded: false,
            isLoading: false,
            data: null,
            url: "",
            contentType: "",
            size: 0,
            errorType: "File could not be read. Please try again",
          },
        ]);
      }
    } else {
      setResumes((prevResumes) => [
        ...prevResumes,
        {
          orginalFileName: uploadContent[0].orginalFileName,
          isUploaded: uploadContent[0].isUploaded,
          isLoading: uploadContent[0].isLoading,
          data: null,
          url: "",
          contentType: "",
          size: 0,
          errorType: "Failed to load the file. Please try again!",
        },
      ]);
    }
  };

  const processFilesSequentially = async (files) => {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      await new Promise((resolve) => {
        uploadContent.bulkUploadFiles(
          loginSelector?.userId,
          file,
          async (response) => {
            await resumeDetailsHandler(response);
            resolve();
          }
        );
      });
    }
    setIsLoading(false);
  };

  const onChangeHandler = (e) => {
    setGetCreatedResponse(false);
    setIsLoading(true);
    setResumes([]);

    processFilesSequentially(Array.from(e.target.files));
  };

  const CreateMultipleusers = async (userProfileData, xlFiledata) => {
    setResumes((prevResumes) => [
      ...prevResumes,
      {
        orginalFileName: xlFiledata.orginalFileName,
        isUploaded: true,
        isLoading: false,
        data: null,
        url: xlFiledata.url,
        contentType: xlFiledata.contentType,
        size: xlFiledata.size,
        errorType: "",
      },
    ]);

    if (userProfileData && userProfileData.length > 0) {
      for (const usrData of userProfileData) {
        const userSignUpData = [
          {
            firstName: usrData.firstName,
            lastName: usrData.lastName,
            emailId: usrData?.email ? usrData?.email : "",
            countryCode: usrData?.countryCode
              ? parseInt(usrData?.countryCode)
              : 91,
            phoneNo: usrData?.phoneNo ? parseInt(usrData?.phoneNo) : 0,
            yearsOfExperience: usrData?.totalExperience
              ? usrData?.totalExperience.toString()
              : usrData?.yearOfExperience
              ? usrData?.yearOfExperience.toString()
              : "",
            designation: usrData?.designation ? usrData?.designation : "",
            location: usrData?.location ? usrData?.location : "",
            industry: usrData?.industry ? [usrData?.industry] : null,
            socialInfo: {
              linkedin: {
                profileUrl: usrData?.socialInfo[0]
                  ? usrData?.socialInfo[0]
                  : "",
              },
            },
            organization: usrData?.organization ? usrData?.organization : "",
            // orginalFileName: resumeData?.orginalFileName
            //   ? resumeData.orginalFileName
            //   : "",
            // resume: {
            //   name: resumeData?.orginalFileName
            //     ? resumeData?.orginalFileName
            //     : "",
            //   url: resumeData?.url ? resumeData?.url : "",
            //   contentType: resumeData.contentType ? resumeData.contentType : "",
            //   size: resumeData?.size ? resumeData?.size : 0,
            // },
            authorType:
              userType === constants.COMPANY.toLowerCase() ||
              userType === constants.COMPANY_MEMBER.toLowerCase()
                ? "COMPANY"
                : "RECRUITER",
            authorId:
              userType === constants.COMPANY.toLowerCase()
                ? userId
                : userType === constants.COMPANY_MEMBER.toLowerCase()
                ? memberSelector.companyId
                : userType === constants.RECRUITER.toLowerCase()
                ? userId
                : "",
            recruiterId:
              userType === constants.COMPANY_MEMBER.toLowerCase() ? userId : "",
            skills: usrData.SkillsInfo,
            experience: usrData?.professionalHistory,
            education: usrData?.educationHistory,
          },
        ];

        createXlUser(userSignUpData);
        await delay(10);
      }
    }
  };

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const createXlUser = async (userSignUpData) => {
    try {
      const response = await dispatch(
        bulkUploadRegisterApi(userSignUpData)
      ).then(unwrapResult);

      if (response.data.conflicts && response.data.conflicts.length > 0) {
        toast.error("User alredy exists.");
      } else if (
        response.data.message === "All users registered successfully" &&
        response.data.status === "success"
      ) {
        response.data.data.forEach((userData) => {
          toast.success("Profile created successfully.");

          if (
            userSignUpData?.experience &&
            userSignUpData.experience?.length > 0
          ) {
            userSignUpData.experience.forEach((exp) => {
              const startDateMonth = exp.startDate
                ? moment(exp.startDate).format("MMMM")
                : "";
              const startDateYear = exp.startDate
                ? moment(exp.startDate).format("YYYY")
                : "";
              const endDateMonth = exp.endDate
                ? exp.endDate === "Present" || exp.endDate === "till date"
                  ? moment().format("MMMM")
                  : moment(exp.endDate).format("MMMM")
                : "";
              const endDateYear = exp.endDate
                ? exp.endDate === "Present" || exp.endDate === "till date"
                  ? moment().format("YYYY")
                  : moment(exp.endDate).format("YYYY")
                : "";
              dispatch(
                addBulkUserExperienceApi({
                  userId: userData.userId,
                  organization: exp?.organization ? exp?.organization : "",
                  organizationId: "",
                  userExperienceDetails: [
                    {
                      location: exp.location ? exp.location : "",
                      locationId: "",
                      locationType: "",
                      // employmentType: employmentType.toString(),
                      title: exp.designation ? exp.designation : "",
                      description: exp.description ? exp.description : "",
                      startDate: {
                        month: startDateMonth,
                        year: startDateYear,
                      },
                      endDate: {
                        month: endDateMonth,
                        year: endDateYear,
                      },
                      isCurrentRole:
                        exp.endDate === "Present" || exp.endDate === "till date"
                          ? true
                          : false,
                      skills: ["Java", "Software"],
                    },
                  ],
                })
              );
            });
          }

          // if (
          //   userProfileData?.educationHistory &&
          //   userProfileData?.educationHistory?.length > 0
          // ) {
          //   userProfileData?.educationHistory.forEach((edu) => {
          //     const startDateMonth = edu.startDate
          //       ? moment(edu.startDate).format("MMMM")
          //       : "";
          //     const startDateYear = edu.startDate
          //       ? moment(edu.startDate).format("YYYY")
          //       : "";
          //     const endDateMonth = edu.endDate
          //       ? moment(edu.endDate).format("MMMM")
          //       : "";
          //     const endDateYear = edu.endDate
          //       ? moment(edu.endDate).format("YYYY")
          //       : "";
          //     dispatch(
          //       addBulkUserEducationApi({
          //         userId: userData.userId,
          //         institute: edu.institute ? edu.institute : "",
          //         organizationId: "",
          //         degree: edu.degree ? edu.degree : "",
          //         description: edu.description ? edu.description : "",
          //         grade: edu.grade ? edu.grade : "",
          //         fieldOfStudy: edu.fieldOfStudy ? edu.fieldOfStudy : "",
          //         activitiesAndSocieties: [],
          //         startDate: {
          //           month: startDateMonth,
          //           year: startDateYear,
          //         },
          //         endDate: {
          //           month: endDateMonth,
          //           year: endDateYear,
          //         },
          //       })
          //     );
          //   });
          // }

          // if (userSignUpData?.skills && userSignUpData?.skills?.length > 0) {
          //   dispatch(
          //     updateBulkUserProfileApi({
          //       userId: userData?.userId,
          //       skills: userSignUpData?.skills,
          //     })
          //   );
          // }

          // if (userSignUpData?.industry) {
          //   dispatch(
          //     updateBulkUserProfileApi({
          //       userId: userData.userId,
          //       industry: [userSignUpData?.industry],
          //     })
          //   );
          // }

          // if (userSignUpData?.socialInfo?.linkedin?.profileUrl) {
          //   dispatch(
          //     updateBulkUserProfileApi({
          //       userId: userData.userId,
          //       socialInfo: {
          //         Twitter: {
          //           type: "twitter",
          //           profileURL:
          //             userSignUpData?.socialInfo?.linkedin?.profileUrl,
          //         },
          //       },
          //     })
          //   );
          // }
        });
      }
    } catch (error) {
      toast.error("Failed to create user profile.");
    }
  };

  const CreateSingleUser = async (userProfileData, resumeData) => {
    const userSignUpData = [
      {
        recruiterId:
          userType === constants.COMPANY_MEMBER.toLowerCase() ? userId : "",
        firstName: userProfileData?.firstName,
        lastName: userProfileData?.lastName,
        emailId: userProfileData?.email ? userProfileData?.email : "",
        countryCode: userProfileData?.countryCode
          ? parseInt(userProfileData?.countryCode)
          : 91,
        phoneNo: userProfileData?.phoneNo
          ? parseInt(userProfileData?.phoneNo)
          : 0,
        yearsOfExperience: userProfileData?.totalExperience
          ? userProfileData?.totalExperience.toString()
          : userProfileData?.yearOfExperience
          ? userProfileData?.yearOfExperience.toString()
          : "",
        designation: userProfileData?.designation
          ? userProfileData?.designation
          : "",
        location: userProfileData?.location ? userProfileData?.location : "",
        industry:
          userProfileData?.industry &&
          userProfileData?.industry.length > 0 &&
          userProfileData?.industry[0]
            ? [userProfileData?.industry[0].toString()]
            : null,
        socialInfo: {
          linkedin: {
            profileUrl: userProfileData?.socialInfo[0]
              ? userProfileData?.socialInfo[0]
              : "",
          },
        },
        organization: userProfileData?.organization
          ? userProfileData?.organization
          : "",
        orginalFileName: resumeData?.orginalFileName
          ? resumeData?.orginalFileName
          : "",
        resume: {
          name: resumeData?.orginalFileName ? resumeData?.orginalFileName : "",
          url: resumeData?.url ? resumeData?.url : "",
          contentType: resumeData?.contentType ? resumeData?.contentType : "",
          size: resumeData?.size ? resumeData?.size : 0,
        },
        authorType:
          userType === constants.COMPANY.toLowerCase() ||
          userType === constants.COMPANY_MEMBER.toLowerCase()
            ? "COMPANY"
            : "RECRUITER",
        authorId:
          userType === constants.COMPANY.toLowerCase()
            ? userId
            : userType === constants.COMPANY_MEMBER.toLowerCase()
            ? memberSelector?.companyId
            : userType === constants.RECRUITER.toLowerCase()
            ? userId
            : "",
        skills: userProfileData.SkillsInfo,
        experience: userProfileData.professionalHistory,
        education: userProfileData.educationHistory,
      },
    ];

    try {
      const response = await dispatch(
        bulkUploadRegisterApi(userSignUpData)
      ).then(unwrapResult);

      if (response.data.conflicts && response.data.conflicts.length > 0) {
        setResumes((prevResumes) => [
          ...prevResumes,
          {
            orginalFileName: resumeData.orginalFileName,
            isUploaded: false,
            isLoading: false,
            data: null,
            url: resumeData.url,
            contentType: resumeData.contentType,
            size: resumeData.size,
            errorType: "User already exists",
          },
        ]);
      } else if (
        response.data.message === "All users registered successfully" &&
        response.data.status === "success"
      ) {
        response.data.data.forEach((userData) => {
          setResumes((prevResumes) => [
            ...prevResumes,
            {
              orginalFileName: resumeData.orginalFileName,
              isUploaded: true,
              isLoading: false,
              data: null,
              url: resumeData.url,
              contentType: resumeData.contentType,
              size: resumeData.size,
              errorType: "",
            },
          ]);

          toast.success("Profile created successfully");

          if (
            userProfileData?.SkillsInfo &&
            userProfileData?.SkillsInfo?.length > 0
          ) {
            dispatch(
              updateBulkUserProfileApi({
                userId: userData?.userId,
                skills: userProfileData?.SkillsInfo,
              })
            );
          }

          if (
            userProfileData?.professionalHistory &&
            userProfileData.professionalHistory?.length > 0
          ) {
            userProfileData.professionalHistory.forEach((exp) => {
              // const startDateMonth = exp.startDate
              //   ? moment(exp.startDate).format("MMMM")
              //   : "";
              // const startDateYear = exp.startDate
              //   ? moment(exp.startDate).format("YYYY")
              //   : "";
              // const endDateMonth = exp.endDate
              //   ? exp.endDate === "Present" || exp.endDate === "till date"
              //     ? moment().format("MMMM")
              //     : moment(exp.endDate).format("MMMM")
              //   : "";
              // const endDateYear = exp.endDate
              //   ? exp.endDate === "Present" || exp.endDate === "till date"
              //     ? moment().format("YYYY")
              //     : moment(exp.endDate).format("YYYY")
              //   : "";
              dispatch(
                addBulkUserExperienceApi({
                  userId: userData.userId,
                  organization: exp?.organization ? exp?.organization : "",
                  organizationId: "",
                  userExperienceDetails: [
                    {
                      location: exp.location ? exp.location : "",
                      locationId: "",
                      locationType: "",
                      // employmentType: employmentType.toString(),
                      title: exp.designation ? exp.designation : "",
                      description: exp.description ? exp.description : "",
                      startDate: {
                        month: exp?.expStartDate?.Month,
                        year: exp?.expStartDate?.Year,
                      },
                      endDate: {
                        month: exp?.expEndDate?.Month,
                        year: exp?.expEndDate?.Year,
                      },
                      isCurrentRole: exp.isCurrentWorking,
                      skills: ["Java", "Software"],
                    },
                  ],
                })
              );
            });
          }

          if (
            userProfileData?.educationHistory &&
            userProfileData?.educationHistory?.length > 0
          ) {
            userProfileData?.educationHistory.forEach((edu) => {
              // const startDateMonth = edu.startDate
              //   ? moment(edu.startDate).format("MMMM")
              //   : "";
              // const startDateYear = edu.startDate
              //   ? moment(edu.startDate).format("YYYY")
              //   : "";
              // const endDateMonth = edu.endDate
              //   ? moment(edu.endDate).format("MMMM")
              //   : "";
              // const endDateYear = edu.endDate
              //   ? moment(edu.endDate).format("YYYY")
              //   : "";
              dispatch(
                addBulkUserEducationApi({
                  userId: userData.userId,
                  institute: edu.institute ? edu.institute : "",
                  organizationId: "",
                  degree: edu.degree ? edu.degree : "",
                  description: edu.description ? edu.description : "",
                  grade: edu.grade ? edu.grade : "",
                  fieldOfStudy: edu.fieldOfStudy ? edu.fieldOfStudy : "",
                  activitiesAndSocieties: [],
                  startDate: {
                    month: edu?.acaStartDate?.Month,
                    year: edu?.acaStartDate?.Year,
                  },
                  endDate: {
                    month: edu?.acaEndDate?.Month,
                    year: edu?.acaEndDate?.Year,
                  },
                })
              );
            });
          }

          if (userProfileData?.industry) {
            dispatch(
              updateBulkUserProfileApi({
                userId: userData.userId,
                industry: [userProfileData?.industry[0].toString()],
              })
            );
          }

          if (userProfileData?.socialInfo[0]) {
            dispatch(
              updateBulkUserProfileApi({
                userId: userData.userId,
                socialInfo: {
                  Twitter: {
                    type: "twitter",
                    profileURL: userProfileData?.socialInfo[0],
                  },
                },
              })
            );
          }
        });
      }
    } catch (error) {
      setResumes((prevResumes) => [
        ...prevResumes,
        {
          orginalFileName: resumeData.orginalFileName,
          isUploaded: false,
          isLoading: false,
          data: null,
          url: resumeData.url,
          contentType: resumeData.contentType,
          size: resumeData.size,
          errorType: "Internal server error",
        },
      ]);
    }
  };

  // const createUser = async () => {
  //   if (resumes.length > 0 && !getCreatedResponse) {
  //     const newArray = resumes.map((resumeData) => {
  //       if (resumeData.isUploaded) {
  //         const userProfileData = resumeData.data;
  //         return {
  //           firstName: userProfileData.firstName,
  //           lastName: userProfileData.lastName,
  //           emailId: userProfileData?.email ? userProfileData?.email : "",
  //           countryCode: userProfileData?.countryCode
  //             ? parseInt(userProfileData?.countryCode)
  //             : 91,
  //           phoneNo: userProfileData?.phoneNo
  //             ? parseInt(userProfileData?.phoneNo)
  //             : 0,
  //           yearsOfExperience: userProfileData?.totalExperience
  //             ? userProfileData?.totalExperience.toString()
  //             : userProfileData?.yearOfExperience
  //             ? userProfileData?.yearOfExperience.toString()
  //             : "",
  //           designation: userProfileData?.designation
  //             ? userProfileData?.designation
  //             : "",
  //           location: userProfileData?.location
  //             ? userProfileData?.location
  //             : "",
  //           industry: userProfileData?.industry
  //             ? [userProfileData?.industry]
  //             : null,
  //           socialInfo: {
  //             linkedin: {
  //               profileUrl: userProfileData?.socialInfo[0]
  //                 ? userProfileData?.socialInfo[0]
  //                 : "",
  //             },
  //           },
  //           organization: userProfileData?.organization
  //             ? userProfileData?.organization
  //             : "",
  //           orginalFileName: resumeData?.orginalFileName
  //             ? resumeData.orginalFileName
  //             : "",
  //           resume: {
  //             name: resumeData?.orginalFileName
  //               ? resumeData?.orginalFileName
  //               : "",
  //             url: resumeData?.url ? resumeData?.url : "",
  //             contentType: resumeData.contentType ? resumeData.contentType : "",
  //             size: resumeData?.size ? resumeData?.size : 0,
  //           },
  //           authorType:
  //             userType === constants.COMPANY.toLowerCase() ||
  //             userType === constants.COMPANY_MEMBER.toLowerCase()
  //               ? "COMPANY"
  //               : "RECRUITER",
  //           authorId:
  //             userType === constants.COMPANY.toLowerCase()
  //               ? userId
  //               : userType === constants.COMPANY_MEMBER.toLowerCase()
  //               ? memberSelector.companyId
  //               : userType === constants.RECRUITER.toLowerCase()
  //               ? userId
  //               : "",
  //           recruiterId:
  //             userType === constants.COMPANY_MEMBER.toLowerCase() ? userId : "",
  //           skills: userProfileData.SkillsInfo,
  //           experience: userProfileData.professionalHistory,
  //           education: userProfileData.educationHistory,
  //           // interest: [],
  //           // languages: [],
  //         };
  //       } else {
  //         return {};
  //       }
  //     });

  //     const filteredArray = newArray.filter(
  //       (obj) => Object.keys(obj).length !== 0
  //     );

  //     try {
  //       const response = await dispatch(
  //         bulkUploadRegisterApi(filteredArray)
  //       ).then(unwrapResult);

  //       // setGetCreatedResponse(true);

  //       if (response.data.conflicts && response.data.conflicts.length > 0) {
  //         const newArray = resumes.map((cvData) => {
  //           const conflict = response.data.conflicts.find(
  //             (conflict) => conflict.orginalFileName === cvData.orginalFileName
  //           );
  //           if (conflict) {
  //             return {
  //               ...cvData,
  //               isUploaded: false,
  //               errorType: "Already Exists",
  //             };
  //           } else {
  //             return cvData;
  //           }
  //         });

  //         setResumes(newArray);
  //       }

  //       if (
  //         response.data.message === "Some users already exist" &&
  //         response.data.status === "error" &&
  //         response?.data?.success &&
  //         response?.data?.success.length > 0
  //       ) {
  //         setSuccessCount(response.data.success.length);

  //         response.data.success.forEach((userData) => {
  //           const resumeDetail = newArray.find(
  //             (fileData) =>
  //               fileData.orginalFileName === userData.orginalFileName
  //           );

  //           if (
  //             resumeDetail?.experience &&
  //             resumeDetail?.experience?.length > 0
  //           ) {
  //             resumeDetail?.experience.forEach((exp) => {
  //               const startDateMonth = exp.startDate
  //                 ? moment(exp.startDate).format("MMMM")
  //                 : "";
  //               const startDateYear = exp.startDate
  //                 ? moment(exp.startDate).format("YYYY")
  //                 : "";
  //               const endDateMonth = exp.endDate
  //                 ? exp.endDate === "Present" || exp.endDate === "till date"
  //                   ? moment().format("MMMM")
  //                   : moment(exp.endDate).format("MMMM")
  //                 : "";
  //               const endDateYear = exp.endDate
  //                 ? exp.endDate === "Present" || exp.endDate === "till date"
  //                   ? moment().format("YYYY")
  //                   : moment(exp.endDate).format("YYYY")
  //                 : "";

  //               dispatch(
  //                 addBulkUserExperienceApi({
  //                   userId: userData.userId,
  //                   organization: exp?.organization ? exp?.organization : "",
  //                   organizationId: "",
  //                   userExperienceDetails: [
  //                     {
  //                       location: exp.location ? exp.location : "",
  //                       locationId: "",
  //                       locationType: "",
  //                       // employmentType: employmentType.toString(),
  //                       title: exp.designation ? exp.designation : "",
  //                       description: exp.description ? exp.description : "",
  //                       startDate: {
  //                         month: startDateMonth,
  //                         year: startDateYear,
  //                       },
  //                       endDate: {
  //                         month: endDateMonth,
  //                         year: endDateYear,
  //                       },
  //                       isCurrentRole:
  //                         exp.endDate === "Present" ||
  //                         exp.endDate === "till date"
  //                           ? true
  //                           : false,
  //                       skills: ["Java", "Software"],
  //                     },
  //                   ],
  //                 })
  //               );
  //             });
  //           }

  //           if (
  //             resumeDetail?.education &&
  //             resumeDetail?.education?.length > 0
  //           ) {
  //             resumeDetail?.education.forEach((edu) => {
  //               const startDateMonth = edu.startDate
  //                 ? moment(edu.startDate).format("MMMM")
  //                 : "";
  //               const startDateYear = edu.startDate
  //                 ? moment(edu.startDate).format("YYYY")
  //                 : "";
  //               const endDateMonth = edu.endDate
  //                 ? moment(edu.endDate).format("MMMM")
  //                 : "";
  //               const endDateYear = edu.endDate
  //                 ? moment(edu.endDate).format("YYYY")
  //                 : "";

  //               dispatch(
  //                 addBulkUserEducationApi({
  //                   userId: userData.userId,
  //                   institute: edu.institute ? edu.institute : "",
  //                   organizationId: "",
  //                   degree: edu.degree ? edu.degree : "",
  //                   description: edu.description ? edu.description : "",
  //                   grade: edu.grade ? edu.grade : "",
  //                   fieldOfStudy: edu.fieldOfStudy ? edu.fieldOfStudy : "",
  //                   activitiesAndSocieties: [],
  //                   startDate: {
  //                     month: startDateMonth,
  //                     year: startDateYear,
  //                   },
  //                   endDate: {
  //                     month: endDateMonth,
  //                     year: endDateYear,
  //                   },
  //                 })
  //               );
  //             });
  //           }

  //           if (resumeDetail.skills && resumeDetail.skills.length > 0) {
  //             dispatch(
  //               updateBulkUserProfileApi({
  //                 userId: userData.userId,
  //                 skills: resumeDetail.skills,
  //               })
  //             );
  //           }

  //           if (resumeDetail.industry && resumeDetail.industry) {
  //             dispatch(
  //               updateBulkUserProfileApi({
  //                 userId: userData.userId,
  //                 industry: resumeDetail.industry,
  //               })
  //             );
  //           }

  //           if (resumeDetail?.socialInfo?.linkedin?.profileUrl) {
  //             dispatch(
  //               updateBulkUserProfileApi({
  //                 userId: userData.userId,
  //                 socialInfo: {
  //                   Twitter: {
  //                     type: "twitter",
  //                     profileURL:
  //                       resumeDetail?.socialInfo?.linkedin?.profileUrl,
  //                   },
  //                 },
  //               })
  //             );
  //           }
  //         });
  //       } else if (
  //         response.data.message === "All users registered successfully" &&
  //         response.data.status === "success"
  //       ) {
  //         navigate("/company-admin/success");
  //         response.data.data.forEach((userData) => {
  //           const resumeDetail = newArray.find(
  //             (fileData) =>
  //               fileData.orginalFileName === userData.orginalFileName
  //           );

  //           if (
  //             resumeDetail?.experience &&
  //             resumeDetail?.experience?.length > 0
  //           ) {
  //             resumeDetail?.experience.forEach((exp) => {
  //               const startDateMonth = exp.startDate
  //                 ? moment(exp.startDate).format("MMMM")
  //                 : "";
  //               const startDateYear = exp.startDate
  //                 ? moment(exp.startDate).format("YYYY")
  //                 : "";
  //               const endDateMonth = exp.endDate
  //                 ? exp.endDate === "Present" || exp.endDate === "till date"
  //                   ? moment().format("MMMM")
  //                   : moment(exp.endDate).format("MMMM")
  //                 : "";
  //               const endDateYear = exp.endDate
  //                 ? exp.endDate === "Present" || exp.endDate === "till date"
  //                   ? moment().format("YYYY")
  //                   : moment(exp.endDate).format("YYYY")
  //                 : "";

  //               dispatch(
  //                 addBulkUserExperienceApi({
  //                   userId: userData.userId,
  //                   organization: exp?.organization ? exp?.organization : "",
  //                   organizationId: "",
  //                   userExperienceDetails: [
  //                     {
  //                       location: exp.location ? exp.location : "",
  //                       locationId: "",
  //                       locationType: "",
  //                       // employmentType: employmentType.toString(),
  //                       title: exp.designation ? exp.designation : "",
  //                       description: exp.description ? exp.description : "",
  //                       startDate: {
  //                         month: startDateMonth,
  //                         year: startDateYear,
  //                       },
  //                       endDate: {
  //                         month: endDateMonth,
  //                         year: endDateYear,
  //                       },
  //                       isCurrentRole:
  //                         exp.endDate === "Present" ||
  //                         exp.endDate === "till date"
  //                           ? true
  //                           : false,
  //                       skills: ["Java", "Software"],
  //                     },
  //                   ],
  //                 })
  //               );
  //             });
  //           }

  //           if (
  //             resumeDetail?.education &&
  //             resumeDetail?.education?.length > 0
  //           ) {
  //             resumeDetail?.education.forEach((edu) => {
  //               const startDateMonth = edu.startDate
  //                 ? moment(edu.startDate).format("MMMM")
  //                 : "";
  //               const startDateYear = edu.startDate
  //                 ? moment(edu.startDate).format("YYYY")
  //                 : "";
  //               const endDateMonth = edu.endDate
  //                 ? moment(edu.endDate).format("MMMM")
  //                 : "";
  //               const endDateYear = edu.endDate
  //                 ? moment(edu.endDate).format("YYYY")
  //                 : "";

  //               dispatch(
  //                 addBulkUserEducationApi({
  //                   userId: userData.userId,
  //                   institute: edu.institute ? edu.institute : "",
  //                   organizationId: "",
  //                   degree: edu.degree ? edu.degree : "",
  //                   description: edu.description ? edu.description : "",
  //                   grade: edu.grade ? edu.grade : "",
  //                   fieldOfStudy: edu.fieldOfStudy ? edu.fieldOfStudy : "",
  //                   activitiesAndSocieties: [],
  //                   startDate: {
  //                     month: startDateMonth,
  //                     year: startDateYear,
  //                   },
  //                   endDate: {
  //                     month: endDateMonth,
  //                     year: endDateYear,
  //                   },
  //                 })
  //               );
  //             });
  //           }

  //           if (resumeDetail.skills && resumeDetail.skills.length > 0) {
  //             dispatch(
  //               updateBulkUserProfileApi({
  //                 userId: userData.userId,
  //                 skills: resumeDetail.skills,
  //               })
  //             );
  //           }

  //           if (resumeDetail.industry && resumeDetail.industry) {
  //             dispatch(
  //               updateBulkUserProfileApi({
  //                 userId: userData.userId,
  //                 industry: resumeDetail.industry,
  //               })
  //             );
  //           }

  //           if (resumeDetail?.socialInfo?.linkedin?.profileUrl) {
  //             dispatch(
  //               updateBulkUserProfileApi({
  //                 userId: userData.userId,
  //                 socialInfo: {
  //                   Twitter: {
  //                     type: "twitter",
  //                     profileURL:
  //                       resumeDetail?.socialInfo?.linkedin?.profileUrl,
  //                   },
  //                 },
  //               })
  //             );
  //           }
  //         });
  //       }
  //     } catch (error) {
  //       if (error) {
  //         setError(true);
  //       }
  //     }
  //   } else {
  //     removeAllHandler();
  //   }
  // };

  return (
    <div>
      <div className="mx-auto">
        <div className="flex items-center justify-between">
          <h1 className="text-lg text-expurple-800 text-start font-semibold">
            Create Candidate profile
          </h1>
        </div>
        {resumes.length === 0 && !isLoading && (
          <div>
            <h1 className="text-base text-exgray-200 font-semibold text-start">
              Upload Candidate Resumes
            </h1>
            <div
              className="h-56 w-full flex items-center justify-center rounded-sm border-2 border-dashed border-exgray-100 hover:border-secondary-400  bg-exgray-100/10 hover:bg-secondary-100 cursor-pointer group"
              onClick={handleClick}
            >
              <div className="text-center w-[90%] mx-auto">
                <div className="cursor-pointer w-fit mx-auto">
                  <div>
                    <UploadIcon
                      height="40"
                      width="40"
                      className="group-hover:stroke-secondary-400"
                    />
                  </div>
                  <input
                    ref={imageInputRef}
                    type="file"
                    name="files"
                    multiple
                    accept=".pdf, .doc, .docx, .csv, .xlsx"
                    onChange={onChangeHandler}
                    style={{ display: "none" }}
                  />
                </div>
                <h2 className="text-xl text-exgray-400 font-semibold text-center group-hover:text-secondary-400">
                  Browse Resumes
                </h2>
                <p className="text-base text-exgray-200 font-normal text-center max-w-xl mx-auto group-hover:text-secondary-400">
                  Upload multiple resumes in bulk to automatically create
                  candidate profiles. Accepted formats include PDF, DOCX
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center justify-between gap-2 my-2">
          <h1 className="text-base text-exgray-200 text-start font-semibold">{`${
            !resumes ? "0" : resumes.length
          } Uploaded Files`}</h1>

          {getCreatedResponse && successCount !== 0 && (
            <h1 className="text-center">
              {` ${successCount} out of ${resumes.length} files were created successfully.`}
            </h1>
          )}

          {resumes && resumes?.length > 0 && (
            <button
              className="text-base text-expurple-800 text-start font-semibold"
              onClick={removeAllHandler}
            >
              Remove All
            </button>
          )}
        </div>

        {resumes && resumes.length > 0 && (
          <div className="space-y-2 mt-2">
            {resumes.map((resume, index) => (
              <div
                className="flex items-center gap-2 justify-center"
                key={uuidV4()}
              >
                <div
                  className={`w-full p-2 ${
                    resume.isUploaded ? "bg-[#CEECD5]" : "bg-red-200/70"
                  } rounded-md flex items-center justify-between gap-2`}
                >
                  {resume.isUploaded ? (
                    <FileIcon width="32" height="32" />
                  ) : (
                    <div className="">
                      <ErrorIcon />
                    </div>
                  )}
                  <h1 className="flex-1">{resume.orginalFileName}</h1>

                  {resume.isUploaded && (
                    <h1 className="mr-4 text-[#098023] font-semibold flex items-center justify-center gap-2 flex-row-reverse">
                      Successfully created
                      {/* <SuccessIcon width="40" height="40" /> */}
                    </h1>
                  )}

                  {resume.errorType && (
                    <h1 className="mr-4 text-[#E05151] font-semibold">
                      {resume.errorType}
                    </h1>
                  )}

                  <div
                    className="mr-2 cursor-pointer"
                    onClick={() => removeResumeHanlder(index)}
                  >
                    {resume.isLoading ? (
                      <LoadingIcon />
                    ) : (
                      <CrossIcon
                        width="15"
                        height="15"
                        color={resume.isUploaded ? "#098023" : "#E05151"}
                      />
                    )}
                  </div>
                </div>
                {/* {resume.isLoading && <LoadingIcon />} */}
              </div>
            ))}
          </div>
        )}
        {isLoading && <h1>Loading ...</h1>}
      </div>
      <Popup onClose={() => setError(false)} visible={error}>
        <div className="w-[40%] bg-expurple-200 p-10 rounded-xl space-y-2">
          <div className="w-fit mx-auto">
            <ErrorIcon width="50" height="50" />
          </div>
          <h1 className="text-center text-xl text-expurple-600 font-semibold">
            Somthing went wrong
          </h1>
          <p className="text-lg text-exgray-200 text-center">
            Please try again
          </p>
        </div>
      </Popup>

      <Popup onClose={() => setExceedCvCounts(false)} visible={exceedCvCounts}>
        <div className="w-[40%] bg-expurple-200 p-10 rounded-xl space-y-2">
          <div className="w-fit mx-auto">
            <ErrorIcon width="50" height="50" />
          </div>
          <h1 className="text-center text-xl text-expurple-600 font-semibold">
            You have exceeded the file limit of 10
          </h1>
          <p className="text-lg text-exgray-200 text-center">
            Please try again
          </p>
        </div>
      </Popup>
    </div>
  );
};

export default BulkUpload;
