import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../config/hooks";
// import { removeMemberApi } from "../../slices/companyMemberSlice";
// import { unwrapResult } from "@reduxjs/toolkit";
// import { toast } from "sonner";
import * as constants from "../../constant";
import { useNavigate } from "react-router-dom";
import ProfileAvatar from "../ProfileAvatar";

const MemberCard = ({ member, type }) => {
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const menuParentRef = useRef(null);

  // const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.login.session.token);

  const [toggleModal, setToggleModal] = useState(false);

  useEffect(() => {
    const toggleHandler = (e) => {
      if (
        menuRef.current &&
        menuParentRef &&
        !menuRef.current.contains(e.target) &&
        !menuParentRef.current.contains(e.target)
      ) {
        // close popup
        setToggleModal(false);
      }
    };

    document.addEventListener("mousedown", toggleHandler);

    return () => {
      document.removeEventListener("mousedown", toggleHandler);
    };
  });

  const toggleModalhandler = () => {
    setToggleModal(!toggleModal);
  };

  // const removeMemberHandler = async () => {
  //   try {
  //     await dispatch(removeMemberApi({ memberId: member.id })).then(
  //       unwrapResult
  //     );
  //     toggleModalhandler();
  //     toast.success("Member removed");
  //   } catch (error) {
  //     toast.error("Something went wrong");
  //   }
  // };

  const navigateHandler = () => {
    navigate(`/company-admin/employee-profile/${member?.id}`);
    // if (type == "onboarding") {
      
    // } else {
    //   navigate(`/company-admin/member-details/${member?.id}`);
    // }
  };

  return (
    <div
      className={`px-3 py-5 border border-exgray-100 shadow-xl rounded-xl space-y-1 cursor-pointer hover:border-secondary-400`}
      onClick={navigateHandler}
    >
      <div className="mx-auto w-fit">
        {member?.picUrl ? (
          <div className="h-20 w-20  rounded-full flex items-center justify-center overflow-hidden">
            <img
              src={`${constants.baseUrl}/${member?.picUrl}?t=${token}`}
              alt="profile"
              className="w-full h-full object-cover"
            />
          </div>
        ) : (
          member?.firstName &&
          member?.lastName && (
            <ProfileAvatar
              firstName={member?.firstName}
              lastName={member?.lastName}
            />
          )
        )}
      </div>

      <div>
        <h1 className="text-lg text-primary-400 font-medium text-center line-clamp-1">
          {member?.firstName && member?.firstName
            ? `${member?.firstName && member?.firstName} ${
                member?.lastName && member?.lastName
              }`
            : "UnKnown"}
        </h1>

        <p className="text-base text-exgray-500 font-normal text-center line-clamp-1">
          {member.jobProfile}
        </p>

        <p className="text-sm text-exgray-200 font-normal text-center line-clamp-1">
          {member?.emailId ? member?.emailId : "Unknown"}
        </p>
      </div>

      <h1
        className={`text-base ${
          type == "onboarding" ? "text-secondary-400" : "text-primary-400"
        } font-semibold text-center line-clamp-1`}
      >
        {member?.employeeCode ? member.employeeCode : "Emp_Code_Missing"}
      </h1>
      {type == "onboarding" && (
        <div className="bg-green-100 rounded-lg items-center justify-center py-1">
          <h1 className="text-secondary-400 text-xs text-center">Onboarding</h1>
        </div>
      )}
    </div>
  );
};

export default MemberCard;
