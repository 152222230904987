import apiClient from "../config/axiosConfig";
import { sideMenuSlice } from "../slices/sideMenuSlice";

class AiService {
  getCvDetails(req) {
    return apiClient.post(`/v1/resume_details`, req, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getJdDetails(req) {
    return apiClient.post(`/v1/jd_autofilling`, req, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  globalSearchCandidate(data) {
    return apiClient.post(
      `https://api.prod.linkcxo.com/v1/global_search`,
      data
    ); //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }
}

const aiService = new AiService();
export default aiService;
