import React, { useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import PlusCircleIcon from "../../../../assets/Icons/PlusCircleIcon";
import useLocations from "../../../../utils/dropdown-options/useLocations";
import ExMultiDropdown from "../../../../components/basic-components/ExMultiDropdown";
import useIndustries from "../../../../utils/dropdown-options/useIndustries";
import NoDataFoundIcon from "../../../../assets/Icons/NoDataFoundIcon";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getMappedCandidatesAPi } from "../../../../slices/aiSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";
import Tooltip from "../../../../components/tool-tip/Tooltip";
import { getJobDetailApi } from "../../../../slices/jobSlice";
import { useParams } from "react-router-dom";

const GlobalSearch = () => {
  const dispatch = useAppDispatch();

  const { jobId } = useParams();

  const { globalSearch } = useAppSelector((state) => state.ai);

  const { defaultIndustries } = useIndustries();

  const { error, isLoading, mappedCandidates } = globalSearch;

  const btnRef = useRef(null);

  const { locationsArray } = useLocations();
  const { industriesArray } = useIndustries();

  const form = useForm({
    defaultValues: {
      jobTitle: "",
      targetCompanies: [{ company: "" }],
      location: [],
    },
  });

  const { handleSubmit, control, formState } = form;
  const { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    name: "targetCompanies",
    control,
  });

  const addOptionHandler = () => {
    append({ company: "" });
  };

  useEffect(() => {
    const getJobDetails = async () => {
      const toasId = toast.loading("Retrieving job data.");
      try {
        const response = await dispatch(getJobDetailApi({ jobId })).then(
          unwrapResult
        );
        const data = response.data.data[0];

        const industries =
          data?.industries && data?.industries.length > 0
            ? defaultIndustries(data?.industries)
            : [];

        const globalSearchData = {
          industry: industries && industries.length > 0 ? industries : [],
          jobTitle: data?.title,
          sub_industry: null,
          iterations: 1,
          Master_Excel_Path: null,
        };
        globalSearchHandler(globalSearchData);
        toast.dismiss(toasId);
      } catch (error) {
        toast.dismiss(toasId);
        toast.error("Unable to fetch job details");
      }
    };

    if (jobId) {
      getJobDetails();
    }
  }, []);

  const globalSearchHandler = async (formData) => {
    const resultString = formData?.industry?.map((obj) => obj.Name).join(", ");
    const toastId = toast.loading("Fetching data...");
    try {
      const response = await dispatch(
        getMappedCandidatesAPi({
          industry: resultString,
          title: formData?.jobTitle,
          sub_industry: null,
          iterations: 1,
          Master_Excel_Path: null,
        })
      ).then(unwrapResult);
      console.log("response :>> ", response.data);
      toast.dismiss(toastId);
      if (response.data.length === 0) toast.error("Candidates not found");
      else toast.success("Candidates fetched successfully");
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("Something went wrong!");
    }
  };

  const onSubmit = (formData) => {
    globalSearchHandler(formData);
  };

  const submitFormhandler = () => {
    if (btnRef.current) btnRef.current.click();
  };

  return (
    <div className="space-y-2">
      <div
        className="p-4 space-y-4"
        style={{
          background:
            "linear-gradient(85.26deg, #2E82C7 21.55%, #42A5F6 93.37%)",
        }}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-xl text-exwhite-100 font-semibold">
            AI Global Search Engine
          </h1>

          <div className="flex items-center justify-between gap-2">
            <button className="border border-exwhite-100 rounded-sm px-6 py-2 text-center text-base font-semibold text-exwhite-100 bg-[#3996E266] hover:bg-[#338AD1]">
              + Upload JD
            </button>

            <button
              className="border border-exwhite-100 rounded-sm px-12 py-2 text-center text-base font-semibold text-exwhite-100 bg-[#338AD1] hover:bg-[#FEEE91] hover:text-exgray-700 hover:border-[#FEEE91]"
              onClick={submitFormhandler}
            >
              Search
            </button>
          </div>
        </div>

        <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
          <button type="submit" ref={btnRef} className="hidden"></button>
          <div>
            <Controller
              name="jobTitle"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Job Title is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="space-y-1">
                  <div>
                    <input
                      className={`w-full h-10 px-3 bg-exwhite-100 rounded-md outline-none border ${
                        errors?.jobTitle?.message
                          ? "border-transparent"
                          : "border-transparent"
                      } text-exgray-800 placeholder:text-gray-400 text-base`}
                      id={"jobTitle"}
                      autoComplete="off"
                      placeholder="Job Title, keyword ... *"
                      onChange={onChange}
                      value={value || ""}
                    />
                  </div>
                  {errors?.jobTitle?.message && (
                    <p className="text-red-400">{errors?.jobTitle?.message}</p>
                  )}
                </div>
              )}
            />
          </div>

          <div className="grid grid-cols-2 auto-rows-auto gap-2">
            {fields.map((field, index) => {
              return (
                <div key={field.id}>
                  <Controller
                    name={`targetCompanies.${index}.company`}
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: "Required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div className="space-y-1">
                        <div
                          className={`w-full h-10 bg-exwhite-100 rounded-md outline-none border ${
                            errors?.targetCompanies?.[index]?.company?.message
                              ? "border-[#dc2623]"
                              : "border-transparent"
                          } flex items-center justify-between pr-2`}
                        >
                          <input
                            className="px-3 outline-none rounded-md w-full text-exgray-800 placeholder:text-gray-400 text-base flex-1"
                            id={"targetCompanies"}
                            name={`targetCompanies-${index}`}
                            autoComplete="off"
                            placeholder="Targeted Company"
                            onChange={onChange}
                            value={value || ""}
                          />

                          {index === 0 ? (
                            fields.length <= 5 && (
                              <button
                                className=""
                                type="button"
                                onClick={addOptionHandler}
                              >
                                <PlusCircleIcon />
                              </button>
                            )
                          ) : (
                            <button
                              className=""
                              type="button"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </button>
                          )}
                        </div>
                        {errors?.targetCompanies?.message && (
                          <p className="text-red-400">
                            {errors?.targetCompanies?.[index]?.company?.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              );
            })}

            <div>
              <Controller
                name="location"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Location is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExMultiDropdown
                    title=""
                    onChange={onChange}
                    placeholder="Location"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    options={locationsArray}
                    closeMenuOnSelect={false}
                    isMandatory={true}
                    dropDownHeight="auto"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="transparent"
                    menuListHeight="250px"
                    error={errors?.location?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="industry"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Industry is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExMultiDropdown
                    title=""
                    onChange={onChange}
                    placeholder="Industry *"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    options={industriesArray}
                    closeMenuOnSelect={false}
                    isMandatory={true}
                    dropDownHeight="auto"
                    dropDownBorderWidth="0px"
                    dropDownBorderColor="transparent"
                    menuListHeight="250px"
                    error={errors?.industry?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="noOfCompanies"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Job Title is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div className="space-y-1">
                    <div>
                      <input
                        className={`w-full h-10 px-3 bg-exwhite-100 rounded-md outline-none border ${
                          errors?.noOfCompanies?.message
                            ? "border-[#dc2623]"
                            : "border-transparent"
                        } text-exgray-800 placeholder:text-gray-400 text-base`}
                        id={"noOfCompanies"}
                        type="number"
                        autoComplete="off"
                        placeholder="Number of Companies"
                        onChange={onChange}
                        value={value || ""}
                      />
                    </div>
                    {errors?.noOfCompanies?.message && (
                      <p className="text-red-400">
                        {errors?.noOfCompanies?.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>

            <div>
              <Controller
                name="noOfProfiles"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Job Title is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div className="space-y-1">
                    <div>
                      <input
                        className={`w-full h-10 px-3 bg-exwhite-100 rounded-md outline-none border ${
                          errors?.noOfProfiles?.message
                            ? "border-[#dc2623]"
                            : "border-transparent"
                        } text-exgray-800 placeholder:text-gray-400 text-base`}
                        id={"noOfProfiles"}
                        type="number"
                        autoComplete="off"
                        placeholder="Number of Profiles"
                        onChange={onChange}
                        value={value || ""}
                      />
                    </div>
                    {errors?.noOfProfiles?.message && (
                      <p className="text-red-400">
                        {errors?.noOfProfiles?.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        </form>
      </div>

      <div className="">
        <div className="max-h-screenheight overflow-x-auto border-2 border-exgray-100 rounded-t-md">
          <table className="min-w-full bg-white">
            <thead className="">
              <tr className="text-left bg-gray-100">
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  ID
                </th>
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  Name
                </th>
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  Title
                </th>
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  Location
                </th>
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  TotalExperience
                </th>
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  CurrentCompany
                </th>
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  PreviousCompany
                </th>
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  Skills
                </th>
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  Education
                </th>
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  Experience
                </th>
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  Industry
                </th>
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  LinkedInID
                </th>
                <th className="px-4 py-2 text-sm font-semibold text-exgray-200">
                  ProfessionalSummary
                </th>
              </tr>
            </thead>
            <tbody>
              {mappedCandidates?.map((candidate, i) => (
                <tr key={i + "can"} className="hover:bg-gray-50">
                  <td className="px-4 py-2 text-sm text-gray-600">{i + 1}</td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {candidate["First Name"]} {candidate["Last Name"]}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {candidate.Title}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {candidate.Location}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {candidate.TotalExperience}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {candidate["Current Company"]}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {candidate["Previous Company"]}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    <Tooltip text={candidate.Skills}>
                      <div className="line-clamp-3">{candidate.Skills}</div>
                    </Tooltip>
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {candidate.Education}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    <Tooltip text={candidate.Experience}>
                      <div className="line-clamp-3">{candidate.Experience}</div>
                    </Tooltip>
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {candidate.Industry}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    {candidate["LinkedIn ID"]}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-600">
                    <Tooltip text={candidate["Professional Summary"]}>
                      <div className="line-clamp-3">
                        {candidate["Professional Summary"]}
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {mappedCandidates && mappedCandidates.length === 0 && (
            <div className="w-full">
              <div className="mx-auto w-fit py-5">
                <div className="flex items-center justify-center">
                  <NoDataFoundIcon />
                </div>
                <div>
                  <h1 className="text-3xl text-expurple-800/50 font-semibold text-center">
                    No Data Found
                  </h1>
                  <p className="text-base text-exgray-200 font-medium text-center max-w-sm mx-auto">
                    Could not find any data from the global search. Please try
                    again.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GlobalSearch;
