import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { PostDailyReport } from "../models/employee.model";
import attendanceService from "../services/attendance.service";

export interface Attendance{
  id?:string;
  employeeId: string;
  date?: Date;
  checkIn?: Date;
  checkOut?: Date;
  status?: string;
  attendanceId?:string;
  isRegularized?:boolean;
  reportingManagerDetails:ReportingManager;
}

export interface ReportingManager{
  firstName:string;
  lastName:string;
  profileImage:string;
  designation:string;
}

export interface GetDailyReport{
  employeeId:string;
}

export interface DailyReport{
  approvalStatus:string;
  approvedBy:string;
  attendanceId:string;
  createdAt:Date;
  dailyReport:Array<string>;
  employeeId:string;
  id:string;
  updatedAt:string;
}

export interface Regularization {
  id?: string;
  attendanceId: string;
  employeeId: string;
  originalCheckIn: Date;
  originalCheckOut: Date;
  requestedIn: Date;
  requestedOut: Date;
  reason: string;
  status?: string;
  approverId?: string;
}

export interface AttendanceFilter{
  status?:string;
  dateFrom?:string;
  dateTo?:string;
  page?:number;
  employeeId?:string;
}

export interface attendanceReduxState {
  attendanceList:Array<Attendance>;
  todaysReports:Array<string>;
  todayAttendance:Attendance;
  dailyReport:Array<DailyReport>;
}

const initialState = {
  attendanceList:[],
  todaysReports:[],
  todayAttendance:null,
  dailyReport:[]
};


export const postAttedanceApi = createAsyncThunk(
  "attendance/postAttedanceApi",
  async (data:Attendance, { getState, rejectWithValue }) => {
    try {
      console.log("you are in post attendance")
      const response = await attendanceService.postAttedance(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }

);

export const updateOutTimeApi = createAsyncThunk(
  "attendance/updateOutTimeApi",
  async (data:Attendance, { getState, rejectWithValue }) => {
    try {
      console.log("you are in post attendance")
      const response = await attendanceService.updateOutTime(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllAttendanceApi = createAsyncThunk(
  "attendance/getAllAttendanceApi",
  async (data:AttendanceFilter, { getState, rejectWithValue }) => {
    try {
      console.log("you are in post attendance")
      const response = await attendanceService.getAllAttendance(data);
      console.log(response,"thiis is get attendace response")
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postDailyReportApi = createAsyncThunk(
  "attendance/postDailyReportApi",
  async (data:PostDailyReport, { getState, rejectWithValue }) => {
    try {
      console.log("you are in post attendance")
      const response = await attendanceService.postDailyReport(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDailyReportApi = createAsyncThunk(
  "attendance/getDailyReportApi",
  async (data:GetDailyReport, { getState, rejectWithValue }) => {
    try {
      console.log("you are in post attendance")
      const response = await attendanceService.getDailyReport(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const attendanceRegularizeRequestApi = createAsyncThunk(
  "attendance/attendanceRegularizeRequestApi",
  async (data:Regularization, { getState, rejectWithValue }) => {
    try {
      console.log("you are in post attendance")
      const response = await attendanceService.regularizeRequest(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllAttendanceApi.fulfilled, (state, action) => {
      state.attendanceList = action.payload.data.data;
      if(action.meta.arg.employeeId !== state.todayAttendance?.employeeId || moment(state.todayAttendance?.checkIn).format("DD/MM/YYYY") !== moment().format("DD/MM/YYYY")){
        state.todayAttendance=null;
        if(state.attendanceList?.length > 0 && moment(state.attendanceList[0]?.checkIn).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")){
          state.todayAttendance = state.attendanceList[0];
        }
      }
    });
    builder.addCase(postAttedanceApi.fulfilled, (state, action) => {
      state.todayAttendance = action.payload.data;
      if(state.attendanceList?.length > 0){
        state.attendanceList =[action.payload.data,...state.attendanceList];
      }
      else{
        state.attendanceList =[action.payload.data];
      }
    });
    builder.addCase(updateOutTimeApi.fulfilled, (state, action) => {
      state.todayAttendance = action.payload.data;
    });
    builder.addCase(attendanceRegularizeRequestApi.fulfilled, (state, action) => {
      let attendanceList = state.attendanceList;
      attendanceList.map((attendance)=>{
        if(attendance.id == action.meta.arg.attendanceId){
          attendance.isRegularized=true;
        }
      });
      state.attendanceList = attendanceList;
    });
    builder.addCase(getDailyReportApi.fulfilled, (state, action) => {
      state.dailyReport = action.payload?.data?.reverse();
    });
    builder.addCase(postDailyReportApi.fulfilled, (state, action) => {
      console.log(action.payload.data,"action.payload.data");
    });
  }
});

//export const { removeRecruiterDetails } = attendanceSlice.actions;
export default attendanceSlice.reducer;
