import { unwrapResult } from "@reduxjs/toolkit";
import { store } from "../../store/store";
import { uploadFiles } from "../../slices/uploadSlice";

class UploadContent {
  //UPload PRofile Content
  async uploadProfileContent(postAuthor, data, callback) {
    const formData = new FormData();

    formData.append("key", "exotalent/" + postAuthor + "/candidate");
    for (let i = 0; i < data.length; i++) {
      formData.append("files", data.item(i));
    }

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          const atts = response.data.data.map((a) => {
            return {
              error: false,
              orginalFileName: a.orginalFileName,
              contentType: a.contentType,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            };
          });
          callback(atts);
        }
      })
      .catch((error) => {
        callback([
          {
            error: true,
          },
        ]);
      });
  }

  async uploadCompanyProfileContent(postAuthor, data, callback) {
    const formData = new FormData();

    formData.append("key", "exotalent/" + postAuthor + "/company");
    for (let i = 0; i < data.length; i++) {
      formData.append("files", data.item(i));
    }

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          const atts = response.data.data.map((a) => {
            return {
              contentType: a.contentType,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            };
          });
          callback(atts);
        }
      })
      .catch((error) => {});
  }

  async bulkUploadFiles(postAuthor, data, callback) {
    const formData = new FormData();
    formData.append("key", "exotalent/" + postAuthor + `/bulkUpload`);

    formData.append("files", data);

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          const atts = response.data.data.map((a) => {
            return {
              contentType: a.contentType,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
              orginalFileName: a.orginalFileName,
              isUploaded: true,
              isLoading: true,
            };
          });
          callback(atts);
        }
      })
      .catch((error) => {
        callback([
          {
            orginalFileName: data.name,
            isUploaded: false,
            isLoading: false,
          },
        ]);
      });
  }

  async uploadPandCard(data, callback) {
    const formData = new FormData();

    formData.append("key", "exotalent/pancard");
    for (let i = 0; i < data.length; i++) {
      formData.append("files", data.item(i));
    }

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          const atts = response.data.data.map((a) => {
            return {
              error: false,
              orginalFileName: a.orginalFileName,
              contentType: a.contentType,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            };
          });
          callback(atts);
        }
      })
      .catch((error) => {
        callback([
          {
            error: true,
          },
        ]);
      });
  }

  async uploadJd(postAuthor, data, callback) {
    const formData = new FormData();

    formData.append("key", "exotalent/" + postAuthor + "/jobDescription");

    for (let i = 0; i < data.length; i++) {
      formData.append("files", data.item(i));
    }

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          const atts = response.data.data.map((a) => {
            return {
              contentType: a.contentType,
              orginalFileName: a.orginalFileName,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            };
          });
          callback(atts);
        }
      })
      .catch((error) => {});
  }

  async uploadProfilePicture(author, data, callback) {
    const formData = new FormData();
    console.log(data,"datadatadata");
    formData.append("key", "exotalent/" + author + "/employeeProfile");
    formData.append("files", data);

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          const atts = response.data.data.map((a) => {
            return {
              contentType: a.contentType,
              orginalFileName: a.orginalFileName,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            };
          });
          callback(atts);
        }
      })
      .catch((error) => {});
  }

  async uploadEmployeeDocument(author, data, callback) {
    const formData = new FormData();
    console.log(data,"datadatadata");
    formData.append("key", "exotalent/" + author + "/employeeDocument");
    formData.append("files", data);

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          const atts = response.data.data.map((a) => {
            return {
              contentType: a.contentType,
              orginalFileName: a.orginalFileName,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            };
          });
          callback(atts);
        }
      })
      .catch((error) => {});
  }

  
  async uploadCRMSContent(postAuthor, data, callback) {
    const formData = new FormData();

    formData.append("key", "exotalent/" + postAuthor + "/CRMS");
    for (let i = 0; i < data.length; i++) {
      formData.append("files", data.item(i));
    }

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          const atts = response.data.data.map((a) => {
            return {
              error: false,
              orginalFileName: a.orginalFileName,
              contentType: a.contentType,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            };
          });
          callback(atts);
        }
      })
      .catch((error) => {
        callback([
          {
            error: true,
          },
        ]);
      });
  }

  
}

const uploadContent = new UploadContent();
export default uploadContent;
