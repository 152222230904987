import React, { ReactNode, useState } from "react";
interface TooltipProps {
  text: string; // The text displayed in the tooltip
  children: ReactNode; // The child element that the tooltip is attached to
}
const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);
  return (
    <div
      className="relative"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {isVisible && (
        <div className="absolute -top-1/2  transform -translate-x-1/2 p-2 bg-gray-700 text-white text-xs rounded-md shadow-lg opacity-100 transition-opacity duration-300 z-50">
          {text}
        </div>
      )}
    </div>
  );
};
export default Tooltip;
