import React, { useState } from "react";
import { FilterClientData } from "../../models/sales.model"; // Import the interface
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import Select from "react-select";
import { updateClientApi } from "../../slices/salesSlice";
import { toast } from "sonner";


const EditClientDetailForm = ({ togglePopupHandler }) => {
   const dispatch = useAppDispatch();
   const {clientDetail}=useAppSelector((state)=>state.sales)
   const {
    salesEngangementType,
    salesIndustry,
    salesSubIndustry,
    salesStages,
    salesStatus,
    countries,
    } = useAppSelector((state) => state.appConfig);

  const [formData, setFormData] = useState<FilterClientData>({
    
    industry: clientDetail.industry||"",
    subIndustry: clientDetail.subIndustry||"",
    location:clientDetail.location||"",
    status: clientDetail.status||"",
    stages: clientDetail.stages||"",
    engagementType:clientDetail.engagementType||[]
  });
 
    const handleChange = ( e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };



  const handleSubmit = () => {
    console.log(formData);


    dispatch(updateClientApi({id:clientDetail.id,...formData}))
    togglePopupHandler()
    toast.success("Updated Successfully")
  };


  const handleIndustryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setFormData({
        ...formData,
        industry: value,
        subIndustry: "", // Reset sub-industry when industry changes
      });
    };

  const handleMultiSelectChange = (selectedOptions) => {
    const engagementType = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];
    setFormData({ ...formData, engagementType });
    };
  return (
    <div className="p-6 w-[70%] bg-white rounded-lg shadow-md  max-h-screenheight overflow-y-auto  ">
      <h2 className="text-lg font-semibold mb-4">Edit lead</h2>

      <div>
          <div className="grid grid-cols-1  gap-4">
          <div>
            <label htmlFor="">Lead Name</label>
            <input
              type="text"
              name="clientName"
              placeholder=""
              defaultValue={clientDetail?.clientName}
              // value={formData.clientName}
              disabled
              // onChange={handleChange}
              className="font-semibold border-gray-300 rounded-md p-2 w-full"
            />
          </div>
          <div>
            <label htmlFor="">Domain</label>
            <input
              type="text"
              name="domain"
              placeholder=""
              defaultValue={clientDetail?.domain}
              // value={formData.clientName}
              disabled
              // onChange={handleChange}
              className="font-semibold border-gray-300 rounded-md p-2 w-full"          
            />
          </div>
              
            <div>
              <label htmlFor="">Industry</label>
              <select
                name="industry"
                value={formData.industry}
                onChange={handleIndustryChange}
                className="border border-gray-300 rounded-md p-2 w-full"
              >
                <option value="">Select Industry</option>
                {salesIndustry.map((industry, index) => (
                  <option key={index} value={industry.Id}>
                    {industry.Title}
                  </option>
                ))}
              </select>
            </div>
            <div>
            <label htmlFor="">Sub Industry</label>
            <select
              name="subIndustry"
              value={formData.subIndustry}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              disabled={!formData.industry} // Disable if no industry selected
            >
              <option value="">Select Sub-Industry</option>
              {formData.industry &&
                salesSubIndustry.map((sub, index) => (
                  <option key={index} value={sub.Id}>
                    {sub.Title}
                  </option>
                ))}
            </select>
            </div>
            {/* <select
              name="engagementType"
              value={formData.engagementType}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Engagement Type</option>
              {salesEngangementType.map((eng, index) => (
                <option key={index} value={eng.Title}>
                  {eng.Title}
                </option>
              ))}
            </select> */}
            <div>
            <label htmlFor="">Engagement Type</label>
            <Select
              isMulti
              name="engagementType"
              options={salesEngangementType?.map((eng) => ({
                label: eng.Title,
                value: eng.Title,
              }))}
              className="basic-multi-select"
              placeholder="Select Engagement Type"
              classNamePrefix="select"
              value={formData.engagementType.map((id) => ({
                label:  salesEngangementType.find((eng) => eng.Id.toString() === id)?.Title,
                value: id,
              }))}
              onChange={handleMultiSelectChange}
            />
            </div>

            <div>
            <label htmlFor="">Status</label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Status</option>
              {salesStatus.map((status, index) => (
                <option key={index} value={status.Id}>
                  {status.Title}
                </option>
              ))}
            </select>
            </div>

            <div>
            <label htmlFor="">Stages</label>
            <select
              name="stages"
              value={formData.stages}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              <option value="">Select Stage</option>
              {salesStages.map((stage, index) => (
                <option key={index} value={stage.Id}>
                  {stage.Title}
                </option>
              ))}
            </select>
            </div>

            <div>
            <label htmlFor="">Location</label>
            <input
              type="text"
              name="location"
              placeholder="Location"
              value={formData.location}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
            </div>
          </div>
        </div>

      {/* Buttons */}
      <div className="flex justify-end gap-4 mt-4">
        <button
          onClick={handleSubmit}
          className="bg-primary-500 text-white px-8 py-2 rounded-md shadow hover:bg-primary-400"
        >
          Save
        </button>
        
        
      </div>
    </div>
  );
};

export default EditClientDetailForm;
