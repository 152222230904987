import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../components/basic-components/ExInput";
import CrossIcon from "../../../assets/Icons/CrossIcon";
import CheckedIcon from "../../../assets/Icons/CheckedIcon";
import { Attendance, attendanceRegularizeRequestApi, postAttedanceApi, postDailyReportApi } from "../../../slices/attendanceSlice";
import { useEffect } from "react";
import ReactQuill from 'react-quill'; // Import the ReactQuill component
import 'react-quill/dist/quill.snow.css';
import CustomDatePicker from "../../../components/basic-components/CustomDatePicker";

export interface RegularizationFormProps {
  onClose: Function;
  attendance: Attendance;
  updateEndTime: Function;
}

const RegularizationForm = ({ onClose, attendance, updateEndTime }: RegularizationFormProps) => {
  const navigate = useNavigate();
  const memberDetail = useAppSelector((state) => state.company.memberDetails);
  const dispatch = useAppDispatch();

  const form = useForm({
    defaultValues: {
      reason: "",
      requestedIn: null,
      requestedOut: null,
      date: "",
    },
  });
  const { handleSubmit, control, formState, setValue } = form;
  const { errors } = formState;

  const handleInTimeChange = (time) => {
    const updatedDate = new Date(attendance.date);
    updatedDate.setHours(time.getHours());
    updatedDate.setMinutes(time.getMinutes());
    updatedDate.setSeconds(time.getSeconds());
    setValue("requestedIn", updatedDate);
  };

  const handleOutTimeChange = (time) => {
    const updatedDate = new Date(attendance.date);
    updatedDate.setHours(time.getHours());
    updatedDate.setMinutes(time.getMinutes());
    updatedDate.setSeconds(time.getSeconds());
    setValue("requestedOut", updatedDate);
  };

  const onSubmit = async (formData) => {
    dispatch(attendanceRegularizeRequestApi({
      attendanceId: attendance.id,
      employeeId: attendance.employeeId,
      originalCheckIn: attendance.checkIn,
      originalCheckOut: attendance.checkOut,
      requestedIn: formData.requestedIn,
      requestedOut: formData.requestedOut,
      reason: formData.reason,
      status: "InReview"
    }))
    onClose();
  };

  return (
    <div className="px-10 py-6 w-[60%] mx-auto bg-white shadow-md rounded-lg overflow-y-auto no-scrollbar">
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-xl text-primary-400 font-medium mb-1">
          Regularization
        </h1>
        <div onClick={() => onClose()}>
          <CrossIcon />
        </div>
      </div>
      <div className="h-full flex-1 justify-between">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-2 mt-4">
          <div className="w-full">
            <Controller
              name="date"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Please enter some text in input box",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  disabled={true}
                  title="Date"
                  name="date"
                  onChange={onChange}
                  selected={new Date(attendance.date)}
                  //maxDate={new Date()}
                  //minDate={new Date()}
                  showYearDropdown={true}
                  dropdownMode="select"
                  placeholderText="Ex. mm/dd/yyyy"
                  popperPlacement="bottom-end"
                  dateFormat="dd/MM/yyyy"
                  showMonthYearPicker={false}
                  error={errors?.reason?.message}
                  isMandatory={true}
                />
              )}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Controller
              name="requestedIn"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Please enter some text in input box",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  title="New Time In"
                  name="Select time"
                  onChange={(date) => handleInTimeChange(date)}
                  selected={value ? value : null}
                  maxDate={new Date(attendance.date)}
                  minDate={new Date(attendance.date)}
                  showYearDropdown={true}
                  dropdownMode="select"
                  placeholderText="Enter new in time"
                  popperPlacement="bottom-end"
                  dateFormat="p"
                  showMonthYearPicker={false}
                  error={errors?.requestedIn?.message}
                  isMandatory={true}
                  showTimeSelect={true}
                  timeIntervals={1}
                //inline
                />
              )}
            />
            <Controller
              name="requestedOut"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Please enter some text in input box",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  title="New Time Out"
                  name="Select time"
                  onChange={(date) => handleOutTimeChange(date)}
                  selected={value ? value : null}
                  maxDate={new Date(attendance.date)}
                  minDate={new Date(attendance.date)}
                  showYearDropdown={true}
                  dropdownMode="select"
                  placeholderText="Enter new out time"
                  popperPlacement="bottom-end"
                  dateFormat="p"
                  showMonthYearPicker={false}
                  showTimeSelect={true}
                  error={errors?.requestedOut?.message}
                  isMandatory={true}
                  timeIntervals={1}

                //inline
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="reason"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Please enter some text in input box",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ExInput
                  title={"Reason for Update"}
                  name={"type"}
                  onChange={onChange}
                  error={errors?.reason?.message}
                />
              )}
            />
          </div>
          <div className="flex items-end justify-end pt-5">
            <button
              onClick={() => handleSubmit(onSubmit)}
              //disabled={(startDateSelected == null && todayAttendance?.length == 0 || (todayAttendance?.length > 0 && todayAttendance[0]?.status))}
              className={`bg-primary-500 w-2/12 h-11 rounded-lg text-exwhite-100 text-lg`}>
              Submit
            </button>
          </div>
        </form>
      </div>


    </div>
  );
};

export default RegularizationForm;
