import React, { useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { createInteractionApi } from '../../slices/salesSlice';
import { useParams } from 'react-router-dom';
import moment from 'moment';
//import moment2 from 'moment-timezone';

import DatePicker from "react-datepicker";
import AttachmentIcon from '../../assets/Icons/AttachmentIcon';
import uploadContent from '../../utils/upload-content/uploadContent';
import * as constants from "../../constant"

const InteractionForm = ({ togglePopupHandler }) => {
  const { userId, who } = useAppSelector((state) => state.login?.session)
  const { salesInteractionType } = useAppSelector((state) => state.appConfig);
  const [uploadedDoc, setUploadedDoc] = useState([])
  const dispatch = useAppDispatch()
  const clientId = useParams()
  const imageInputRef = useRef(null);
  const [formData, setFormData] = useState({
    interaction: {
      clientId: clientId.clientId,
      interactionType: "",
      venue: "",
      followUptitle: "",
      interactionDate: new Date(),
      interactionPerson: "",
      remarks: "",
      purpose: "",
      authorId: userId,
      authorType: who.toUpperCase(),
      nextFollowUpDate: new Date(),
      createdBy: userId,
      updatedBy: userId
    },
    documents: []
  });

  const [isUpdate, setIsUpdate] = useState(false); // Determine if it's an update or create

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setFormData({
    //   ...formData,
    //   [name]: value,
    // });
    setFormData({
      ...formData,
      interaction: {
        ...formData.interaction,
        [name]: value
      }
    });
  };

  const handleDateChange = (date, name) => {
    // setFormData({
    //     ...formData,
    //     [name]: date
    // });
    setFormData({
      ...formData,
      interaction: {
        ...formData.interaction,
        [name]: date
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const localDateInteract = moment(formData.interactionDate, "YYYY-MM-DD").local();  // Local IST date
    // formData.interactionDate = localDateInteract.format("YYYY-MM-DDTHH:mm:ss[Z]");

    // const localDateFollow = moment(formData.nextFollowUpDate, "YYYY-MM-DD").local();  // Local IST date
    // formData.interactionDate = formData.interactionDate + ":00Z";
    // formData.nextFollowUpDate = formData.nextFollowUpDate + ":00Z";
    // formData.interactionDate = "2024-12-23T00:00:00Z";
    // formData.nextFollowUpDate = "2024-12-23T00:00:00Z";
    // formData.clientId=clientId.clientId
    formData.interaction.interactionPerson = formData.interaction.interactionPerson ? formData.interaction.interactionPerson : ""

    // formData.interaction.interactionDate = moment2(formData.interaction.interactionDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    // formData.interaction.nextFollowUpDate = moment2(formData.interaction.nextFollowUpDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    // formData.userId=userId
    console.log("formData Interaction", formData)
    togglePopupHandler()
    try {
      // dispatch(createInteractionApi(formData))

    } catch (error) {
      console.error("Error:", error);
      alert("There was an error submitting the form.");
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    console.log(files, "FIles")

    uploadContent.uploadCRMSContent(userId, e.target.files, (response) => {
      console.log(response, "Response")
      setFormData({
        ...formData,
        documents: response.map((ele) => {
          return {
            documentType: "INTERACTION",
            documentName: ele.orginalFileName,
            documentURL: ele.url,
            uploadedBy: userId
          }
        })
        // assuming response is an array of documents
      });
    })
    // if (files) {
    //     const fileArray = Array.from(files);
    //     setFormData({
    //         ...formData,
    //         files: fileArray
    //     });
    // }
  };

  const handleAttachmentClick = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };
  return (
    <form onSubmit={handleSubmit} className=" p-6 w-[70%] max-h-screenheight overflow-y-auto bg-white shadow-md rounded-lg">

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Interaction purpose:</label>
        <input
          type="text"
          name="purpose"
          value={formData.interaction.purpose}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Implementation Date:</label>
        <DatePicker
          selected={formData.interaction.interactionDate}
          onChange={(date) => handleDateChange(date, 'interactionDate')}
          showTimeSelect
          dateFormat="Pp"
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Participants:</label>
        <input
          type="text"
          name="interactionPerson"
          value={formData.interaction.interactionPerson || ""}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
        />
      </div>

      <div className='mb-4  '>
        <label className="text-gray-700 font-bold mb-2" htmlFor="">Interaction Type:</label>
        <select
          name="interactionType"
          value={formData.interaction.interactionType}
          onChange={handleChange}
          className="border border-gray-300 rounded-md p-2 w-full"
        >
          <option value="">Select Interaction Type</option>
          {salesInteractionType?.map((interaction, index) => (
            <option className='font-light' key={index} value={interaction.Id}>
              {interaction.Title}
            </option>
          ))}
        </select>
      </div>

      {formData.interaction.interactionType === "6" && (
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Venue:</label>
          <input
            type="text"
            name="venue"
            value={formData.interaction.venue}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
      )}


      <input
        ref={imageInputRef}
        type="file"
        name="files"
        multiple
        accept=".pdf, .doc, .docx, .csv, .xlsx"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />

      <div className="mb-4 relative ">
        <label className="block text-gray-700 font-bold mb-2">Meeting Tracker / Discussion Notes :</label>
        <textarea
          name="remarks"
          value={formData.interaction.remarks}
          onChange={handleChange}
          className="w-full pl-3 pr-8 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300 no-scrollbar"
        />
        <div onClick={handleAttachmentClick} className='absolute bottom-3 right-2 hover:scale-90 hover:cursor-pointer'>
          <AttachmentIcon color='#142243' />
        </div>
      </div>

      {formData.documents.length > 0 && <div className="mb-2 ">
        <h1 className='text-primary-500 bg-secondary-300 w-fit rounded-full p-2 font-semibold '>{formData.documents.map((ele) => ele.documentName).join(" , ")}</h1>

      </div>}



      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Follow-up Title:</label>
        <input
          type="text"
          name="followUptitle"
          value={formData.interaction.followUptitle}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Follow-Up Date:</label>
        <DatePicker
          selected={formData.interaction.nextFollowUpDate}
          onChange={(date) => handleDateChange(date, 'nextFollowUpDate')}
          showTimeSelect
          dateFormat="Pp"
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
        />
      </div>
      <div className='flex justify-end'>
        <button
          type="submit"
          className="w-fit bg-primary-500 text-white py-2 px-8 rounded-lg hover:bg-primary-400 focus:outline-none focus:ring focus:ring-blue-300"
        >
          Submit
        </button>
      </div>
    </form>
  );
}

export default InteractionForm