import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getAllClientsApi, getNextClientsApi } from "../../slices/salesSlice";
import Popup from "../../components/popup/Popup";
import ClientForm from "./ClientForm";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "sonner";
import FilterClientForm from "./FilterClientForm";
import SalesBulkUpload from "./SalesBulkUpload";
import { Link } from "react-router-dom";
import useSalesMultipleDropdown from "../../utils/dropdown-options/useSalesMultipleDropdown";
import SearchIcon from "../../assets/Icons/SearchIcon";
import FilterIcon from "../../assets/Icons/FilterIcon";
import moment from "moment";

const statusColors: Record<string, string> = {
  Warm: "bg-yellow-100 text-yellow-600",
  Hot: "bg-red-100 text-red-600",
  Cold: "bg-blue-100 text-blue-600",
};
const LeadManagement = () => {
  const dispatch = useAppDispatch();
  const allClients = useAppSelector((state) => state.sales.clients);
  const {
    salesEngangementType,
    salesIndustry,
    salesStages,
    salesStatus,
    salesSubIndustry,
  } = useAppSelector((state) => state.appConfig);
  const { clients } = allClients || {};
  const [search, setSearch] = useState("");
  const [togglePopup, setTogglePopup] = useState(false);
  const [toggleUploadPopup, setToggleUploadPopup] = useState(false);
  const [toggleFilterPopup, setToggleFilterPopup] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [filteredClients, setFilteredClients] = useState(clients || []);
  const {defaultSalesEngagementType}= useSalesMultipleDropdown()

  useEffect(() => {
    dispatch(getAllClientsApi());
  }, []);

  useEffect(() => {
    setFilteredClients(clients); // Sync filtered clients when clients change in Redux
  }, [clients]);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);

    const updatedClients = clients?.filter(
      (client) =>
        client.clientName.toLowerCase().includes(value) ||
        client.domain.toLowerCase().includes(value) ||
        client.location.toLowerCase().includes(value)
    );
    setFilteredClients(updatedClients);
  };

  // const handleFilter = (status: string) => {
  //   setFilterStatus(status);

  //   if (status) {
  //     const updatedClients = clients.filter(
  //       (client) => client.status.toLowerCase() === status.toLowerCase()
  //     );
  //     setFilteredClients(updatedClients);
  //   } else {
  //     setFilteredClients(clients);
  //   }
  // };

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };

  const toggleFIlterPopupHandler = () => {
    setToggleFilterPopup(!toggleFilterPopup);
  };

  const toggleUploadPopupHandler = () => {
    setToggleUploadPopup(!toggleUploadPopup);
  };

  const getMoreDataHandler = () => {
    try {
      console.log("HIT NEXT URL TABLE");
      dispatch(getNextClientsApi());
      // console.log(clients,"CLIENTSHHKDHJKDGH",filteredClients)
    } catch (error) {
      toast.error("Unable to fetch data");
    }
  };
  return (
    <div className="px-6 py-2 mx-auto  shadow-lg rounded-xl bg-white  ">
      <div className="py-2 flex justify-between items-center">
        <h1 className="text-xl font-semibold text-exgray-500">
          Lead Management
        </h1>
        <div className="flex gap-4">
          <button
            onClick={toggleUploadPopupHandler}
            className="border  border-primary-400 text-primary-400 px-4 py-2 font-semibold rounded-md shadow hover:scale-95"
          >
            Upload
          </button>
          <button
            onClick={togglePopupHandler}
            className=" bg-primary-400 text-white px-4 py-2 font-semibold rounded-md shadow hover:bg-primary-600 hover:scale-95"
          >
            <span className="">+</span> Add Lead
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4 relative">
        <div className="flex gap-2">
          <input
            type="text"
            placeholder="Search Clients..."
            value={search}
            onChange={handleSearch}
            className="border border-gray-300 rounded-md pl-8 pr-4 py-2 w-72 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="absolute top-2 pl-1">
            <SearchIcon/>
          </div>
          {/* <select
                value={filterStatus}
                onChange={(e) => handleFilter(e.target.value)}
                className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">All Status</option>
                <option value="Hot">Hot</option>
                <option value="Warm">Warm</option>
                <option value="Cold">Cold</option>
              </select> */}
        </div>
        <button
          onClick={toggleFIlterPopupHandler}
          className="bg-gray-100 text-gray-600 px-4 py-2 rounded-md shadow hover:bg-gray-200 flex gap-2"
        >
          <FilterIcon/>
          Filter
        </button>
      </div>
      <div className=" overflow-auto">
        <InfiniteScroll
          className=""
          dataLength={clients?.length || 0}
          next={() => getMoreDataHandler()}
          hasMore={allClients?.metadata?.nextResultURL ? true : false}
          height={"calc(100vh - 155px)"}
          loader={""}
        >
          <div className="relative ">
            <table className="min-w-full border border-gray-200 text-sm text-left text-gray-500 ">
              <thead className="bg-primary-400 text-xs text-white uppercase text-center sticky top-0 z-10">
                <tr>
                  <th className="px-6 py-3 border-r border-gray-400">Lead Name</th>
                  <th className="px-6 py-3 border-r border-gray-400">Lead Created</th>
                  <th className="px-6 py-3 border-r border-gray-400">Lead Owner</th>
                  <th className="px-6 py-3 border-r border-gray-400">Status</th>
                  <th className="px-6 py-3 border-r border-gray-400">Stages</th>
                  <th className="px-6 py-3 border-r border-gray-400">Contact Person</th>
                  <th className="px-6 py-3 border-r border-gray-400">Contact Person Email</th>
                  <th className="px-6 py-3 border-r border-gray-400">Contact Person Phone</th>
                  <th className="px-6 py-3 border-r border-gray-400">Lead Email</th>
                  <th className="px-6 py-3 border-r border-gray-400">Company Website</th>
                  <th className="px-6 py-3 border-r border-gray-400">Revenue Expectation</th>
                  <th className="px-6 py-3 border-r border-gray-400">Engangement Type</th>
                  {/* <th className="px-6 py-3 border-b">subStages</th> */}
                  <th className="px-6 py-3 border-r border-gray-400">Industry</th>
                  <th className="px-6 py-3 border-r border-gray-400">Sub Industry</th>
                  <th className="px-6 py-3 border-r border-gray-400">Location</th>
                  <th className="px-6 py-3 border-b">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {filteredClients?.map((client, index) => (
                  <tr
                    key={index}
                    className="bg-white hover:bg-gray-50 border-b text-gray-600 text-center "
                  >
                  
                    <td className="px-6 py-3 border border-gray-200 hover:text-primary-500 hover:font-semibold">
                      <Link className='w-full block' to={`/company-admin/sales/detail/${client.id}`}>
                        {client?.clientName}
                      </Link>
                    </td>
                    
                    <td className="px-6 py-3 border border-gray-200">{moment(client?.createdAt).format(' DD/MM/YYYY')}</td>
                    <td className="px-6 py-3 border border-gray-200">{client?.leadOwner}</td>
                    <td className="px-6 py-3 border border-gray-200">
                      <span
                        className={`px-3 py-1 rounded-md font-semibold ${
                          statusColors[client.status]
                        }`}
                      >
                        {
                          salesStatus.find(
                            (ele) => ele.Id.toString() === client?.status
                          )?.Title
                        }
                      </span>
                    </td>

                    <td className="px-6 py-3 border border-gray-200">
                      {
                        salesStages.find(
                          (ele) => ele.Id.toString() === client?.stages
                        )?.Title
                      }
                    </td>
                    {/* <td className="px-6 py-3">{salesStages.find((ele)=>ele.Id.toString()===client?.subStages)?.Title}</td> */}
                    
                    <td className="px-6 py-3 border border-gray-200">{client?.primaryContact?.name}</td>
                    <td className="px-6 py-3 border border-gray-200">{client?.primaryContact?.email}</td>
                    <td className="px-6 py-3 border border-gray-200">{client?.primaryContact?.phoneNumber}</td>
                    <td className="px-6 py-3 border border-gray-200">{client?.clientEmail}</td>
                    <td className="px-6 py-3 border border-gray-200">{client?.domain}</td>
                    <td className="px-6 py-3 border border-gray-200">{client?.revenueExpectations}</td>
                    <td className="px-6 py-3 border border-gray-200">{defaultSalesEngagementType(client?.engagementType)}</td>
                    
                    <td className="px-6 py-3 border border-gray-200">
                      {
                        salesIndustry.find(
                          (ele) => ele.Id.toString() === client?.industry
                        )?.Title
                      }
                    </td>
                    <td className="px-6 py-3 border border-gray-200">
                      {
                        salesSubIndustry.find(
                          (ele) => ele.Id.toString() === client?.subIndustry
                        )?.Title
                      }
                    </td>
                    <td className="px-6 py-3 border border-gray-200">{client?.location}</td>
                    <td className="px-6 py-3 border border-gray-200">{client?.remarks}</td>            
                  </tr>
                ))}
              </tbody>
            </table>
            {(filteredClients?.length === 0 || clients == null) && (
              <div className="text-center py-4 text-gray-500">
                No clients found.
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
      <Popup
        visible={togglePopup}
        onClose={togglePopupHandler}
        children={<ClientForm togglePopupHandler={togglePopupHandler} />}
      />
      <Popup
        visible={toggleFilterPopup}
        onClose={toggleFIlterPopupHandler}
        children={
          <FilterClientForm togglePopupHandler={toggleFIlterPopupHandler} />
        }
      />
      <Popup
        visible={toggleUploadPopup}
        onClose={toggleUploadPopupHandler}
        children={
          <SalesBulkUpload togglePopupHandler={toggleUploadPopupHandler} />
        }
      />
    </div>
  );
};

export default LeadManagement;
