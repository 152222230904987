import { useMemo } from "react";
import { Link } from "react-router-dom";
import useIndustries from "../../../../../utils/dropdown-options/useIndustries";
import * as constants from "../../../../../constant";
import axios from "axios";
import { useAppSelector } from "../../../../../config/hooks";
import DownloadIcon from "../../../../../assets/Icons/DownloadIcon";
import Tooltip from "../../../../../components/tool-tip/Tooltip";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import { toast } from "sonner";
import moment from "moment";
import CopyIcon from "../../../../../assets/Icons/CopyIcon";

const BundleUploadUserCard = ({ candidateDetails }) => {
  const { defaultIndustry } = useIndustries();
  const { defaultLocation } = useLocations();

  const token = useAppSelector((state) => state?.login?.session?.token);

  const defaultLoc = useMemo(() => {
    if (candidateDetails?.Location) {
      return defaultLocation(candidateDetails?.Location);
    }
    return null;
  }, [candidateDetails?.Location, defaultLocation]);

  const date = useMemo(() => {
    return candidateDetails?.createdAt
      ? moment(candidateDetails?.createdAt).format("DD MMMM YYYY")
      : "";
  }, [candidateDetails?.createdAt]);

  // const industry = useMemo(() => {
  //   if (candidateDetails?.industry && candidateDetails?.industry[0]) {
  //     return defaultIndustry(candidateDetails?.industry[0]);
  //   }
  //   return null;
  // }, [candidateDetails?.industry]);

  const copyText = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Copied");
      })
      .catch((err) => {
        toast.error("Failed to copy!");
      });
  };

  const handleDownload = async () => {
    if (candidateDetails?.resume?.url) {
      axios
        .get(
          `${constants.baseUrl}/${candidateDetails?.resume?.url}?t=${token}`,
          {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = candidateDetails?.resume?.name;
          a.click();
        })
        .catch((err) => {
          toast.error("Download failed. Please try again");
        });
    } else {
      toast.error("Resume not found");
    }
  };

  return (
    <tr className="odd:bg-exwhite-100 even:bg-exgray-100/30">
      <td className="px-3 py-4">
        <Link
          className="w-full flex items-center justify-start gap-2 cursor-pointer"
          to={`/company-admin/job-seekers/${candidateDetails?.id}`}
        >
          <div className="w-fit">
            {candidateDetails?.profilePicture ? (
              <Link
                to={`/job-seekers/${candidateDetails?.id}`}
                className="h-8 w-8  rounded-full flex items-center justify-center overflow-hidden cursor-pointer"
              >
                <img
                  src={`${constants.baseUrl}/${candidateDetails?.profilePicture}?t=${token}`}
                  alt="profile"
                  className="w-full h-full object-cover"
                />
              </Link>
            ) : candidateDetails?.lastName && candidateDetails?.firstName ? (
              <div
                to={`/job-seekers/${candidateDetails?.id}`}
                className="h-8 w-8 rounded-full bg-primary-500 bg-center bg-cover bg-no-repeat cursor-pointer text-xs text-exwhite-100 font-semibold flex items-center justify-center"
              >
                <h1 className="tracking-wide">{`${candidateDetails?.firstName[0].toUpperCase()}${candidateDetails?.lastName[0].toUpperCase()}`}</h1>
              </div>
            ) : (
              <div
                to={`/job-seekers/${candidateDetails?.id}`}
                className="h-8 w-8 rounded-full bg-DefaultProfileImg bg-center bg-cover bg-no-repeat cursor-pointer"
              ></div>
            )}
          </div>

          <h1 className="flex-1 text-base text-exgray-800 font-semibold cursor-pointer text-nowrap">
            {`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
          </h1>
        </Link>
      </td>

      <td className="text-base text-start text-exgray-500 px-3 py-4 text-nowrap">
        <div className="flex items-center gap-2 justify-between">
          <h1 className="text-base text-start text-exgray-500 flex-1">
            {candidateDetails?.email}
          </h1>
          {candidateDetails?.email && (
            <div
              className="cursor-pointer"
              onClick={() => copyText(candidateDetails?.email)}
            >
              <CopyIcon width="16" height="20" />
            </div>
          )}
        </div>
      </td>
      <td className="px-3 py-4 text-nowrap">
        <div className="flex items-center gap-3 justify-between">
          <h1 className="text-base text-start text-exgray-500 flex-1">{`+${candidateDetails?.countryCode} ${candidateDetails?.mobile}`}</h1>
          <div
            className="cursor-pointer"
            onClick={() => copyText(candidateDetails?.mobile)}
          >
            <CopyIcon width="16" height="20" />
          </div>
        </div>
      </td>
      <td className="text-base text-start text-exgray-500 px-3 py-4 text-nowrap">
        {defaultLoc?.Name}
      </td>
      <td className="text-base text-start text-exgray-500 px-3 py-4 text-nowrap">
        <Tooltip text={candidateDetails?.designation}>
          <div className="max-w-36 truncate cursor-default">
            {candidateDetails?.designation}
          </div>
        </Tooltip>
      </td>
      <td className="text-base text-center text-exgray-500 px-3 py-4 text-nowrap">
        {candidateDetails?.totalExperience
          ? `${candidateDetails?.totalExperience}`
          : ""}
      </td>
      <td className="text-base text-start text-exgray-500 px-3 py-4 text-nowrap">
        <Tooltip text={candidateDetails?.organization}>
          <div className="max-w-36 truncate cursor-default">
            {candidateDetails?.organization}
          </div>
        </Tooltip>
      </td>
      <td className="text-base text-start text-exgray-500 px-3 py-4 text-nowrap">
        <div className="flex-1">
          {candidateDetails?.recruiterId ? (
            <div className="flex items-center justify-start gap-1">
              {!candidateDetails?.recruiterDetails?.profilePicture ? (
                <div className="h-6 w-6 rounded-sm bg-DefaultProfileImg bg-cover bg-center"></div>
              ) : (
                <div className="h-6 w-6 rounded-sm flex items-center justify-center overflow-hidden">
                  <img
                    src={`${constants.baseUrl}/${candidateDetails?.recruiterDetails?.profilePicture}?t=${token}`}
                    alt="profile"
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
              <h1 className="text-base text-exgray-300 font-semibold">{`${candidateDetails?.recruiterDetails?.firstName} ${candidateDetails?.recruiterDetails?.lastName}`}</h1>
            </div>
          ) : (
            <div className="flex items-center justify-start gap-1">
              <div className="h-6 w-6 rounded-sm bg-DefaultProfileImg bg-cover bg-center"></div>
              <h1 className="text-base text-exgray-300 font-semibold">
                Super Admin
              </h1>
            </div>
          )}
        </div>
      </td>
      <td className="text-base text-center text-exgray-500 px-3 py-4 text-nowrap">
        {date}
      </td>
      <td className="text-base text-exgray-500 px-3 py-4 text-nowrap flex items-center justify-center">
        <button className="p-1 active:scale-90" onClick={handleDownload}>
          <DownloadIcon />
        </button>
      </td>
    </tr>

    // <div
    //   className="flex items-center justify-start p-4 bg-transparent gap-7 odd:bg-exwhite-100 even:bg-exgray-100/30"
    //   key={uuidV4()}
    // >
    //   <div className="flex-1 space-y-1">
    //     <Link
    //       to={`/job-seekers/${candidateDetails?.id}`}
    //       className="text-lg text-exgray-800 font-semibold leading-none line-clamp-1 cursor-pointer"
    //     >
    //       {`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
    //     </Link>
    //     <p className="text-base text-exgray-400 line-clamp-1">
    //       {candidateDetails?.designation}
    //     </p>
    //     <div className="flex items-center justify-start gap-2">
    //       <p className="text-expurple-700 text-base font-semibold line-clamp-1">
    //         {candidateDetails?.organization
    //           ? candidateDetails?.organization
    //           : "N/A"}
    //       </p>
    //       {candidateDetails?.locations && (
    //         <div className="flex items-center justify-start gap-1">
    //           <div className="w-fit">
    //             <LocationIcon width="20" height="20" color="#5071C4" />
    //           </div>
    //           <p className="text-expurple-800 text-base font-semibold line-clamp-1 flex-1">
    //             {candidateDetails?.locations}
    //           </p>
    //         </div>
    //       )}
    //     </div>

    //     {industry && (
    //       <p className="text-base text-exgray-500 font-semibold line-clamp-1">
    //         {industry?.Name}
    //       </p>
    //     )}
    //   </div>

    //   <div className="flex-1">
    //     <p className="text-exgray-800 text-base font-semibold line-clamp-1">
    //       {candidateDetails?.totalExperience
    //         ? `${candidateDetails?.totalExperience} Yrs Exp`
    //         : "N/A"}
    //     </p>
    //   </div>

    //   <div className="flex-1">
    //     {candidateDetails?.recruiterId ? (
    //       <div className="flex items-center justify-start gap-1">
    //         {!candidateDetails?.recruiterDetails?.profilePicture ? (
    //           <div className="h-6 w-6 rounded-sm bg-DefaultProfileImg bg-cover bg-center"></div>
    //         ) : (
    //           <div className="h-6 w-6 rounded-sm flex items-center justify-center overflow-hidden">
    //             <img
    //               src={`${constants.baseUrl}/${candidateDetails?.recruiterDetails?.profilePicture}?t=${token}`}
    //               alt="profile"
    //               className="w-full h-full object-cover"
    //             />
    //           </div>
    //         )}
    //         <h1 className="text-base text-exgray-300 font-semibold">{`${candidateDetails?.recruiterDetails?.firstName} ${candidateDetails?.recruiterDetails?.lastName}`}</h1>
    //       </div>
    //     ) : (
    //       <div className="flex items-center justify-start gap-1">
    //         <div className="h-6 w-6 rounded-sm bg-DefaultProfileImg bg-cover bg-center"></div>
    //         <h1 className="text-base text-exgray-300 font-semibold">
    //           Super Admin
    //         </h1>
    //       </div>
    //     )}
    //   </div>

    //   <div className="flex-1 flex items-center justify-end gap-5">
    //     <div className="w-fit">
    //       <button
    //         className="text-base text-[#0BA02C] bg-green-100 pl-5 pr-3 py-2 text-center rounded-md flex items-center justify-center gap-2 active:scale-90"
    //         onClick={handleDownload}
    //       >
    //         Resume
    //         <div className="rotate-90">
    //           <RightArrowIcon color="#0BA02C" />
    //         </div>
    //       </button>
    //     </div>

    //     <div className="cursor-not-allowed">
    //       <VerticleThreeDotsIcon />
    //     </div>
    //   </div>
    // </div>
  );
};

export default BundleUploadUserCard;
