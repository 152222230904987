import React from "react";
import { useAppSelector } from "../../config/hooks";
import { baseUrl } from "../../constant";
import { formatDate } from "../../utils/date.util";
// import AlternateProfile from '../../components/AlternateProfile'

const MessageInboxItem = ({ lastMessage, time, memberDetails }) => {
  const { token } = useAppSelector((state) => state.login.session);

  return (
    <div className="flex gap-4 items-center py-2 hover:bg-exgray-100/30  px-5 border-b border-exgray-100 cursor-pointer">
      <div>
        {memberDetails[0]?.picUrl?.length > 0 ? (
          <img
            src={`${baseUrl}/files/${memberDetails[0]?.picUrl}?t=${token}`}
            alt="Profile Image"
            className="min-w-10 max-w-10  h-10 object-cover rounded-full"
          />
        ) : (
          //   <AlternateProfile firstName={memberDetails[0]?.firstName} lastName={memberDetails[0]?.lastName} width='10' height='10'/>
          <div className="h-12 w-12 rounded-full bg-primary-400 flex items-center justify-center text-2xl text-exwhite-100 font-semibold">{`${memberDetails[0]?.firstName[0].toUpperCase()}${memberDetails[0]?.lastName[0].toUpperCase()}`}</div>
        )}
      </div>
      <div className="w-3/4">
        <div className="flex justify-between">
          <h1 className="text-primary-500 font-semibold text-md line-clamp-1 w-2/3">
            {memberDetails[0]?.firstName} {memberDetails[0]?.lastName}
          </h1>
          <h6 className="text-secondary-400 text-xs font-semibold">
            {formatDate(time)}
          </h6>
        </div>
        <div className="flex justify-between">
          <p className="text-exgray-300 text-sm line-clamp-1 w-2/3">
            {lastMessage}
          </p>
          {/* <p className='bg-red-500 rounded-full w-fit px-2 py-1 text-[10px] text-white'>2</p> */}
        </div>
      </div>
    </div>
  );
};

export default MessageInboxItem;
