import { useAppSelector } from "../../config/hooks";

const useSalesDropdown = () => {
  const { salesIndustry, salesSubIndustry, salesInteractionType } =
    useAppSelector((state) => state.appConfig);

  const defaultSalesIndustry = (id) => {
    return salesIndustry.find((ele) => ele.Id == id)?.Title;
  };

  const defaultSalesSubIndustry = (id) => {
    return salesSubIndustry.find((ele) => ele.Id == id)?.Title;
  };

  const defaultSalesInteractionType = (id) => {
    return salesInteractionType.find((ele) => ele.Id == id)?.Title;
  };

  return {
    defaultSalesIndustry,
    defaultSalesSubIndustry,
    defaultSalesInteractionType,
  };
};

export default useSalesDropdown;
