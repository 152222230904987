import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base.model";
import apiClient from "../config/axiosConfig";
import { Client, FilterClientData, PaginatedClientResponse } from "../models/sales.model";
import { CompanyProfile } from "../models/company.model";

class SalesService {
    createClient(
       client: Client
      ): AxiosPromise<ApiResponse<Client>> {
        return apiClient.post(`/v1/exo/clients`, client);
    }

    updateClient(
        client: Client
       ): AxiosPromise<ApiResponse<Client>> {
         return apiClient.put(`/v1/exo/client/${client.id}`, client);
     }

    getAllClients(): AxiosPromise<ApiResponse<PaginatedClientResponse>> {
         return apiClient.get(`/v1/exo/clients?size=50`);
     }

    getfilteredClients(
        filterData: FilterClientData
      ):
        AxiosPromise<ApiResponse<PaginatedClientResponse>> {
        return apiClient.get(`/v1/exo/clients?`,{
            params:filterData
        });  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
    }

    getClientById(clientId:string):AxiosPromise<ApiResponse<Client>>{
        return apiClient.get(`/v1/exo/client/${clientId}`);
    }

    checkDomain(domain:string):AxiosPromise<ApiResponse<CompanyProfile>>{
        return apiClient.get(`/v1/exo/companies/domain?domain=${domain}`)
    }

    getClientByUrl(nextUrl) {
        return apiClient.get(nextUrl);
    }

    getClientsData(data:any):
        AxiosPromise<ApiResponse<any>> {
            const formData = new FormData();
  
            // Append the text data to the FormData object with the key "files"
            formData.append('files', data.text);  // 'data.text' if 'text' is directly available in 'data'
            
            return apiClient.post(`/v1/client_upload`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",  // Axios will automatically handle the boundary
              },
            });
    }
}

const salesService=new SalesService();
export default salesService;