import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import aiService from "../services/ai.service";

const initialState = {
  globalSearch: {
    mappedCandidates: [],
    isLoading: false,
    error: false,
  },
};

export const getCvDetailsApi = createAsyncThunk(
  "user/getCvDetailsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.getCvDetails(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJdDetailsApi = createAsyncThunk(
  "user/getJdDetailsApi",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.getJdDetails(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMappedCandidatesAPi = createAsyncThunk(
  "user/getMappedCandidatesApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await aiService.globalSearchCandidate(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMappedCandidatesAPi.pending, (state) => {
      state.globalSearch.isLoading = true;
    });

    builder.addCase(getMappedCandidatesAPi.fulfilled, (state, action) => {
      state.globalSearch.isLoading = false;
      state.globalSearch.error = false;
      const response = action?.payload?.data;
      state.globalSearch.mappedCandidates = [
        ...state.globalSearch.mappedCandidates,
        ...response,
      ];
    });

    builder.addCase(getMappedCandidatesAPi.rejected, (state) => {
      state.globalSearch.isLoading = false;
      state.globalSearch.error = true;
    });
  },
});

export default aiSlice.reducer;
