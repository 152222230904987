import React, { useEffect, useState } from "react";
import Interaction from "./Interaction";
import Contact from "./Contact";
import LeadCard from "./LeadCard";
import {
  getAllContactsApi,
  getAllInteractionsApi,
  getClientByIdApi,
} from "../../slices/salesSlice";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import Popup from "../../components/popup/Popup";
import InteractionForm from "./InteractionForm";
import PlusCircleIcon from "../../assets/Icons/PlusCircleIcon";
import ContactDetailForm from "./ContactDetailForm";

// type Interaction = {
//   date: string;
//   time: string;
//   description: string;
//   participants: string[];
//   document?: string;
//   followUpRequired?: boolean;
//   followUpDate?: string;
// };

const ClientDetail = () => {
  const dispatch = useAppDispatch();
  const clientId = useParams();
  const interactions = useAppSelector((state) => state.sales?.interactions);
  const { contacts, clientDetail } = useAppSelector((state) => state.sales);
  const [togglePopup, setTogglePopup] = useState(false);
  const [togglePopupContact, setTogglePopupContact] = useState(false);
  
  console.log(interactions, ":INTERACTIONS FROM");
  useEffect(() => {
    if (clientId.clientId) {
      dispatch(getAllInteractionsApi(clientId.clientId));
      dispatch(getAllContactsApi(clientId.clientId));
      // dispatch(getClientByIdApi(clientId.clientId));
    }
  }, [clientId.clientId]);

  const togglePopupHandler = () => {
    setTogglePopup(!togglePopup);
  };
  const togglePopupContactHandler = () => {
    setTogglePopupContact(!togglePopupContact);
  };

  return (
    <div className=" flex flex-col gap-2 bg-gray-100 rounded-md shadow-md ">
      <div className=" flex gap-2  bg-exwhite-100 p-2">
        <LeadCard />

        <div className="flex flex-col gap-4 overflow-hidden">
          <div className="flex  items-center gap-4 px-2 ">
            <div className="text-gray-800 font-semibold text-xl">Contact Persons:</div>
            <div onClick={togglePopupContactHandler} className="hover:scale-110">
              <PlusCircleIcon />
            </div>
          </div>

          <div className="flex  gap-2 overflow-x-auto ">
                {contacts.length > 0 ? (
                contacts?.map((ele, i) => {
                  return <Contact key={ele.id} data={ele} />;
                })
              ) : (
                <h1 className="text-person text-sm text-exgray-400 text-center">
                  Please add a Contact Person...
                </h1>
              )}
          </div>
         
       
        </div>
      </div>

      

      {/* Interaction Timeline */}
      <div className="">
        <div className="flex justify-between  ">
          <h3 className="text-lg text-exgray-200  font-semibold">
            Interaction Timeline
          </h3>     
          <button
            onClick={togglePopupHandler}
            className="px-6 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-700"
          >
            + Add Interaction
          </button>
          
        </div>

       
        {interactions?.length > 0 ? (
          <div className="">
            {interactions?.map((ele) => {
              return <Interaction key={ele.id} data={ele} />;
            })}
          </div>
        ) : (
          <div className="text-exgray-500 text-center mt-8">
            <h3>No Interaction yet ....</h3>
          </div>
        )}
      </div>

      <Popup
        visible={togglePopup}
        onClose={togglePopupHandler}
        children={<InteractionForm togglePopupHandler={togglePopupHandler} />}
      />

      <Popup
        visible={togglePopupContact}
        onClose={togglePopupContactHandler}
        children={
          <ContactDetailForm togglePopupHandler={togglePopupContactHandler} />
        }
      />
    </div>
  );
};

export default ClientDetail;
