import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../config/hooks";

import { getBulkCandidateDetailApi } from "../../../../slices/candidateProfileSlice";

import * as constants from "../../../../constant";

import { useLocation, useParams } from "react-router-dom";
import LoadingIcon from "../../../../assets/Icons/LoadingIcon";
import { unwrapResult } from "@reduxjs/toolkit";
import UploadedPersonalDetails from "./components/UploadedPersonalDetails";
import UploadedExperienceSection from "./components/UploadedExperienceSection";
import UploadedEducationSection from "./components/UploadedEducationSection";
import UploadedUserSkillsSection from "./components/UploadedUserSkillsSection";
import UploadedUserPreferenceSection from "./components/UploadedUserPreferenceSection";
import UploadedUserAdditionalSection from "./components/UploadedUserAdditionalSection";

const JobSeerkerView = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const receivedData = location.state;

  const { userId } = useParams();

  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getBulkCandidateDetailApi({ userId })
        ).then(unwrapResult);
        setUserDetails(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <h1>Somthing went wrong</h1>;
  }

  return loading ? (
    <div className="py-20 flex items-center justify-center">
      <LoadingIcon />
    </div>
  ) : (
    <div className="">
      <div className="flex items-center justify-center">
        {/* <div className="w-[300px] space-y-4 self-start">
          {receivedData?.comments &&
            receivedData?.comments?.length > 0 &&
            receivedData?.comments[0].comment && (
              <div className="space-y-2">
                <h1 className="text-lg font-normal text-expurple-800">
                  Profile Comment
                </h1>
                <div className="bg-expurple-100 p-2 text-expurple-700 whitespace-nowrap">
                  {receivedData?.comments[0].comment}
                </div>
              </div>
            )}

          {receivedData?.reasonForjobChange && (
            <div className="space-y-2">
              <h1 className="text-lg font-normal text-expurple-800">
                Reason For Job Change
              </h1>
              <div className="bg-expurple-100 p-2 text-expurple-700 whitespace-nowrap">
                {receivedData?.reasonForjobChange}
              </div>
            </div>
          )}
        </div> */}

        <div className="flex-1 pl-4 space-y-2 pb-4">
          <div className="border-b-2 border-b-exgray-200 pb-4">
            <UploadedPersonalDetails userDetails={userDetails} />
          </div>

          <div className="border-b-2 border-b-exgray-200 pb-4">
            <UploadedUserAdditionalSection userDetails={userDetails} />
          </div>

          <div className="border-b-2 border-b-exgray-200 pb-2">
            <UploadedExperienceSection userDetails={userDetails} />
          </div>
          <div className="border-b-2 border-b-exgray-200 pb-2">
            <UploadedEducationSection userDetails={userDetails} />
          </div>
          <div className="border-b-2 border-b-exgray-200 pb-2">
            <UploadedUserSkillsSection
              userSkills={userDetails.userDetails.skills}
            />
          </div>
          <div className="pb-2">
            <UploadedUserPreferenceSection userDetails={userDetails} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSeerkerView;
