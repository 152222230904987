import React, { useState, useRef, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import {
  getOtpApi,
  getOtpDomainVerification,
  getRecruiterOtpApi,
  toggleOtpHandler,
  verifyCompanyDomain,
  verifyOtpApi,
  verifyRecruiterOTPApi,
} from "../../slices/loginSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyCompanyOtpApi } from "../../slices/loginSlice";
import * as constants from "../../constant";
import { getCandidateDetailApi } from "../../slices/candidateProfileSlice";
import { jobOpenedbeforeLoginHandler } from "../../slices/jobSlice";
import { toast } from "sonner";
import { setComapnyDomain } from "../../slices/companySlice";
import { getMemberByIdApi } from "../../slices/companyMemberSlice";
import OTPInput from "react-otp-input";

// let currentOTPIndex = 0;
const OtpField = ({ userType, onClose = () => {} }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  // const inputRef = useRef(null);
  const btnRef = useRef(null);

  const otpDetails = useAppSelector((state) => state.login.ui);
  const jobSelector = useAppSelector(
    (state) => state.jobs.openedJobBeforelogin
  );

  const { requestId } = otpDetails || {};

  // const [otp, setOtp] = useState(new Array(6).fill(""));
  // const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (otp && otp.length === 6 && btnRef.current) {
      btnRef.current.click();
    }
  }, [otp]);

  // useEffect(() => {
  //   inputRef.current?.focus();
  // }, [activeOTPIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const onChangeOTP = (text) => {
    setOtp(text);
  };

  const resendOtpTimerHandler = () => {
    setMinutes(1);
    setSeconds(30);

    if (otpDetails.isEmail) {
      if (userType === "user") {
        getOtp({ emailId: otpDetails.email });
      } else if (userType === "company") {
        const domain = otpDetails.email.split("@");
        getOtp({
          domain: domain.length > 1 ? domain[1] : "",
          emailId: otpDetails.email,
        });
      } else if (userType === "recruiter") {
        getOtp({ emailId: otpDetails.email });
      }
    } else {
      if (userType === "user" || userType === "recruiter")
        getOtp({
          countryCode: parseInt(otpDetails.countryCode),
          phoneNo: parseInt(otpDetails.phone),
        });
    }
  };

  const getOtp = async (req) => {
    if (userType === "user") {
      try {
        await dispatch(getOtpApi(req)).then(unwrapResult);
      } catch (error) {}
    } else if (userType === "company") {
      try {
        await dispatch(verifyCompanyDomain(req)).then(unwrapResult);
      } catch (error) {
        try {
          const response = await dispatch(getOtpDomainVerification(req)).then(
            unwrapResult
          );
          if (response) {
            dispatch(
              setComapnyDomain({
                companyDomain: req.domainName,
                companyEmail: req.emailId,
              })
            );
          }
        } catch (error) {}
      }
    } else if (userType === "recruiter") {
      try {
        await dispatch(getRecruiterOtpApi(req)).then(unwrapResult);
      } catch (error) {}
    }
  };

  // const handleOnChange = (e) => {
  //   const { value } = e.target;
  //   const newOTP = [...otp];
  //   newOTP[currentOTPIndex] = value.substring(value.length - 1);

  //   if (!value) setActiveOTPIndex(currentOTPIndex - 1);
  //   else setActiveOTPIndex(currentOTPIndex + 1);

  //   setOtp(newOTP);
  // };

  // const handleOnKeyDown = (e, index) => {
  //   currentOTPIndex = index;
  //   if (e.key === "Backspace") setActiveOTPIndex(currentOTPIndex - 1);
  // };

  const fetchData = async (userId) => {
    try {
      await dispatch(getMemberByIdApi({ memberId: userId })).then(unwrapResult);
      //navigate("/company-admin");
      navigate("/employee-dashboard");
    } catch (error) {
      toast.error("Unable to fetch employee details");
    }
  };

  const loginHandler = async () => {
    // const joinedString = otp.join("");
    const otpNumber = Number(otp);
    if (otp && otpNumber && otp.length === 6) {
      if (userType === constants.USER.toLowerCase()) {
        try {
          const response = await dispatch(
            verifyOtpApi({
              otp: otpNumber,
              requestId: requestId,
            })
          ).then(unwrapResult);
          if (pathname === "/candidate/register") {
            dispatch(toggleOtpHandler());
          }
          if (response.data.data.accountExists) {
            if (
              response.data.data.session.who === constants.USER.toLowerCase()
            ) {
              if (
                jobSelector.jobId &&
                response.data.data.session.who === constants.USER.toLowerCase()
              ) {
                try {
                  await dispatch(
                    getCandidateDetailApi({
                      userId: response.data.data.session.userId,
                    })
                  ).then(unwrapResult);
                  navigate("/job-search");
                } catch (error) {
                  dispatch(jobOpenedbeforeLoginHandler({ jobId: "" }));
                  navigate("/job-search");
                }
              } else {
                try {
                  await dispatch(
                    getCandidateDetailApi({
                      userId: response.data.data.session.userId,
                    })
                  ).then(unwrapResult);
                  navigate("/applied-jobs");
                } catch (error) {
                  toast.error("Failed to fetch candidate details.");
                }
              }
            }
            if (
              response.data.data.session.who === constants.COMPANY.toLowerCase()
            ) {
              navigate("/company");
            }
            if (
              response.data.data.session.who ===
              constants.COMPANY_MEMBER.toLowerCase()
            ) {
              navigate("/company");
            }
            if (
              response.data.data.session.who ===
              constants.RECRUITER.toLowerCase()
            ) {
              navigate("/recruiter");
            }
          } else navigate("/candidate/register");
        } catch (error) {
          toast.error("Invalid OTP");
        }
      } else if (userType === constants.COMPANY.toLowerCase()) {
        try {
          const response = await dispatch(
            verifyCompanyOtpApi({ requestId: requestId, otp: otpNumber })
          ).then(unwrapResult);
          if (response.data.data.accountExists) {
            if (
              response.data.data.session.who === constants.USER.toLowerCase()
            ) {
              navigate("/candidate");
              toast.error("!Error, Please login as candidate");
            }
            if (
              response.data.data.session.who === constants.COMPANY.toLowerCase()
            ) {
              navigate("/company-admin");
            }
            if (
              response.data.data.session.who ===
              constants.COMPANY_MEMBER.toLowerCase()
            ) {
              fetchData(response.data.data.session.userId);
              // navigate("/company-admin"); // phase 2 change   moving nevigation to fetch data after await
            }
            if (response.data.data.session.who === "recruiter") {
              navigate("/recruiter");
            }
          } else {
            if (pathname === "/company") navigate("/company/register");
            else navigate("/company-profile");
          }
        } catch (error) {
          toast.error("Invalid OTP");
        }
        onClose();
      } else if (userType === constants.RECRUITER.toLowerCase()) {
        try {
          const response = await dispatch(
            verifyRecruiterOTPApi({ requestId: requestId, otp: otpNumber })
          ).then(unwrapResult);
          if (response.data.data.accountExists) {
            if (
              response.data.data.session.who === constants.USER.toLowerCase()
            ) {
              navigate("/candidate");
            }
            if (
              response.data.data.session.who === constants.COMPANY.toLowerCase()
            ) {
              navigate("/company");
            }
            if (
              response.data.data.session.who ===
              constants.COMPANY_MEMBER.toLowerCase()
            ) {
              navigate("/company");
            }
            if (response.data.data.session.who === "recruiter") {
              navigate("/recruiter-dashboard");
            }
          } else {
            navigate("/recruiter/register");
          }
        } catch (error) {
          toast.error("Invalid OTP");
        }
        onClose();
      }
    } else {
      toast.error("Invalid OTP");
    }
  };

  return (
    <div className="w-full space-y-4">
      <h1 className="text-xl text-expurple-800 font-semibold tracking-wider text-center">
        Enter OTP
      </h1>
      <div className={"flex justify-center items-center space-x-5"}>
        <OTPInput
          containerStyle={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
            backgroundColor: "transparent",
          }}
          inputStyle={{
            width: "50px",
            height: "50px",
            //   borderRadius: "4px",
            //color: customColor.lxwhite.
          }}
          onChange={(value) => onChangeOTP(value)}
          numInputs={6}
          renderSeparator={<span></span>}
          renderInput={(props, index) => {
            return (
              <input
                {...props}
                name={`otp-input-${index}`}
                autoFocus={index === 0}
                className="w-12 h-12 border-2 rounded bg-expurple-100 outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-expurple-700 transition"
              />
            );
          }}
          value={otp}
        />

        {/* {otp.map((_, index) => {
          return (
            <React.Fragment key={index}>
              <input
                ref={activeOTPIndex === index ? inputRef : null}
                type="number"
                className={
                  "w-12 h-12 border-2 rounded bg-expurple-100 outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-expurple-700 transition"
                }
                onWheel={(e) => e.target.blur()}
                onChange={handleOnChange}
                onKeyDown={(e) => handleOnKeyDown(e, index)}
                value={otp[index]}
              />
            </React.Fragment>
          );
        })} */}
      </div>

      <h1 className="text-center text-2xl text-expurple-700 font-semibold">
        {minutes < 10 ? `0${minutes}` : minutes} :{" "}
        {seconds < 10 ? `0${seconds}` : seconds}
      </h1>

      <h1 className="text-2xl text-expurple-700 font-medium text-center">
        Didn't recevie an OTP ?{" "}
        <button
          disabled={minutes > 0 || seconds > 0}
          className={`text-expurple-800 font-bold text-lg underline cursor-pointer ${
            minutes > 0 || seconds > 0 ? "opacity-45" : "opacity-100"
          }`}
          onClick={resendOtpTimerHandler}
        >
          Resend
        </button>
      </h1>

      <div className="">
        <div className="w-fit mx-auto">
          <button
            type="button"
            className={`px-8 py-2 rounded-md text-center ${
              otp.length === 6
                ? "bg-expurple-800 text-golden"
                : "bg-exgray-200 text-exwhite-100"
            } font-semibold`}
            onClick={loginHandler}
            disabled={!otp.length === 6}
            ref={btnRef}
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default OtpField;
