import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Accordian from "../../../components/Accordian";
import Popup from "../../../components/popup/Popup";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getMemberByIdApi } from "../../../slices/companyMemberSlice";
import DocumentManagement from "../components/DocumentManagement";
import EmployeeDetail from "../components/EmployeeDetail";
import EmploymentDetails from "../components/EmploymentDetails";
import HealthBenefits from "../components/HealthBenefits";
import UpdateEmployeeDetail from "../components/UpdateEmployeeDetail";
import UpdateProfessionalDetail from "../components/UpdateProfessionalDetail";
import * as constants from "../../../constant";
import AdditionalDetails from "../components/AdditionalDetails";
import UpdateAdditionalDetails from "../components/UpdateAdditionalDetail";

const EmployeeProfile = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { memberId } = useParams();
  const dispatch = useAppDispatch();
  const [memberDetail, setMemberDetail] = useState(undefined);
  const [togglePopup, setTogglePopup] = useState(false);
  const [isProfessional, setIsProfessional] = useState(false);
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const loginUserDetails = useAppSelector((state) => state.company.memberDetails);
  const [additionalDetails, setAdditionalDetails] = useState(false);

  useEffect(() => {
    getMember();
  }, [togglePopup, isProfessional])

  const getMember = () => {
    dispatch(getMemberByIdApi({
      memberId: memberId
    })).then((result) => {
      setMemberDetail(result?.payload?.data?.data);
    })
  }

  return (
    <div className="bg-exwhite-100">
      <Accordian isEditable={true} title="Personal Information" onPressEdit={() => setTogglePopup(true)}>
        <EmployeeDetail memberDetail={memberDetail} />
      </Accordian>
      <Accordian
        title="Professional Details"
        isEditable={
          (loginSelector?.who === constants.COMPANY.toLowerCase())
          ||
          (loginUserDetails?.departments?.includes("HRMS") && memberId !== userId)
        }
        onPressEdit={() => setIsProfessional(true)}
      >
        <EmploymentDetails memberDetail={memberDetail} />
      </Accordian>
      {/* <Accordian isEditable={false} title="Educational Background">
      </Accordian>
      <Accordian isEditable={false} title="Medical Information">
        <HealthBenefits />
      </Accordian> */}
      <Accordian onPressEdit={() => setAdditionalDetails(true)} isEditable={true} title="Additional Details">
        <AdditionalDetails memberDetail={memberDetail?.emergencyDetails} />
      </Accordian>
      <Accordian isEditable={false} title="Document Details">
        <DocumentManagement memberId={memberDetail?.id} />
      </Accordian>
      <Popup onClose={() => setTogglePopup(false)} visible={togglePopup}>
        <UpdateEmployeeDetail onClose={() => setTogglePopup(false)} employeeDetail={memberDetail} />
      </Popup>
      <Popup onClose={() => setIsProfessional(false)} visible={isProfessional}>
        <UpdateProfessionalDetail onClose={() => setIsProfessional(false)} employeeDetail={memberDetail} />
      </Popup>
      <Popup onClose={() => setAdditionalDetails(false)} visible={additionalDetails}>
        <UpdateAdditionalDetails
          onClose={() => setAdditionalDetails(false)}
          employeeAdditionalDetail={memberDetail?.emergencyDetails}
          employeeDetail={memberDetail}

        />
      </Popup>
    </div>
  );


};

export default EmployeeProfile;
