import moment from "moment";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/Icons/EditIcon";
import BasicDataView from "../../../components/BasicDataView";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import * as constants from "../../../constant";
import { EmergencyDetails, member } from "../../../models/companyMember.model";
import { getMemberByIdApi, updateMembersApi } from "../../../slices/companyMemberSlice";
import uploadContent from "../../../utils/upload-content/uploadContent";

export interface AdditionalDetailProps {
  memberDetail: EmergencyDetails;
}

const AdditionalDetails = ({ memberDetail }: AdditionalDetailProps) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [profileUrl, setProfileUrl] = useState('');
  const hiddenFileInput = useRef(null);
  const token = useAppSelector((state) => state?.login?.session?.token);
  const userId = useAppSelector((state) => state?.login?.session?.userId);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      uploadContent.uploadProfilePicture(memberDetail.id, fileUploaded, handleFileUpload)
    }
  };

  const handleFileUpload = (response) => {
    setProfileUrl(response[0].url);
    dispatch(updateMembersApi({
      memberId: memberDetail.id,
      profileImage: response[0].url
    }));
    dispatch(getMemberByIdApi({
      memberId: memberDetail.id
    }))
  }

  return (
    <>
      <div className="p-3">
        <div>
          <div className="flex items-center">
            <div className="flex w-6/12">
              <BasicDataView
                title={"First Name"}
                value={memberDetail?.firstName}
              />
            </div>
            <div className="flex w-6/12">
              <BasicDataView
                title={"Last Name"}
                value={memberDetail?.lastName}
              />
            </div>
          </div>
          <div className="flex mt-3 items-center">
            <div className="flex w-6/12">
              <BasicDataView
                title={"Relationship"}
                value={memberDetail?.relationship}
              />

            </div>
            <div className="flex w-6/12">
              <BasicDataView
                title={"Phone"}
                value={memberDetail?.phone}
              />
            </div>
          </div>
          <div className="flex mt-3 items-center">
            <div className="flex w-6/12">
              <BasicDataView
                title={"Current Address"}
                value={memberDetail?.currentAddress?.street}
              />
            </div>
            <div className="flex w-6/12">
              <BasicDataView
                title={"Permanent Address"}
                value={memberDetail?.permanentAddress?.street}
              />
            </div>
          </div>
        </div>

      </div >
    </>
  );
};

export default AdditionalDetails;
