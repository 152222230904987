const FileIcon = ({ width = "31", height = "31" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="31" height="31" rx="5" fill="#E7F0FA" />
      <g clipPath="url(#clip0_1086_3989)">
        <path
          d="M11.0819 23H16.8139C18.0359 23 18.6144 22.3802 18.6144 21.1464V20.0543H19.7655C20.9816 20.0543 21.5896 19.4345 21.5896 18.2007V9.8536C21.5896 8.61985 20.9816 8 19.7655 8H14.063C12.847 8 12.233 8.61985 12.233 9.8536V10.9457H11.0819C9.86588 10.9457 9.25195 11.5655 9.25195 12.7993V21.1464C9.25195 22.3861 9.86588 23 11.0819 23ZM15.4739 10.0838C15.2142 10.0838 15.0961 9.91263 15.0961 9.73555V9.61156C15.0961 9.42856 15.2142 9.26327 15.4739 9.26327H18.3546C18.6085 9.26327 18.7325 9.42856 18.7325 9.61156V9.73555C18.7325 9.91263 18.6085 10.0838 18.3546 10.0838H15.4739ZM11.1291 22.0496C10.5211 22.0496 10.2024 21.719 10.2024 21.1287V12.8111C10.2024 12.2326 10.5211 11.8961 11.135 11.8961H13.1835V15.3199C13.1835 16.0638 13.5613 16.4298 14.2933 16.4298H17.664V21.1287C17.664 21.719 17.3452 22.0496 16.7313 22.0496H11.1291ZM14.4054 15.5384C14.1693 15.5384 14.0749 15.4439 14.0749 15.2137V12.0791L17.481 15.5384H14.4054Z"
          fill="#0A65CC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1086_3989">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FileIcon;
