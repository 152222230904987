import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "sonner";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import CrossIcon from "../../../assets/Icons/CrossIcon";
import CustomDatePicker from "../../../components/basic-components/CustomDatePicker";
import ExDropdown from "../../../components/basic-components/ExDropdown";
import ExInput from "../../../components/basic-components/ExInput";
import ExMultiDropdown from "../../../components/basic-components/ExMultiDropdown";
import { createMemberApi, editAdditionalDetailApi, updateAdditionalDetailApi, updateMembersApi } from "../../../slices/companyMemberSlice";
import * as constants from "../../../constant";
import { EmergencyDetails, member } from "../../../models/companyMember.model";
import useIndustries from "../../../utils/dropdown-options/useIndustries";
import useGender from "../../../utils/dropdown-options/useGender";
import useMaritalStatus from "../../../utils/dropdown-options/useMaritalStatus";
import useNationality from "../../../utils/dropdown-options/useNationality";
//import ExMultiDropdown from "../../../components/basic-components/ExMultiDropdown";

const marriageStatus = [
  {
    id: 1,
    Title: "Married"
  },
  {
    id: 2,
    Title: "UnMarried"
  }
];

const nationalityArray = [
  {
    id: 1,
    Title: "Indian"
  },
  {
    id: 2,
    Title: "NRI"
  }
];

export interface UpdateEmployeeDetalProps {
  employeeDetail: member;
  onClose: Function;
  employeeAdditionalDetail: EmergencyDetails;
}


const UpdateAdditionalDetails = ({
  onClose,
  employeeDetail,
  employeeAdditionalDetail
}: UpdateEmployeeDetalProps) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const companySelector = useAppSelector(
    (state) => state.company.companyDetails
  );
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const gender = useAppSelector(
    (state) => state?.appConfig?.genderList
  );

  // const { genderArray, defaultGender } = useGender();
  // const { maritalStatusArray, defaultMaritalStatus } = useMaritalStatus();
  // const { nationalityArray, defaultNationality } = useNationality();

  // console.log(defaultGender(employeeDetail?.gender), "this is for gender get ");

  const domain = useMemo(() => {
    if (
      loginSelector.who === constants.COMPANY_MEMBER.toLowerCase() &&
      memberSelector.emailId
    ) {
      const email = memberSelector.emailId.match(
        /@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/
      );
      if (email.length > 0) {
        return email[0] ? email[0] : "";
      }
      return "";
    } else if (
      loginSelector.who === constants.COMPANY.toLowerCase() &&
      companySelector?.domain
    ) {
      return `@${companySelector.domain}`;
    }
    return "";
  }, [companySelector?.domain, loginSelector?.who, memberSelector?.emailId]);

  const form = useForm({
    defaultValues: {
      firstName: employeeAdditionalDetail?.firstName,
      lastName: employeeAdditionalDetail?.lastName,
      relationship: employeeAdditionalDetail?.relationship,
      phone: employeeAdditionalDetail?.phone,
      currentAddress: employeeAdditionalDetail?.currentAddress.street,
      permanentAddress: employeeAdditionalDetail?.permanentAddress.street
    },
  });
  const { handleSubmit, control, formState, setValue } = form;
  const { errors } = formState;

  console.log(employeeDetail, "employeeDetailemployeeDetailemployeeDetail");

  const onSubmit = async (formData) => {
    if (employeeAdditionalDetail) {
      try {
        await dispatch(
          editAdditionalDetailApi({
            id: employeeAdditionalDetail?.id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phone: parseInt(formData.phone),
            relationship: formData.relationship,
            employeeID: employeeDetail.id,
            currentAddress: {
              street: formData.currentAddress,
              city: "",
              state: "",
              country: "",
              postalCode: ""
            },
            permanentAddress: {
              street: formData.permanentAddress,
              city: "",
              state: "",
              country: "",
              postalCode: ""
            }
          })
        ).then(unwrapResult);
        toast.success("Additional details updated successfully");
      } catch (error) {
        toast.error("Something went wrong,please try again");
      }
    }
    else {
      dispatch(
        updateAdditionalDetailApi({
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: parseInt(formData.phone),
          relationship: formData.relationship,
          employeeID: employeeDetail.id,
          currentAddress: {
            street: formData.currentAddress,
            city: "",
            state: "",
            country: "",
            postalCode: ""
          },
          permanentAddress: {
            street: formData.permanentAddress,
            city: "",
            state: "",
            country: "",
            postalCode: ""
          }
        })
      ).then(unwrapResult);
      toast.success("Additional details updated successfully");
    }
    onClose();
  };

  return (
    <div className="px-10 py-6 w-[70%] mx-auto bg-white shadow-md rounded-lg overflow-y-auto no-scrollbar">
      <div className="mb-5">
        <div className="flex flex-row items-center justify-between mb-2">
          <h1 className="text-xl text-primary-400 font-medium mb-1">
            Update Emergency Details
          </h1>
          <div onClick={() => onClose()}>
            <CrossIcon />
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "First name is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="First Name"
                placeholder="Type here..."
                name="firstName"
                onChange={onChange}
                value={value}
                error={errors?.firstName?.message}
                isMandatory={true}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Last name is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Last Name"
                placeholder="Type here..."
                name="lastName"
                onChange={onChange}
                value={value}
                error={errors?.lastName?.message}
                isMandatory={true}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="relationship"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Relationship is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Relation"
                placeholder="Type here..."
                name="relationship"
                onChange={onChange}
                value={value}
                error={errors?.relationship?.message}
                isMandatory={true}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Phone Number is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Phone Number"
                placeholder="Type here..."
                name="phone"
                onChange={onChange}
                value={value}
                error={errors?.phone?.message}
                isMandatory={true}
              />
            )}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Controller
            name="currentAddress"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Current Address is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Current Address"
                placeholder="Type here..."
                name="firstName"
                onChange={onChange}
                value={value}
                error={errors?.currentAddress?.message}
                isMandatory={true}
              />
            )}
          />
          <Controller
            name="permanentAddress"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Joining date is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <ExInput
                title="Permanent Address"
                placeholder="Type here..."
                name="firstName"
                onChange={onChange}
                value={value}
                error={errors?.permanentAddress?.message}
                isMandatory={true}
              />
            )}
          />
        </div>

        <div className="w-fit ml-auto mr-0 pt-3">
          <button
            type="submit"
            className="px-8 py-2 text-exwhite-100 text-base font-semibold text-center bg-expurple-800 rounded-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateAdditionalDetails;
