import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ExInput from "../../../../../components/basic-components/ExInput";
import ExDropdown from "../../../../../components/basic-components/ExDropdown";
import useCountryCodes from "../../../../../utils/dropdown-options/useCountryCodes";
import useLocations from "../../../../../utils/dropdown-options/useLocations";
import { useAppDispatch, useAppSelector } from "../../../../../config/hooks";
import { createReferApi } from "../../../../../slices/loginSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getAllReferralsApi,
  referCandidateApi,
  searchCandidatesApi,
} from "../../../../../slices/referSlice";
import * as constants from "../../../../../constant";
import ErrorIcon from "../../../../../assets/Icons/ErrorIcon";
import LoadingIcon from "../../../../../assets/Icons/LoadingIcon";
import { toast } from "sonner";
import LocationIcon from "../../../../../assets/Icons/LocationIcon";
import useIndustries from "../../../../../utils/dropdown-options/useIndustries";
import IndustryIcon from "../../../../../assets/Icons/IndustryIcon";
import { useLocation, useNavigate } from "react-router-dom";
import useSalaries from "../../../../../utils/dropdown-options/useSalaries";
import useJobTypes from "../../../../../utils/dropdown-options/useJobTypes";
import uploadContent from "../../../../../utils/upload-content/uploadContent";
import ExTextarea from "../../../../../components/basic-components/ExTextarea";
import { createAdditionalDetailsApi } from "../../../../../slices/candidateProfileSlice";
import ExRadioBtnGroup from "../../../../../components/basic-components/ExRadioBtnGroup";
import CrossIcon from "../../../../../assets/Icons/CrossIcon";
import { v4 as uuidv4 } from "uuid";
import { applyJobApi, getApplicantsApi } from "../../../../../slices/jobSlice";
import CustomDatePicker from "../../../../../components/basic-components/CustomDatePicker";
import ExCheckBox from "../../../../../components/basic-components/ExCheckBox";
import ExMultiDropdown from "../../../../../components/basic-components/ExMultiDropdown";
import UploadIcon from "../../../../../assets/Icons/UploadIcon";
import FileIcon from "../../../../../assets/Icons/FileIcon";
import moment from "moment";

const CreateReferForm = ({ jobId, onClose = () => {} }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { pathname } = useLocation();

  const userId = useAppSelector((state) => state?.login?.session?.userId);
  const userType = useAppSelector((state) => state?.login?.session?.who);
  const token = useAppSelector((state) => state?.login?.session?.token);
  const loginSelector = useAppSelector((state) => state?.login?.session);
  const memberSelector = useAppSelector(
    (state) => state?.member?.memberDetails
  );

  const [userDetails, setUserDetails] = useState(null);
  const [conflictError, setConflictError] = useState("");
  const [interestValue, setInterestValue] = useState("");
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [current, setcurrent] = useState(false);

  const { modifiedCountryCodes } = useCountryCodes();
  const { minSalaries, defualtSalary } = useSalaries();
  const { industriesArray, defaultIndustry } = useIndustries();
  const { locationsArray, defaultLocation } = useLocations();
  const { jobTypesArray, defaultJobType } = useJobTypes();

  const candidateLocation = useMemo(() => {
    return userDetails?.location
      ? isNaN(userDetails?.location)
        ? userDetails?.location
        : defaultLocation(userDetails?.location)
      : "";
  }, [defaultLocation, userDetails?.location]);

  const preferdIndustry = useMemo(() => {
    if (userDetails?.industry) {
      return userDetails?.industry[0]
        ? defaultIndustry(userDetails?.industry[0])
        : null;
    }
    return null;
  }, [defaultIndustry, userDetails?.industry]);

  const form = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      emailId: "",
      location: null,
      phoneNo: "",
      companyName: "",
      designantion: "",
      isCurrentlyworking: false,
      isFresher: false,
      edStartDate: "",
      edEndDate: "",
      exStartDate: "",
      exEndDate: "",
      experienceYears: null,
      countryCode: {
        Id: 99,
        CountryName: "India",
        Iso3: "IND",
        PhoneCode: "91",
        PhoneCodeLabel: "IND +91",
      },
      isMobileVerified: false,
      isEmailVerified: false,
      industry: null,
      prefferedIndustry: null,
      prevCompanyName: "",
      jobLocation: null,
      prefferedJobLocation: null,
      reportingTo: "",
      currentCtc: null,
      expectedCtc: null,
      qualification: "",
      university: "",
      noticePeriodDuration: "",
      workMode: null,
      jobChangeReasaon: "",
      comment: "",
      openForRelocation: "Yes",
      isServingNoticePeriod: "No",
      teamSize: "",
      skill: [],
      file: {
        name: "",
        url: "",
        contentType: "",
        size: 0,
      },
    },
  });

  const { handleSubmit, control, formState, watch, reset, setValue, setFocus } =
    form;
  const { errors } = formState;

  const allSkillsValues = watch("skill");
  const uploadedFile = watch("file");
  const isServing = watch("isServingNoticePeriod");
  // const currentCtcSal = watch("currentCtc");
  const currentWorkingStatus = watch("isCurrentlyworking");
  const isCandidateFresher = watch("isFresher");

  useEffect(() => {
    if (currentWorkingStatus) {
      setValue("endDate", "");
    }
  }, [currentWorkingStatus, setValue]);

  useEffect(() => {
    console.log("errors :>> ", errors);
    if (errors) {
      console.log("errors :>> ", errors);
      const firstErrorField = Object.keys(errors)[0];
      console.log("firstErrorField :>> ", firstErrorField); // Get the first error field name
      if (firstErrorField) setFocus(firstErrorField);
    }
  }, [errors, setFocus]);

  const onChangeInterestValue = (e) => {
    const fieldValue = e.target.value;
    setInterestValue(fieldValue);
  };

  const triggerInputfileHanlder = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const addInterestHandler = () => {
    setValue("skill", [...allSkillsValues, interestValue], {
      shouldDirty: true,
    });
    setInterestValue("");
  };

  const removeInterestHandler = (index) => {
    const newInterestArray = allSkillsValues?.filter((_, i) => i !== index);
    setValue("skill", newInterestArray, { shouldDirty: true });
  };

  const onSubmit = async (formData) => {
    if (
      userDetails &&
      (formData.isMobileVerified || formData.isEmailVerified)
    ) {
      try {
        const referredResponse = await dispatch(
          referCandidateApi({
            recruiterID: userId,
            candidateID: userDetails.id,
            jobID: jobId,
            status: "Sourced",
            referralType: userType,
            reasonForJobChange: formData.jobChangeReasaon,
            comments: [
              {
                commentType: "Common comment",
                comment: formData.comment,
                authorId: userId,
              },
            ],
            reportingTo: formData.reportingTo,
            previousCompany: formData.prevCompanyName,
            expectation: formData.expectedCtc.Id,
            preferredJobLocation: [formData.prefferedJobLocation.Id.toString()],
            workMode: formData?.workMode?.Title
              ? formData?.workMode?.Title
              : "",
            teamSize: parseInt(formData.teamSize),
            qualification: formData.qualification,
            skills: formData.skill,

            //phase 2 new fields
            previousCompanyDesignation: formData.designantion,
            currentWorkingStatus: formData.isCurrentlyworking,
            previousCompanyStartDate: formData.exStartDate,
            previousCompanyEndDate: formData.isCurrentlyworking
              ? new Date()
              : formData.exEndDate,
            university: formData.university,
            qualificationStartDate: formData.edStartDate,
            qualificationEndDate: formData.edEndDate,
            isOnNotice: formData.isServingNoticePeriod === "Yes" ? true : false,
            yearsOfExperience: formData.experienceYears,
          })
        ).then(unwrapResult);

        try {
          const applyJobData = {
            jobId: jobId,
            userId: userDetails.id,
            mobile: parseInt(formData.phoneNo),
            emailId: formData.emailId,
            location: [formData?.prefferedJobLocation?.Id?.toString()],
            lastSalary: formData?.currentCtc?.Id,
            preferredIndustry: [formData?.industry?.Id?.toString()],
            openForRelocation:
              formData.openForRelocation === "Yes" ? true : false,
            countryCode: parseInt(formData?.countryCode?.PhoneCode),
            noOfServingDays:
              formData.isServingNoticePeriod === "Yes"
                ? parseInt(formData?.noticePeriodDuration)
                : 0,
            status: "Sourced",
            subStatus: "APPLICATION_SEND",
            attachment: formData.file,
            recruiterId: userId,
            referralId: referredResponse.data.data.id,
            referralType: userType,
            applied: true,

            comments: [
              {
                commentType: "Common comment",
                comment: formData.comment,
                authorId: userId,
              },
            ],
            expectation: formData?.expectedCtc?.Id,
            preferredJobLocation: [formData.prefferedJobLocation.Id.toString()],
            previousCompany: formData.prevCompanyName,
            qualification: formData.qualification,
            reasonForJobChange: formData.jobChangeReasaon,
            reportingTo: formData.reportingTo,
            teamSize: parseInt(formData.teamSize),
            workMode: formData?.workMode?.Title
              ? formData?.workMode?.Title
              : "",
            skills: formData.skill,

            //phase 2 new fields
            previousCompanyDesignation: formData.designantion,
            currentWorkingStatus: formData.isCurrentlyworking,
            previousCompanyStartDate: formData.exStartDate
              ? formData.exStartDate
              : null,
            previousCompanyEndDate: formData.isCurrentlyworking
              ? new Date()
              : formData.exEndDate
              ? formData.exEndDate
              : null,
            university: formData.university,
            qualificationStartDate: formData.edStartDate
              ? formData.edStartDate
              : null,
            qualificationEndDate: formData.edEndDate
              ? formData.edEndDate
              : null,
            isOnNotice: formData.isServingNoticePeriod === "Yes" ? true : false,
            yearsOfExperience: parseInt(formData.experienceYears),
          };

          await dispatch(applyJobApi(applyJobData)).then(unwrapResult);
          if (
            loginSelector?.who === constants.COMPANY.toLowerCase() ||
            (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
              memberSelector?.designation === constants.ADMIN)
          ) {
            try {
              await dispatch(
                getApplicantsApi({
                  jobId,
                  recruiterId:
                    loginSelector?.who === constants.COMPANY.toLowerCase() ||
                    (loginSelector?.who ===
                      constants.COMPANY_MEMBER.toLowerCase() &&
                      memberSelector?.designation === "Admin")
                      ? ""
                      : loginSelector.userId,
                })
              ).then(unwrapResult);
            } catch (error) {}
          } else {
            try {
              await dispatch(
                getAllReferralsApi({
                  jobId: jobId,
                  // status: "applied",
                  recruiterId: loginSelector?.userId,
                })
              ).then(unwrapResult);
            } catch (error) {}
          }
          toast.success("Referred Successfully");
        } catch (error) {
          toast.error("Something went wrong");
        }
        // toast.success("Reffered successfully");
        onClose();
      } catch (error) {
        if (error.status === 409) {
          // setConflictError("Candidate alredy reffered");
          toast.error("Alredy reffered");
        } else {
          toast.error("Something went wrong");
        }
      }
    } else {
      try {
        const response = await dispatch(
          createReferApi({
            firstName: formData.firstName,
            lastName: formData.lastName,
            emailId: formData.emailId,
            countryCode: parseInt(formData.countryCode.PhoneCode),
            phoneNo: parseInt(formData.phoneNo),
            yearsOfExperience: formData.experienceYears,
            designation: formData.designantion,
            authorType:
              userType === constants.COMPANY.toLowerCase() ||
              userType === constants.COMPANY_MEMBER.toLowerCase()
                ? "COMPANY"
                : "RECRUITER",
            authorId:
              userType === constants.COMPANY.toLowerCase()
                ? userId
                : userType === constants.COMPANY_MEMBER.toLowerCase()
                ? memberSelector.companyId
                : userType === constants.RECRUITER.toLowerCase()
                ? userId
                : "",
            location: formData.location.Id.toString(),
            // socialInfo: {
            //   linkedin: {
            //     profileUrl: "",
            //   },
            // },
            organization: formData.prevCompanyName,
          })
        ).then(unwrapResult);
        const additionalDetailsData = {
          userId: response.data.data.userId,
          mobile: parseInt(formData.phoneNo),
          email: formData.emailId,
          preferredLocation: [formData.prefferedJobLocation.Id.toString()],
          lastSalary: formData.currentCtc.Id.toString(),
          preferredIndustry: [formData.industry.Id.toString()],
          relocation: formData.openForRelocation === "Yes" ? true : false,
          countryCode: parseInt(formData.countryCode.PhoneCode),
          resume: formData.file,
          isServingNoticePeriod:
            formData.isServingNoticePeriod === "Yes" ? true : false,
          noticePeriod:
            formData.isServingNoticePeriod === "Yes"
              ? formData.noticePeriodDuration.toString()
              : "0",
          reportingTo: formData.reportingTo,
          previousCompany: formData.prevCompanyName,
          expectation: formData.expectedCtc.Id.toString(),
          workMode: formData?.workMode?.Title ? formData?.workMode?.Title : "",
          qualification: formData.qualification,
          yearsOfExperience: formData.experienceYears,
        };
        // try {
        // await dispatch(
        //   createAdditionalDetailsApi(additionalDetailsData)
        // ).then(unwrapResult);
        try {
          const referredResponse = await dispatch(
            referCandidateApi({
              recruiterID: userId,
              candidateID: response.data.data.userId,
              jobID: jobId,
              status: "Sourced",
              referralType: userType,
              reasonForJobChange: formData.jobChangeReasaon,
              comments: [
                {
                  commentType: "Common comment",
                  comment: formData.comment,
                  authorId: userId,
                },
              ],
              teamSize: parseInt(formData.teamSize),
              reportingTo: formData.reportingTo,
              previousCompany: formData.prevCompanyName,
              expectation: formData.expectedCtc.Id,
              preferredJobLocation: [
                formData?.prefferedJobLocation?.Id.toString(),
              ],
              workMode: formData?.workMode?.Title
                ? formData?.workMode?.Title
                : "",
              qualification: formData.qualification,
              skills: formData.skill,

              //phase 2 new fields
              previousCompanyDesignation: formData.designantion,
              currentWorkingStatus: formData.isCurrentlyworking,
              previousCompanyStartDate: formData.exStartDate,
              previousCompanyEndDate: formData.isCurrentlyworking
                ? new Date()
                : formData.exEndDate,
              university: formData.university,
              qualificationStartDate: formData.edStartDate,
              qualificationEndDate: formData.edEndDate,
              isOnNotice:
                formData.isServingNoticePeriod === "Yes" ? true : false,
              yearsOfExperience: formData.experienceYears,
            })
          ).then(unwrapResult);
          try {
            const applyJobData = {
              jobId: jobId,
              userId: response.data.data.userId,
              mobile: parseInt(formData.phoneNo),
              emailId: formData.emailId,
              location: [formData.prefferedJobLocation.Id.toString()],
              lastSalary: formData.currentCtc.Id,
              preferredIndustry: [formData.industry.Id.toString()],
              openForRelocation:
                formData.openForRelocation === "Yes" ? true : false,
              countryCode: parseInt(formData.countryCode.PhoneCode),
              noOfServingDays:
                formData.isServingNoticePeriod === "Yes"
                  ? parseInt(formData.noticePeriodDuration)
                  : 0,
              status: "Sourced",
              subStatus: "APPLICATION_SEND",
              attachment: formData.file,
              recruiterId: userId,
              // referralId: "7244873905025179648",
              referralId: referredResponse.data.data.id,
              referralType: userType,
              applied: true,

              comments: [
                {
                  commentType: "Common comment",
                  comment: formData.comment,
                  authorId: userId,
                },
              ],
              expectation: formData.expectedCtc.Id,
              preferredJobLocation: [
                formData.prefferedJobLocation.Id.toString(),
              ],
              previousCompany: formData.prevCompanyName,
              qualification: formData.qualification,
              reasonForJobChange: formData.jobChangeReasaon,
              reportingTo: formData.reportingTo,
              teamSize: parseInt(formData.teamSize),
              workMode: formData?.workMode?.Title
                ? formData?.workMode?.Title
                : "",
              skills: formData.skill,

              //phase 2 new fields
              previousCompanyDesignation: formData.designantion,
              currentWorkingStatus: formData.isCurrentlyworking,
              previousCompanyStartDate: formData.exStartDate
                ? formData.exStartDate
                : null,
              previousCompanyEndDate: formData.isCurrentlyworking
                ? new Date()
                : formData.exEndDate
                ? formData.exEndDate
                : null,
              university: formData.university,
              qualificationStartDate: formData.edStartDate
                ? formData.edStartDate
                : null,
              qualificationEndDate: formData.edEndDate
                ? formData.edEndDate
                : null,
              isOnNotice:
                formData.isServingNoticePeriod === "Yes" ? true : false,
              yearsOfExperience: parseInt(formData.experienceYears),
            };
            await dispatch(applyJobApi(applyJobData)).then(unwrapResult);
            if (
              loginSelector?.who === constants.COMPANY.toLowerCase() ||
              (loginSelector?.who === constants.COMPANY_MEMBER.toLowerCase() &&
                memberSelector?.designation === constants.ADMIN)
            ) {
              try {
                await dispatch(
                  getApplicantsApi({
                    jobId,
                    recruiterId:
                      loginSelector?.who === constants.COMPANY.toLowerCase() ||
                      (loginSelector?.who ===
                        constants.COMPANY_MEMBER.toLowerCase() &&
                        memberSelector?.designation === "Admin")
                        ? ""
                        : loginSelector.userId,
                  })
                ).then(unwrapResult);
              } catch (error) {}
            } else {
              try {
                const resposeRefer = await dispatch(
                  getAllReferralsApi({
                    jobId: jobId,
                    // status: "applied",
                    recruiterId: loginSelector?.userId,
                  })
                ).then(unwrapResult);
              } catch (error) {}
            }
            toast.success("Successfully referred");
          } catch (error) {
            toast.error("Something went wrong");
          }
          // toast.success("Reffered successfully");
        } catch (error) {
          toast.error("Something went wrong");
        }
        // } catch (error) {
        //   toast.error("Unable to save additonal details");
        // }
        onClose();
      } catch (error) {
        toast.error("Something went wrong");
      }
    }
  };

  const { emailId, phoneNo } = watch();

  const searchCandidatehandler = async (isEmail) => {
    try {
      const response = await dispatch(
        searchCandidatesApi({
          mobile: !isEmail ? parseInt(phoneNo) : null,
          email: isEmail ? emailId : "",
          authorId:
            userType === constants.COMPANY.toLowerCase()
              ? userId
              : userType === constants.COMPANY_MEMBER.toLowerCase()
              ? memberSelector.companyId
              : userId,
        })
      ).then(unwrapResult);
      setUserDetails(response.data.data);
      const data = response.data.data;
      console.log("response?.data?.data :>> ", data);
      setValue("firstName", data.firstName);
      setValue("lastName", data.lastName);
      setValue("experienceYears", data.totalExperience);
      setValue("designantion", data.designation);
      setValue("companyName", data.organization);
      setValue("emailId", data.email);
      setValue("phoneNo", data.mobile.toString());
      setValue("location", defaultLocation(data.location));
      setValue("isEmailVerified", data.isEmailVerified);
      setValue("isMobileVerified", data.isMobileVerified);

      if (data?.industry && data?.industry.length > 0) {
        const preIndustry = defaultIndustry(data?.industry[0]);
        setValue("industry", preIndustry);
      }

      if (data?.userEducation && data?.userEducation?.length > 0) {
        const education = data?.userEducation[0];

        setValue("qualification", education?.degree ? education.degree : "");
        setValue("university", education?.institute ? education.institute : "");

        if (education.startDate?.month && education.startDate?.year) {
          setValue(
            "edStartDate",
            moment(
              `${education.startDate?.month} ${education.startDate?.year}`
            ).format()
          );
        }

        if (education.endDate?.month && education.endDate?.year) {
          setValue(
            "edEndDate",
            moment(
              `${education.endDate?.month} ${education.endDate?.year}`
            ).format()
          );
        }
      }

      if (data?.userExperience && data?.userExperience?.length > 0) {
        let experience;
        const latestExp = data?.userExperience.find(
          (exp) => exp?.userExperienceDetails[0]?.isCurrentRole === true
        );

        if (!latestExp) {
          const sortedExperiences = data?.userExperience
            .filter((exp) => exp.userExperienceDetails[0]?.startDate?.year) // Filter out items without a start year
            .sort((a, b) => {
              const yearA = parseInt(
                a.userExperienceDetails[0].startDate.year,
                10
              );
              const yearB = parseInt(
                b.userExperienceDetails[0].startDate.year,
                10
              );
              return yearB - yearA; // Sort in ascending order of year
            });
          console.log("sortedExperiences[0] :>> ", sortedExperiences[0]);
          if (sortedExperiences[0]) {
            experience = sortedExperiences[0];
          } else {
            experience = data?.userExperience[0];
          }
        } else {
          experience = latestExp;
        }

        setValue(
          "designantion",
          experience?.userExperienceDetails[0]?.title
            ? experience?.userExperienceDetails[0]?.title
            : ""
        );
        setValue(
          "prevCompanyName",
          experience?.organization ? experience?.organization : ""
        );

        if (experience?.userExperienceDetails[0]?.employmentType) {
          const workMode = defaultJobType(
            experience?.userExperienceDetails[0]?.employmentType
          );

          setValue(
            "workMode",
            workMode?.Title && workMode?.Id ? workMode : null
          );
        }

        if (experience?.userExperienceDetails[0]?.location) {
          const defaultJobLocation = defaultLocation(
            experience?.userExperienceDetails[0]?.location
          );

          setValue("jobLocation", defaultJobLocation);
        }

        if (
          experience?.userExperienceDetails[0]?.startDate?.month &&
          experience?.userExperienceDetails[0]?.startDate?.year
        ) {
          setValue(
            "exStartDate",
            moment(
              `${experience?.userExperienceDetails[0]?.startDate?.month} ${experience?.userExperienceDetails[0]?.startDate?.year}`
            ).format()
          );
        }

        if (!experience?.userExperienceDetails[0].isCurrentRole) {
          if (
            experience?.userExperienceDetails[0]?.endDate?.month &&
            experience?.userExperienceDetails[0]?.endDate?.year
          ) {
            setValue(
              "exEndDate",
              moment(
                `${experience?.userExperienceDetails[0]?.endDate?.month} ${experience?.userExperienceDetails[0]?.endDate?.year}`
              ).format()
            );
          }
        }

        setValue(
          "isCurrentlyworking",
          experience?.userExperienceDetails[0].isCurrentRole
        );
      } else {
        setValue("isFresher", true);
      }

      if (data.userAdditionalInfo.id) {
        setValue(
          "isServingNoticePeriod",
          data.userAdditionalInfo.isServingNoticePeriod ? "Yes" : "No"
        );
        setValue(
          "openForRelocation",
          data.userAdditionalInfo.relocation ? "Yes" : "No"
        );
        setValue("noticePeriodDuration", data.userAdditionalInfo.noticePeriod);
        setValue("reportingTo", data.userAdditionalInfo.reportingTo);
        setValue("teamSize", data.userAdditionalInfo.teamSize);
        setValue("file.name", data.userAdditionalInfo.resume.name);
        setValue("file.url", data.userAdditionalInfo.resume.url);
        setValue(
          "file.contentType",
          data.userAdditionalInfo.resume.contentType
        );
        if (
          data.userAdditionalInfo.lastSalary &&
          !isNaN(data.userAdditionalInfo.lastSalary)
        ) {
          const lastSal = defualtSalary(data.userAdditionalInfo.lastSalary);
          setValue("currentCtc", lastSal);
        }

        if (data.userAdditionalInfo.expectation) {
          const expectedSal = defualtSalary(
            data.userAdditionalInfo.expectation
          );
          setValue(
            "expectedCtc",
            data.userAdditionalInfo.expectation ? expectedSal : null,
            { shouldDirty: true }
          );
        }

        if (
          data.userAdditionalInfo.preferredLocation &&
          data.userAdditionalInfo.preferredLocation.length > 0
        ) {
          if (
            data.userAdditionalInfo.preferredLocation[0] &&
            !isNaN(data.userAdditionalInfo.preferredLocation[0])
          ) {
            const jobLocation = defaultLocation(
              data.userAdditionalInfo.preferredLocation[0]
            );
            setValue("prefferedJobLocation", jobLocation);
          }
        }

        if (
          data.userAdditionalInfo.preferredIndustry &&
          data.userAdditionalInfo.preferredIndustry.length > 0
        ) {
          if (
            data.userAdditionalInfo.preferredIndustry[0] &&
            !isNaN(data.userAdditionalInfo.preferredIndustry[0])
          ) {
            const preIndustry = defaultIndustry(
              data.userAdditionalInfo.preferredIndustry[0]
            );
            setValue("prefferedIndustry", preIndustry);
          }
        }
      }

      if (data?.skills && data.skills.length > 0) {
        setValue("skill", data.skills);
      }
    } catch (error) {
      if (error?.error?.code === 404) toast.error("User not found");
      else toast.error("Something went wrong");
    }
  };

  const clearFieldsHandler = () => {
    reset();
    setUserDetails(null);
    setConflictError("");
  };

  const navigateHandler = () => {
    if (userDetails.id) navigate(`/job-seekers/${userDetails.id}`);
  };

  const removeFileHandler = () => {
    setValue("file.contentType", "");
    setValue("file.name", "");
    setValue("file.size", 0);
    setValue("file.url", "");
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-start gap-2 px-10">
        <div className="flex-1">
          <h1 className="text-lg text-primary-600 font-semibold text-start">
            Add Candidate
          </h1>
        </div>
        <div className="self-start cursor-pointer" onClick={onClose}>
          <CrossIcon color="#223870" />
        </div>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`space-y-4 max-h-popupScreenheight overflow-y-auto w-full px-10`}
      >
        {/* <form
        onSubmit={handleSubmit(onSubmit)}
        className={`space-y-4 ${
          pathname === `/company-admin/job/${jobId}` &&
          "max-h-popupScreenheight overflow-y-auto no-scrollbar w-full px-10"
        }`}
      > */}

        <div className="space-y-1">
          <h1 className="text-sm text-secondary-400 font-semibold text-start border-b border-b-exgray-100 pb-1">
            Basic Info :
          </h1>

          <div className="grid grid-cols-2 auto-rows-auto gap-2">
            <div className="flex items-center justify-between gap-2">
              <div className="min-w-[120px] self-start">
                <Controller
                  name="countryCode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExDropdown
                      title="Country"
                      onChange={onChange}
                      placeholder="Select"
                      value={value || null}
                      optionValue="PhoneCode"
                      optionLabel="PhoneCodeLabel"
                      options={modifiedCountryCodes}
                      dropDownHeight="40px"
                      error={errors?.countryCode?.message}
                    />
                  )}
                />
              </div>
              <div className="flex-1 self-start">
                <Controller
                  name="phoneNo"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Mobile no is required",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid mobile number",
                    },
                  }}
                  render={({ field: { onChange, value, ref } }) => (
                    <div className="space-y-1">
                      <label
                        htmlFor={"phoneNo"}
                        className={`text-exgray-200/70 text-base font-semibold`}
                      >
                        Mobile No. *
                      </label>
                      <div className={`relative`}>
                        <input
                          type="number"
                          className={`w-full h-10 px-3 bg-exwhite-100 rounded-md outline-none 
                            border ${
                              errors?.phoneNo?.message
                                ? "border-[#dc2623]"
                                : "border-exgray-200"
                            } text-exgray-800 placeholder:text-gray-400 text-base`}
                          id="phoneNo"
                          onWheel={(e) => e.target.blur()}
                          readOnly={userDetails}
                          placeholder="Enter here"
                          autoComplete="off"
                          name="phoneNo"
                          ref={ref}
                          onChange={onChange}
                          value={value}
                        />
                        <div className="absolute h-full top-0 flex items-center justify-center right-2 spin-button-none">
                          <button
                            type="button"
                            disabled={!phoneNo}
                            onClick={() => searchCandidatehandler(false)}
                            className="py-1 w-fit px-4 bg-expurple-800 text-exwhite-100 text-xs rounded-sm"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      {errors?.phoneNo?.message && (
                        <p className="text-red-400">
                          {errors?.phoneNo?.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>

            <div>
              <Controller
                name="emailId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Email ID is required",
                  },
                  pattern: {
                    value:
                      /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                    message: "Please enter a valid email ID",
                  },
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <div className="space-y-1">
                    <label
                      htmlFor={"emailId"}
                      className={`text-exgray-200/70 text-base font-semibold`}
                    >
                      Email ID *
                    </label>
                    <div className={`relative`}>
                      <input
                        type="text"
                        className={`w-full h-10 px-3 bg-exwhite-100 rounded-md outline-none border ${
                          errors?.emailId?.message
                            ? "border-[#dc2623]"
                            : "border-exgray-200"
                        } text-exgray-800 placeholder:text-gray-400 text-base`}
                        id="emailId"
                        readOnly={userDetails}
                        placeholder="eg. abc@abc.com"
                        autoComplete="off"
                        name="emailId"
                        ref={ref}
                        onChange={onChange}
                        value={value}
                      />
                      <div className="absolute h-full top-0 flex items-center justify-center right-2">
                        <button
                          type="button"
                          className="py-1 w-fit px-4 bg-expurple-800 text-exwhite-100 text-xs rounded-sm"
                          disabled={!emailId}
                          onClick={() => searchCandidatehandler(true)}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                    {errors?.emailId?.message && (
                      <p className="text-red-400">{errors?.emailId?.message}</p>
                    )}
                  </div>
                )}
              />
            </div>

            <div>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "First Name is required",
                  },
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <ExInput
                    title="First Name"
                    placeholder="First Name"
                    isMandatory={true}
                    name="firstName"
                    onChange={onChange}
                    reference={ref}
                    value={value}
                    error={errors?.firstName?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Last Name is required",
                  },
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <ExInput
                    title="Last Name"
                    placeholder="Last Name"
                    isMandatory={true}
                    name="lastName"
                    onChange={onChange}
                    reference={ref}
                    value={value}
                    error={errors?.lastName?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="location"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Preffered location is required",
                  },
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <ExDropdown
                    title="Candidate Location"
                    onChange={onChange}
                    placeholder="Select"
                    isMandatory={true}
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    reference={ref}
                    options={locationsArray}
                    dropDownHeight="40px"
                    error={errors?.location?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="industry"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Preffered industry is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Industry"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    options={industriesArray}
                    dropDownHeight="40px"
                    error={errors?.industry?.message}
                  />
                )}
              />
            </div>

            <div className="py-1">
              <Controller
                name="isFresher"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ExCheckBox
                    title="Is Fresher"
                    name="isFresher"
                    onChange={onChange}
                    checked={value}
                    value={value}
                  />
                )}
              />
            </div>
          </div>
        </div>

        {!isCandidateFresher && (
          <div className="space-y-1">
            <h1 className="text-sm text-secondary-400 font-semibold text-start border-b border-b-exgray-100 pb-1">
              Professional Info :
            </h1>

            <div className="space-y-2">
              <div className="grid grid-cols-2 auto-rows-auto gap-2">
                <div className="">
                  <Controller
                    name="prevCompanyName"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Company name is required",
                      },
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <ExInput
                        // title="Present Company"
                        title="Current / Previous Company"
                        placeholder="Enter Here"
                        name="prevCompanyName"
                        onChange={onChange}
                        isMandatory={true}
                        reference={ref}
                        value={value}
                        error={errors?.prevCompanyName?.message}
                      />
                    )}
                  />
                </div>

                <div>
                  <Controller
                    name="designantion"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Designantion is required",
                      },
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <ExInput
                        title="Current / Previous Designation"
                        placeholder="Enter Here"
                        name="designantion"
                        isMandatory={true}
                        onChange={onChange}
                        reference={ref}
                        value={value}
                        error={errors?.designantion?.message}
                      />
                    )}
                  />
                </div>

                <div>
                  <Controller
                    name="workMode"
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: "Work mode is required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ExDropdown
                        title="Work Mode"
                        onChange={onChange}
                        placeholder="Select"
                        value={value || null}
                        optionValue="Id"
                        optionLabel="Title"
                        options={jobTypesArray}
                        dropDownHeight="40px"
                        error={errors?.workMode?.message}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="py-1">
                <Controller
                  name="isCurrentlyworking"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ExCheckBox
                      title="Candidate currently working in this role"
                      name="isCurrentlyworking"
                      onChange={onChange}
                      checked={value}
                      value={value}
                    />
                  )}
                />
              </div>

              <div className="grid grid-cols-2 auto-rows-auto gap-2">
                <div className="w-full self-start">
                  <Controller
                    name="exStartDate"
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: "Start date is required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <CustomDatePicker
                        title="Start date *"
                        name="exStartDate"
                        onChange={onChange}
                        selected={value ? new Date(value) : null}
                        maxDate={new Date()}
                        placeholderText="Ex. mm/yy"
                        popperPlacement="bottom-end"
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker={true}
                        error={errors?.exStartDate?.message}
                      />
                    )}
                  />
                </div>
                <div className="w-full self-start">
                  <Controller
                    name="exEndDate"
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: "End date is required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <CustomDatePicker
                        title="End date"
                        name="exEndDate"
                        onChange={onChange}
                        selected={value ? new Date(value) : null}
                        maxDate={new Date()}
                        minDate={new Date(watch("exStartDate"))}
                        placeholderText="Ex. mm/yy"
                        popperPlacement="bottom-end"
                        dateFormat="MMMM yyyy"
                        disabled={currentWorkingStatus || !watch("exStartDate")}
                        showMonthYearPicker={true}
                        error={
                          !currentWorkingStatus && errors?.exEndDate?.message
                        }
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 auto-rows-auto gap-2">
                {/* <div>
                <Controller
                  name="prevCompanyName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Company name is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ExInput
                      title="Last Company"
                      placeholder="Enter Here"
                      name="prevCompanyName"
                      onChange={onChange}
                      value={value}
                      error={errors?.prevCompanyName?.message}
                    />
                  )}
                />
              </div> */}

                <div>
                  <Controller
                    name="jobLocation"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Preferred location is required",
                      },
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <ExDropdown
                        title="Job Location"
                        onChange={onChange}
                        placeholder="Select"
                        value={value || null}
                        optionValue="Id"
                        optionLabel="Name"
                        isMandatory={true}
                        options={locationsArray}
                        reference={ref}
                        dropDownHeight="40px"
                        error={errors?.jobLocation?.message}
                      />
                    )}
                  />
                </div>

                <div>
                  <Controller
                    name="openForRelocation"
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: "Employment Type is required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ExRadioBtnGroup
                        title="Open For Relocation"
                        onChange={onChange}
                        selectedOption={value}
                        options={["Yes", "No"]}
                        error={errors?.openForRelocation?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="space-y-1">
          <h1 className="text-sm text-secondary-400 font-semibold text-start border-b border-b-exgray-100 pb-1">
            Qualification Info :
          </h1>

          <div className="grid grid-cols-2 auto-rows-auto gap-2">
            <div>
              <Controller
                name="qualification"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Qualification is required",
                  },
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <ExInput
                    title="Qualification"
                    placeholder="eg. Bachelor of Engineering"
                    name="qualification"
                    onChange={onChange}
                    isMandatory={true}
                    reference={ref}
                    value={value}
                    error={errors?.qualification?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="university"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "University is required",
                  },
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <ExInput
                    title="University"
                    placeholder="eg. Mumbai University"
                    name="university"
                    isMandatory={true}
                    onChange={onChange}
                    reference={ref}
                    value={value}
                    error={errors?.university?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="edStartDate"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Start date is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <CustomDatePicker
                    title="Start date *"
                    name="edStartDate"
                    onChange={onChange}
                    selected={value ? new Date(value) : null}
                    maxDate={new Date()}
                    placeholderText="Ex. mm/yy"
                    popperPlacement="bottom-end"
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker={true}
                    error={errors?.edStartDate?.message}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="edEndDate"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "End date is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <CustomDatePicker
                    title="End date *"
                    name="edEndDate"
                    onChange={onChange}
                    selected={value ? new Date(value) : null}
                    maxDate={new Date()}
                    minDate={new Date(watch("edStartDate"))}
                    placeholderText="Ex. mm/yy"
                    popperPlacement="bottom-end"
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker={true}
                    error={errors?.edEndDate?.message}
                    disabled={!watch("edStartDate")}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="space-y-1">
          <h1 className="text-sm text-secondary-400 font-semibold text-start border-b border-b-exgray-100 pb-1">
            Additional Info :
          </h1>

          <div className="grid grid-cols-2 auto-rows-auto gap-2">
            <div>
              <Controller
                name="experienceYears"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Experience is required",
                  },
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <ExInput
                    title="Total Yrs of Experience"
                    placeholder="Ex. 5"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    isMandatory={true}
                    name="experienceYears"
                    onChange={onChange}
                    value={value || ""}
                    reference={ref}
                    error={errors?.experienceYears?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="prefferedJobLocation"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Preferred location is required",
                  },
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <ExDropdown
                    title="Preffered Location"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    isMandatory={true}
                    reference={ref}
                    options={locationsArray}
                    dropDownHeight="40px"
                    error={errors?.prefferedJobLocation?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="prefferedIndustry"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Preffered industry is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExDropdown
                    title="Prefered Industry"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    options={industriesArray}
                    dropDownHeight="40px"
                    error={errors?.prefferedIndustry?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="currentCtc"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Current CTC is required",
                  },
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <ExDropdown
                    title="Current CTC"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    isMandatory={true}
                    reference={ref}
                    options={minSalaries}
                    dropDownHeight="40px"
                    error={errors?.currentCtc?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="expectedCtc"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Expected CTC is required",
                  },
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <ExDropdown
                    title="Expectation"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Title"
                    isMandatory={true}
                    options={minSalaries}
                    reference={ref}
                    dropDownHeight="40px"
                    error={errors?.expectedCtc?.message}
                  />
                )}
              />
            </div>

            {/* <div>
              <Controller
                name="prefferedLocations"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Location is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExMultiDropdown
                    title="Preferred Location  *"
                    onChange={onChange}
                    placeholder="Select"
                    value={value || null}
                    optionValue="Id"
                    optionLabel="Name"
                    options={locationsArray}
                    closeMenuOnSelect={false}
                    dropDownHeight="auto"
                    dropDownBorderWidth="2px"
                    dropDownBorderColor="#CDCED2"
                    error={errors?.prefferedLocations?.message}
                  />
                )}
              />
            </div> */}

            <div className="">
              <Controller
                name="isServingNoticePeriod"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Notice period is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExRadioBtnGroup
                    title="Serving Notice Period"
                    onChange={onChange}
                    selectedOption={value}
                    options={["Yes", "No"]}
                    error={errors?.isServingNoticePeriod?.message}
                  />
                )}
              />
            </div>

            {
              <div>
                <Controller
                  name="noticePeriodDuration"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Notice period required",
                    },
                  }}
                  render={({ field: { onChange, value, ref } }) => (
                    <ExInput
                      title="No. of Days Serving"
                      placeholder="Ex. 30"
                      name="noticePeriodDuration"
                      type="number"
                      onChange={onChange}
                      isMandatory={true}
                      reference={ref}
                      onWheel={(e) => e.target.blur()}
                      value={value}
                      error={errors?.noticePeriodDuration?.message}
                    />
                  )}
                />
              </div>
            }

            <div>
              <Controller
                name="reportingTo"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Field is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="Reporting To"
                    placeholder="Enter here"
                    name="reportingTo"
                    onChange={onChange}
                    value={value}
                    error={errors?.reportingTo?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="teamSize"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: "Team size required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ExInput
                    title="Team Size"
                    placeholder="Ex. 10"
                    name="teamSize"
                    type="number"
                    onChange={onChange}
                    onWheel={(e) => e.target.blur()}
                    value={value}
                    error={errors?.teamSize?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div>
          <Controller
            name="skill"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Skill is required",
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <div className="space-y-2">
                <label
                  htmlFor={"skill"}
                  className={`text-exgray-200/70 text-base font-semibold`}
                >
                  Key Skills
                </label>
                <div className="relative">
                  <input
                    type="text"
                    className={`w-full h-10 px-3 bg-exwhite-100 rounded-md outline-none border ${
                      errors?.skill?.message && allSkillsValues.length === 0
                        ? "border-[#dc2623]"
                        : "border-exgray-200"
                    } text-exgray-800 placeholder:text-gray-400 text-base`}
                    id="skill"
                    placeholder="Add Skill"
                    ref={ref}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && interestValue) {
                        e.preventDefault();
                        addInterestHandler();
                      }
                    }}
                    autoComplete="off"
                    name="skill"
                    onChange={onChangeInterestValue}
                    value={interestValue}
                  />
                  <div className="absolute h-full top-0 flex items-center justify-center right-2">
                    <button
                      type="button"
                      disabled={!interestValue}
                      onClick={addInterestHandler}
                      className="py-1 w-fit px-4  text-exgray-400 font-bold underline text-sm rounded-sm"
                    >
                      Add
                    </button>
                  </div>
                </div>
                {errors?.skill?.message && (
                  <p className="text-red-400">
                    {errors?.skill?.message &&
                      allSkillsValues.length === 0 &&
                      errors?.skill?.message}
                  </p>
                )}

                {allSkillsValues && allSkillsValues.length > 0 && (
                  <div className="flex flex-wrap gap-2">
                    {allSkillsValues?.map((skill, index) => (
                      <div
                        className="px-3 py-1 bg-exgray-100/50 text-exgray-200 font-semibold w-fit rounded-full text-sm flex items-center justify-center gap-4"
                        key={uuidv4()}
                      >
                        <h1>{skill}</h1>
                        <div
                          className="cursor-pointer"
                          onClick={() => removeInterestHandler(index)}
                        >
                          <CrossIcon width="10" height="10" color="#8C8C8C" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          />
        </div>

        <div>
          <div>
            <Controller
              name="jobChangeReasaon"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "field is required",
                },
                maxLength: {
                  value: 2600,
                  message: "Only 2600 characters are allowed",
                },
              }}
              render={({ field: { onChange, value, ref } }) => (
                <ExTextarea
                  rows={2}
                  name="jobChangeReasaon"
                  value={value || ""}
                  title="Reason for Leaving"
                  maxLength={2600}
                  onChange={onChange}
                  isMandatory={true}
                  reference={ref}
                  placeholder="Enter Here"
                  error={errors?.jobChangeReasaon?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="comment"
              control={control}
              rules={{
                required: {
                  value: false,
                  message: "Comment is required",
                },
                maxLength: {
                  value: 2600,
                  message: "Only 2600 characters are allowed",
                },
              }}
              render={({ field: { onChange, value, ref } }) => (
                <ExTextarea
                  rows={2}
                  name="comment"
                  value={value || ""}
                  title="Comment"
                  maxLength={2600}
                  onChange={onChange}
                  reference={ref}
                  placeholder="Enter Here"
                  error={errors?.comment?.message}
                />
              )}
            />
          </div>
        </div>

        <div className="space-y-2">
          <h1 className="text-sm text-secondary-400 font-semibold text-start border-b border-b-exgray-100 pb-1">
            Resume Upload :
          </h1>

          <div className="">
            {!uploadedFile.url ? (
              <Controller
                name="file"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required",
                  },
                  validate: {
                    hasName: (value) =>
                      value?.name !== "" || "File is required",
                    hasUrl: (value) => value?.url !== "" || "File is required",
                    hasContentType: (value) =>
                      value?.contentType !== "" || "File is required",
                    // validSize: (value) => value?.size > 0 || "File is required",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <div className="hidden">
                      <input
                        title="Add Resume / CV"
                        placeholder="Browse file"
                        name="file"
                        id="file"
                        type="file"
                        ref={inputRef}
                        accept=".pdf, .doc, .docx"
                        onChange={(e) => {
                          setIsFileLoading(true);
                          const toastId = toast.loading("Uploading...");
                          uploadContent.uploadProfileContent(
                            userId,
                            e.target.files,
                            (response) => {
                              setIsFileLoading(false);
                              toast.dismiss(toastId);
                              if (!response[0].error) {
                                toast.success("File Uploaded Successfully");
                                onChange({
                                  name: response[0].orginalFileName,
                                  url: response[0].url,
                                  contentType: response[0].contentType,
                                  size: response[0].size,
                                });
                              } else {
                                toast.error("Unable to upload file");
                              }
                            }
                          );
                        }}
                        // value={value}
                      />
                    </div>
                    <div className="flex items-center justify-between gap-2">
                      <div
                        className={`flex-1 h-10 border-2 ${
                          errors?.file?.message
                            ? "border-[#dc2623] text-[#dc2623] bg-red-100/70"
                            : "border-secondary-400 text-secondary-400 bg-secondary-100"
                        } border-dashed rounded-md flex items-center justify-center gap-1 p-10 cursor-pointer`}
                        onClick={triggerInputfileHanlder}
                      >
                        <div className="flex items-center justify-center gap-2">
                          <div className="w-fit">
                            <UploadIcon
                              height="32"
                              width="32"
                              color={
                                errors?.file?.message ? "#dc2623" : "#0A65CC"
                              }
                            />
                          </div>
                          <h1 className="text-base font-semibold">
                            Upload your CV as a PDF file.
                          </h1>
                        </div>
                      </div>
                    </div>
                    {errors?.file?.message && (
                      <p className="text-red-400">{errors?.file?.message}</p>
                    )}
                  </div>
                )}
              />
            ) : (
              <div className="bg-secondary-100 p-2 flex items-center justify-start rounded-sm">
                <div className="w-fit">
                  <FileIcon width="35" height="35" color="#0A65CC" />
                </div>
                <p className="flex-1 text-secondary-400 text-base font-semibold">
                  {uploadedFile.name}
                </p>
                <div
                  className="pr-2 cursor-pointer"
                  onClick={removeFileHandler}
                >
                  <CrossIcon width="15" height="15" color="#0A65CC" />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center">
          {conflictError && (
            <p className="text-base text-red-600 font-semibold flex items-center gap-2 justify-start">
              <ErrorIcon width="20" height="20" /> {conflictError}
            </p>
          )}

          <div className="w-fit ml-auto mr-0 space-x-2">
            <button
              type="button"
              className="px-10 py-2 text-expurple-800 text-base font-semibold text-center bg-transparent rounded-md active:scale-95"
              onClick={clearFieldsHandler}
            >
              Clear
            </button>

            <button
              className={`px-10 py-2 text-exwhite-100 text-base font-semibold text-center ${
                isFileLoading ? "bg-exgray-400/50" : "bg-expurple-800"
              } rounded-md active:scale-95`}
              disabled={isFileLoading}
            >
              Refer
            </button>
          </div>
        </div>
        {userDetails && (
          <div className="p-2 w-[50%]">
            <div
              className={`bg-gradient-to-b from-[#FAFBFF] to-expurple-100 h-full rounded-lg p-7 space-y-2 cursor-pointer border border-exgray-100/70`}
            >
              <div className="flex items-center justify-between gap-2">
                {userDetails.profilePicture ? (
                  <div className="h-16 w-16 rounded-md flex items-center justify-center overflow-hidden">
                    <img
                      src={`${constants.baseUrl}/${userDetails?.profilePicture}?t=${token}`}
                      alt="profile"
                      className="w-full h-full object-cover"
                    />
                  </div>
                ) : (
                  <div className="h-16 w-16 bg-DefaultProfileImg rounded-md bg-cover bg-center bg-no-repeat"></div>
                )}
                <div className="self-stretch ml-3 flex-1 space-y-1">
                  <h1 className="text-lg text-black font-semibold line-clamp-1">
                    {`${userDetails?.firstName} ${userDetails?.lastName}`}
                  </h1>
                  <div className="flex items-center justify-start gap-2">
                    <p className=" flex-1 text-base text-black font-semibold line-clamp-1">
                      {userDetails?.organization}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-2 text-base text-exgray-300">
                <LocationIcon width="20" height="20" color="#6C7275" />
                <h1 className="line-clamp-1">{candidateLocation?.Name}</h1>
              </div>
              <div className="flex items-center gap-2">
                <IndustryIcon width="20" height="20" color="#6C7275" />
                <h1 className="flex items-center gap-2 text-base text-exgray-300">
                  {preferdIndustry ? preferdIndustry?.Name : "N/A"}
                </h1>
              </div>

              <div>
                <div className="ml-auto mr-0 w-fit">
                  <button
                    className="text-base px-8 py-2 bg-expurple-800 font-semibold text-center rounded-md text-exwhite-100"
                    onClick={navigateHandler}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default CreateReferForm;
